import store from '../store';

export const SET_IS_IN_PORTRAIT_MODE = 'SET_IS_IN_PORTRAIT_MODE';
export const SET_IS_IN_LANDSCAPE_MODE = 'SET_IS_IN_LANDSCAPE_MODE';
export const SET_MOBILE_BREAKPOINT_REACHED = 'SET_MOBILE_BREAKPOINT_REACHED';
export const UNSET_MOBILE_BREAKPOINT_REACHED = 'UNSET_MOBILE_BREAKPOINT_REACHED';
export const SET_WAKELOCK_IS_SUPPORTED = 'SET_WAKELOCK_IS_SUPPORTED';
export const UNSET_WAKELOCK_IS_SUPPORTED = 'UNSET_WAKELOCK_IS_SUPPORTED';
export const ACTIVATE_WAKELOCK = 'ACTIVATE_WAKELOCK';
export const DEACTIVATE_WAKELOCK = 'DEACTIVATE_WAKELOCK';

// SCREEN ORIENTATION

const setIsInPortraitMode = () => {
    return {
        type: SET_IS_IN_PORTRAIT_MODE,
    };
};

export const dispatchSetIsInPortraitMode = () => {
    store.dispatch(setIsInPortraitMode());
};

const setIsInLandscapeMode = () => {
    return {
        type: SET_IS_IN_LANDSCAPE_MODE,
    };
};

export const dispatchSetIsInLandscapeMode = () => {
    store.dispatch(setIsInLandscapeMode());
};

// MOBILE BREAKPOINT

const setMobileBreakpointReached = () => {
    return {
        type: SET_MOBILE_BREAKPOINT_REACHED,
    };
};

export const dispatchSetMobileBreakpointReached = () => {
    store.dispatch(setMobileBreakpointReached());
};

const unsetMobileBreakpointReached = () => {
    return {
        type: UNSET_MOBILE_BREAKPOINT_REACHED,
    };
};

export const dispatchUnsetMobileBreakpointReached = () => {
    store.dispatch(unsetMobileBreakpointReached());
};

// SCREEN WAKELOCK

const setWakelockIsSupported = () => {
    return {
        type: SET_WAKELOCK_IS_SUPPORTED,
    };
};

export const dispatchSetWakelockIsSupported = () => {
    store.dispatch(setWakelockIsSupported());
};

const unsetWakelockIsSupported = () => {
    return {
        type: UNSET_WAKELOCK_IS_SUPPORTED,
    };
};

export const dispatchUnsetWakelockIsSupported = () => {
    store.dispatch(unsetWakelockIsSupported());
};


const activateWakelock = () => {
    return {
        type: ACTIVATE_WAKELOCK,
    };
};

export const dispatchActivateWakelock = () => {
    store.dispatch(activateWakelock());
};

const deactivateWakelock = () => {
    return {
        type: DEACTIVATE_WAKELOCK,
    };
};

export const dispatchDeactivateWakelock = () => {
    store.dispatch(deactivateWakelock());
};

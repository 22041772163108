import { calculateDifference } from './helper';
import { DEBUG } from '../config';
import { addLogDispatch } from '../redux/actions/logs';
import { postLogEvent, postLogError } from '../api/backendApi';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { addSessionLogEntryDispatch } from '../redux/actions/session';
import { dispatcherAuthManager } from '../store/DispatcherAuthManager';

const time = {};

/**
 * logs the current time under a given key
 * @param {string} key
 */
export const timeLog = key => {
    time[key] = new Date();
};

/**
 * calculate the duration until now under a given key
 * @param {string} key
 * @return {number} duration
 */
export const calcDuration = key => {
    const now = new Date();
    const then = time[key];

    delete time[key];

    return calculateDifference(now, then);
};

/**
 * log notes from running session
 * @param {object} kpi
 * @returns response data
 */
export const notesLog = async ({ message, duration, eventId, sessionId }) => {
    addSessionLogEntryDispatch({ message, eventId, additionalInformation: '' });
    await sendLog({
        type: 'NOTES',
        message,
        duration,
        eventId,
        sessionId,
    });

    return await sendLog({
        type: 'KPI',
        message,
        duration,
        eventId,
        sessionId,
    });
};

/**
 * log a kpi entry
 * @param {object} kpi
 * @returns response data
 */
export const kpiLog = async ({ message, duration, eventId, sessionId, additionalInformation }) => {
    addSessionLogEntryDispatch({ message, eventId, sessionId, additionalInformation });
    return await sendLog({
        type: 'KPI',
        message,
        duration,
        eventId,
        sessionId,
        additionalInformation,
    });
};

/**
 * log a error entry
 * @param {object} error
 */
export const errorLog = ({ message, error, eventId, sessionId }) => {
    if (DEBUG) addNotificationAndShowDispatch(message, 'error');
    sendLog({
        stack: error.stack,
        message,
        type: 'ERROR',
        eventId,
        sessionId,
        error: true,
    });
};

/**
 * log a data entry
 * @param {object} data
 */
export const dataLog = ({ message, eventId, sessionId }) => {
    sendLog({ type: 'DATA', message, eventId, sessionId });
};

/**
 * send a log to the backend
 * @param {object} logData
 * @return response data
 */
const sendLog = async ({ message, stack, duration, type = '', eventId = '', sessionId = dispatcherAuthManager.bystanderToken, error = false, additionalInformation }) => {
    // message
    let messageText = message;
    if (type === 'ERROR') {
        if (stack) {
            messageText += ' | ' + stack;
        }
        // limit error message to 1000 characters
        if (messageText.length > 1000) {
            messageText = messageText.substring(0, 1000);
        }
    }

    if (duration) {
        messageText += ' | ' + calcDuration(duration);
    }

    const data = {
        category: type,
        sessionId,
        message: messageText,
        additionalInformation,
        eventId,
    };

    if (DEBUG) addLogDispatch(['LOG API', data]);

    if (error) {
        return await postLogError(data);
    }
    return await postLogEvent(data);
};

import { StreamInfo } from "../../types/stream.types";
import { serializeContact } from "./serializeContact";

export const serializeStreamInfo = (streamInfo: StreamInfo) => {
    const immutableStreamInfo = {
        listEventType: streamInfo.listEventType,
        contact: serializeContact(streamInfo.contact),
        isRemote: streamInfo.isRemote,
        hasAudio: streamInfo.hasAudio,
        hasVideo: streamInfo.hasVideo,
        isAudioMuted: streamInfo.isAudioMuted,
        isVideoMuted: streamInfo.isVideoMuted,
        isScreensharing: streamInfo.isScreensharing,
        isSIP: streamInfo.isSIP,
        isSimulcast: streamInfo.isSimulcast,
        streamId: streamInfo.streamId,
        type: streamInfo.type,
        callId: streamInfo.callId,
        descriptor: streamInfo.descriptor,
        context: streamInfo.context,
    };

    return {
        ...immutableStreamInfo,
    };
};

import { ADD_LOG } from '../actions/actionTypes';

export const initialState = [];

/**
 * logs reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LOG:
            return [...state, action.log];
        default:
            return state;
    }
};

export default logsReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { createKpiLog, parseTime } from '../../helper/helper';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import clsx from 'clsx';
import ChatFile from './ChatFile';
import './ChatMessage.scss';
import { RootState } from '../../redux/store';
import QRCode from 'qrcode.react';
import { rtmpStreamQrcodeRegex, urlRegex } from '../../regex';

interface ChatMessageProps {
    chatMessage: string;
    originalChatMessage: string;
    timestamp: string;
    id: string;
    currentUser: 'dispatcher' | 'caller' | 'conference';
    showOriginal: string;
    isInit: boolean;
    from?: 'DISPATCHER' | 'CALLER';
    isForCaller: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ chatMessage, originalChatMessage, timestamp, id, currentUser, showOriginal, isInit, from, isForCaller = true }) => {
    const fileShareFeature = useSelector((state: RootState) => state.features.fileShareFeature);
    const dispatcherFiles = useSelector((state: RootState) => state.files.dispatcherFiles);
    const callerFiles = useSelector((state: RootState) => state.files.callerFiles);

    const [showTranslated, setShowTranslated] = React.useState(true);

    const toggleTranslated = () => {
        setShowTranslated(!showTranslated);
    };

    const handleTranslation = () => {
        if (showTranslated) {
            return chatMessage.replace(urlRegex, '');
        } else {
            return originalChatMessage.replace(urlRegex, '');
        }
    };

    const download = async (e, file) => {
        e.preventDefault();
        if (currentUser === 'caller') {
            createKpiLog('fileDownloadCallerInfo', '', { 0: file.name, 1: file.size });
        } else if (currentUser === 'dispatcher') {
            createKpiLog('fileDownloadDispatcherInfo', '', { 0: file.name, 1: file.size });
        }

        try {
            const fileUrl = file.url;
            const response = await fetch(fileUrl);
            if (!response.ok) {
                addNotificationAndShowDispatch('File not ready', 'error', DISPLAY_ONLY_IN_SESSION);
            }
            const content = await response.blob();
            const blob = new Blob([content], { type: file.type });
            const blobURL = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = file.name;
            a.click();
            window.URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderMessage = () => {
        let url = window.location.href;
        url = window.location.href.replace('disptchr', 'caller'); // eslint-disable-line

        // dispatcher files
        if (fileShareFeature && dispatcherFiles.length !== 0) {
            let matchingFile = dispatcherFiles.filter(file => originalChatMessage === file.url + '\n' + file.description);
            if (matchingFile.length !== 0) {
                return (
                    <>
                        <ChatFile file={matchingFile[0]} download clickHandler={e => download(e, matchingFile[0])} />
                        {handleTranslation()}
                    </>
                );
            }
        }
        // caller files
        if (fileShareFeature && callerFiles.length !== 0) {
            let matchingFile = callerFiles.filter(file => originalChatMessage === file.url + '\n' + file.description);
            if (matchingFile.length !== 0) {
                return (
                    <>
                        <ChatFile file={matchingFile[0]} download clickHandler={e => download(e, matchingFile[0])} />
                        {handleTranslation()}
                    </>
                );
            }
        }

        // conference user filehandling
        if (fileShareFeature && currentUser === 'conference' && originalChatMessage.trim().indexOf('https://') === 0) {
            const url = originalChatMessage.split('\n')[0];
            const description = originalChatMessage.split('\n')[1];
            return (
                <>
                    <ChatFile file={{ url, description }} download={false} showFileInfo={false} clickHandler={() => {}} />
                    {handleTranslation()}
                </>
            );
        }

        // only appear in dispatcher chat window
        if (!isForCaller) {
            return originalChatMessage;
        }

        if (rtmpStreamQrcodeRegex.test(originalChatMessage) || originalChatMessage === url) {
            return <QRCode value={originalChatMessage} includeMargin={true} />;
        } else {
            if (showTranslated) {
                return chatMessage;
            } else {
                return originalChatMessage;
            }
        }
    };

    const messageUser = id.split('_')[0];
    const messageFromCurrentUser = currentUser === messageUser;
    const conferenceShowRight = currentUser === 'conference' && messageUser === 'caller';
    const classes = clsx('chatMessage', {
        'chatMessage--right': messageFromCurrentUser || conferenceShowRight,
        'chatMessage--isInit': isInit,
    });
    const classesOriginal = clsx('chatMessage__showOriginal', {
        'chatMessage__showOriginal--strike': !showTranslated,
    });

    const translatedMessage = chatMessage.trim() !== originalChatMessage.trim();

    const chatMessageClasses = `${!isForCaller ? 'chatMessage--dispatcher-only' : 'chatMessage__message'}`;

    let shouldShowOriginalToggle = translatedMessage && !messageFromCurrentUser;
    if (currentUser === 'conference') {
        // only show the caller translation for the conference user
        shouldShowOriginalToggle = from === 'CALLER' && translatedMessage;
    }

    return (
        <div className={classes}>
            <div className="chatMessage__meta">
                <span className="chatMessage__time">{parseTime(timestamp)}</span>
            </div>
            <div className={chatMessageClasses}>
                {renderMessage()}
                {/* remove any white space to ensure equality */}
                {shouldShowOriginalToggle && (
                    <div className={classesOriginal} onClick={toggleTranslated}>
                        {showOriginal}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatMessage;

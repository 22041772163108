import store from '../store/index';
import { invitedUserWebRTCManager } from '../../webrtc/webrtcManagers/InvitedUserWebRTCManager';
import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { createKpiLog, isOnStartPage } from '../../helper/helper';

export const ACTIVATE_CONVERSATION = 'ACTIVATE_CONVERSATION';
export const DEACTIVATE_CONVERSATION = 'DEACTIVATE_CONVERSATION';

export const ADD_CONVERSATION_NAME = 'ADD_CONFERENCE_NAME';

export const ADD_GOODBYE_TEXT = 'ADD_GOODBYE_TEXT';
export const ADD_GOODBYE_LOGO = 'ADD_GOODBYE_LOGO';

export const ADD_STREAM_INFO = 'ADD_STREAM_INFO';
export const REMOVE_STREAM_INFO = 'REMOVE_STREAM_INFO';

export const MUTE_MIC = 'MUTE_MIC';
export const UNMUTE_MIC = 'UNMUTE_MIC';

export const SET_LEAVE_CONFERENCE = 'SET_LEAVE_CONFERENCE';

export const RESET_CONFERENCING = 'RESET_CONFERENCING';

/*** Conference actions - DISPATCHER ***/

// MIC - Dispatcher

const muteMicDispatcher = () => {
    return { type: MUTE_MIC };
};

export const muteMicDispatcherDispatch = () => {
    dispatcherWebRTCManager.muteMic();

    if (!isOnStartPage()) createKpiLog('stateAudioMic', 'muted');

    store.dispatch(muteMicDispatcher());
};

const unmuteMicDispatcher = () => {
    return { type: UNMUTE_MIC };
};

export const unmuteMicDispatcherDispatch = () => {
    dispatcherWebRTCManager.unmuteMic();

    createKpiLog('stateAudioMic', 'unmuted');

    store.dispatch(unmuteMicDispatcher());
};

// CONVERSATION

const activateConversation = () => {
    return { type: ACTIVATE_CONVERSATION };
};

export const activateConversationDispatcherDispatch = () => {
    invitedUserWebRTCManager.toggleConversationStatus(true);
    store.dispatch(activateConversation());
};

const deactivateConversation = () => {
    return { type: DEACTIVATE_CONVERSATION };
};

export const deactivateConversationDispatcherDispatch = () => {
    invitedUserWebRTCManager.toggleConversationStatus(false);
    store.dispatch(deactivateConversation());
};

// SCREEN SHARING

// Conference users

const addConversationName = conversationName => {
    return { type: ADD_CONVERSATION_NAME, conversationName };
};

export const addConversationNameDispatcherDispatch = conversationName => {
    store.dispatch(addConversationName(conversationName));
};

export const addConversationNameDispatcherAndSend = conversationName => {
    invitedUserWebRTCManager.sendDispatcherConversationName(conversationName);
    addConversationNameDispatcherDispatch(conversationName);
};

/*** Conference actions - PARTICIPANT ***/

// MIC - Participant

const muteMicContact = () => {
    return { type: MUTE_MIC };
};

export const muteMicContactDispatch = () => {
    invitedUserWebRTCManager.muteMic();
    store.dispatch(muteMicContact());
};

const unmuteMicContact = () => {
    return { type: UNMUTE_MIC };
};

export const unmuteMicContactDispatch = () => {
    invitedUserWebRTCManager.unmuteMic();
    store.dispatch(unmuteMicContact());
};

// MIC - Caller

const muteMicCaller = () => {
    return { type: MUTE_MIC };
};

export const muteMicCallerDispatch = () => {
    store.dispatch(muteMicCaller());
};

const unmuteMicCaller = () => {
    return { type: UNMUTE_MIC };
};

export const unmuteMicCallerDispatch = () => {
    store.dispatch(unmuteMicCaller());
};

// STREAM INFO

const addStreamInfo = streamInfo => {
    return {
        streamInfo,
        type: ADD_STREAM_INFO,
    };
};

export const dispatchAddStreamInfo = streamInfo => {
    store.dispatch(addStreamInfo(streamInfo));
};

const removeStreamInfo = streamId => {
    return {
        streamId,
        type: REMOVE_STREAM_INFO,
    };
};

export const dispatchRemoveStreamInfo = streamId => {
    store.dispatch(removeStreamInfo(streamId));
};

export const resetConferencing = () => {
    return {
        type: RESET_CONFERENCING,
    };
};

export const resetConferencingDispatch = () => {
    store.dispatch(resetConferencing());
};


const setIsLeavingConference = () => {
    return { type: SET_LEAVE_CONFERENCE };
};

export const setIsLeavingConferenceDispatch = () => {
    store.dispatch(setIsLeavingConference());
};

const addConferenceGoodbyeText = goodbyeText => {
    return {
        type: ADD_GOODBYE_TEXT,
        goodbyeText,
    };
};

export const addConferenceGoodbyeTextDispatch = goodbyeText => {
    store.dispatch(addConferenceGoodbyeText(goodbyeText));
};

export const addConferenceGoodbyeLogo = ({ logo }) => {
    return {
        type: ADD_GOODBYE_LOGO,
        logo,
    };
};

export const addConferenceGoodbyeLogoDispatch = ({ logo }) => {
    store.dispatch(addConferenceGoodbyeLogo({ logo }));
};

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UserType } from "../types";
import { RootState } from "../redux/store";
import { sendCallerDetails } from "../webrtc/outgoingMessages/outgoingMessagesCaller";

export function useHandleDisclaimerState(user: UserType) {
    // currently we only use this on the caller side for delaying the caller details until the disclaimer check is passed
    const disclaimerFeatureCaller = useSelector((state: RootState) => state.features.disclaimerFeatureCaller);
    // const disclaimerFeatureDispatcher = useSelector((state: RootState) => state.features.disclaimerFeatureDispatcher);
    const usageDisclaimerStateCaller = useSelector((state: RootState) => state.disclaimers.usageDisclaimerStateCaller);
    // const usageDisclaimerStateDispatcher = useSelector((state: RootState) => state.disclaimers.usageDisclaimerStateDispatcher);
    // const usageDisclaimerStateConference = useSelector((state: RootState) => state.disclaimers.usageDisclaimerStateConference);

    useEffect(() => {
        if (user === 'caller') {
            if (usageDisclaimerStateCaller === 'accepted' || usageDisclaimerStateCaller === 'none') {
                sendCallerDetails();
            }
        }
    }, [disclaimerFeatureCaller, usageDisclaimerStateCaller, user])
}
import { Contact, UserData } from '../../types/sessionHandling.types';

export const serializeContact = (data: Contact) => {
    const immutableUserData: UserData = {
        username: data.userData.username,
        userConfId: data.userData.userConfId,
        webRtcCompliant: data.userData.webRtcCompliant,
        dtlsCompliant: data.userData.dtlsCompliant,
        osName: data.userData.osName,
        osVersion: data.userData.osVersion,
        browser: data.userData.browser,
        deviceType: data.userData.deviceType,
        deviceVendor: data.userData.deviceVendor,
        deviceModel: data.userData.deviceModel,
        browser_version: data.userData.browser_version,
        browser_major_version: data.userData.browser_major_version,
        apiRTCVersion: data.userData.apiRTCVersion,
        cpuArchitecture: data.userData.cpuArchitecture,
        engineName: data.userData.engineName,
        engineVersion: data.userData.engineVersion,
        remoteAccessUserSupport: data.userData.remoteAccessUserSupport,
        isSimulated: data.userData.isSimulated,
        id: data.userData.id,
        audioDevicePresent: data.userData.audioDevicePresent,
        videoDevicePresent: data.userData.videoDevicePresent,
    };

    const serializableContact: Contact = {
        enterprise: null,
        fileTransfers: [],
        groups: [ ...data.groups],
        previousMessages: [],
        profile: null,
        promises: [],
        streams: {},
        userData: immutableUserData,
    };

    return {
        contact: serializableContact,
        chatHistory: [],
        geolocation: {},
    };
};

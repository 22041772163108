import store from '../store';
import reduxStore from '../store/index';
import { activateChatDispatcherDispatch } from './application';
import { sendSetFeatureFocus } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE, FocusFeatureKey } from '../../types/focus.types';
import { WebRtcManagerType } from '../../types';

export const SET_FOCUS_WINDOW = 'SET_FOCUS_WINDOW';
export const RESET_FOCUS_WINDOW = 'RESET_FOCUS_WINDOW';
export const ACTIVATE_PREVIEW_DOCK = 'ACTIVATE_PREVIEW_DOCK';
export const DEACTIVATE_PREVIEW_DOCK = 'DEACTIVATE_PREVIEW_DOCK';
export const ACTIVATE_FOCUS_CONTROLS = 'ACTIVATE_FOCUS_CONTROLS';
export const DEACTIVATE_FOCUS_CONTROLS = 'DEACTIVATE_FOCUS_CONTROLS';
export const SET_FOCUS_CHANGED_BY_CALLER = 'SET_FOCUS_CHANGED_BY_CALLER';
export const UNSET_FOCUS_CHANGED_BY_CALLER = 'UNSET_FOCUS_CHANGED_BY_CALLER';
export const SET_INCOMING_FOCUS_FEATURE = 'SET_INCOMING_FOCUS_FEATURE';
export const SET_MAXIMIZE_CHAT_WINDOW = 'SET_MAXIMIZE_CHAT_WINDOW';
export const SET_MINIMIZE_CHAT_WINDOW = 'SET_MINIMIZE_CHAT_WINDOW';

const setFocusWindow = (feature: FocusFeatureKey) => {    
    return {
        type: SET_FOCUS_WINDOW,
        payload: feature
    }
}   

export const dispatchSetFocusWindowLiveVideo = async () => {
    store.dispatch(setFocusWindow(FOCUS_FEATURE_TYPE.LIVE_VIDEO as FocusFeatureKey));
}

export const dispatchSetFocusWindowBidi = async () => {
    store.dispatch(setFocusWindow(FOCUS_FEATURE_TYPE.BIDI as FocusFeatureKey));
};

export const dispatchSetFocusWindowExternalStream = async () => {
    store.dispatch(setFocusWindow(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM as FocusFeatureKey));
};

export const dispatchSetFocusWindowChat = (type) => {
    if (type === WebRtcManagerType.DISPATCHER && !reduxStore.getState().application.chatIsActive) {
        activateChatDispatcherDispatch();
    }
    store.dispatch(setFocusWindow(FOCUS_FEATURE_TYPE.CHAT as FocusFeatureKey));
};

export const dispatchSetFocusWindowScreenshare = () => {
    store.dispatch(setFocusWindow(FOCUS_FEATURE_TYPE.SCREEN_SHARE as FocusFeatureKey));
};

export const dispatchSetFocusWindowMap = async () => {
    store.dispatch(setFocusWindow(FOCUS_FEATURE_TYPE.MAP as FocusFeatureKey));
};

export const dispatchSetFocusWindowFeature = async (feature: FocusFeatureKey) => {
    store.dispatch(setFocusWindow(feature));
};

const setIncomingFocusWindow = (feature: FocusFeatureKey) => {
    return {
        type: SET_INCOMING_FOCUS_FEATURE,
        payload: feature,
    };
}

export const dispatchSetIncomingFocusWindow = (feature: FocusFeatureKey) => {
    store.dispatch(setIncomingFocusWindow(feature));
};

const resetFocusWindow = () => {
    return {
        type: RESET_FOCUS_WINDOW,
    };
};

export const dispatchResetFocusWindow = (type) => {
    if (type === WebRtcManagerType.DISPATCHER) {
        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.RESET_FOCUS);
    }
    store.dispatch(resetFocusWindow());
};

const activatePreviewDock = () => {
    return {
        type: ACTIVATE_PREVIEW_DOCK,
    };
};

export const dispatchActivatePreviewDock = () => {
    store.dispatch(activatePreviewDock());
};

const deactivatePreviewDock = () => {
    return {
        type: DEACTIVATE_PREVIEW_DOCK,
    };
};

export const dispatchDeactivatePreviewDock = () => {
    store.dispatch(deactivatePreviewDock());
};

const activateFocusControls = () => {
    return {
        type: ACTIVATE_FOCUS_CONTROLS,
    };
};

export const dispatchActivateFocusControls = () => {
    store.dispatch(activateFocusControls());
};

const deactivateFocusControls = () => {
    return {
        type: DEACTIVATE_FOCUS_CONTROLS,
    };
};

export const dispatchDeactivateFocusControls = () => {
    store.dispatch(deactivateFocusControls());
};

const setFocusChangedByCaller = () => {
    return {
        type: SET_FOCUS_CHANGED_BY_CALLER,
    };
}

export const dispatchSetFocusTriggeredByCaller = () => {
    store.dispatch(setFocusChangedByCaller());
};

const unsetFocusChangedByCaller = () => {
    return {
        type: UNSET_FOCUS_CHANGED_BY_CALLER,
    };
};

export const dispatchUnsetFocusChangedByCaller = () => {
    store.dispatch(unsetFocusChangedByCaller());
};

// Invited users - chat gridbox

const setMaximizeChatWindow = () => {
    return {
        type: SET_MAXIMIZE_CHAT_WINDOW,
    };
};

export const dispatchSetMaximizeChatWindow = () => {
    store.dispatch(setMaximizeChatWindow());
};

const setMinimizeChatWindow = () => {
    return {
        type: SET_MINIMIZE_CHAT_WINDOW,
    };
};

export const dispatchSetMinimizeChatWindow = () => {
    store.dispatch(setMinimizeChatWindow());
};
import {
    ADD_POINTS_CALLER,
    ADD_POINTS_DISPATCHER,
    ALLOW_PAINTING,
    CHANGE_COLOR,
    CHANGE_SHAPE,
    DELETE_PAINT_CALLER,
    DELETE_PAINT,
    DISALLOW_PAINTING,
    UNDO_LAST_PAINT,
    RESET_PAINT,
    SET_BACKGROUND_IMAGE,
    REMOVE_BACKGROUND_IMAGE,
    SHOW_SCREENSHOT_DIALOGUE,
    HIDE_SCREENSHOT_DIALOGUE,
    SET_PAINT_DEACTIVATION_IS_PENDING_SCREENSHOT_DIALOGUE,
    UNSET_PAINT_DEACTIVATION_IS_PENDING_SCREENSHOT_DIALOGUE,
} from '../actions/actionTypes';

export const PAINT_COLORS = {
    COLOR1: 'COLOR1',
    COLOR2: 'COLOR2',
    COLOR3: 'COLOR3',
    WHITE: 'WHITE',
    BLACK: 'BLACK',
    COLORC: 'COLORC'
};
export const PAINT_SHAPES = {
    ARROW: 'ARROW',
    FREE: 'FREE',
}

export const initialState = {
    color: PAINT_COLORS.COLOR1,
    shape: PAINT_SHAPES.FREE,
    dispatcherPaint: [],
    callerPaint: [],
    isPaintingAllowed: false,
    backgroundImage: null,
    isScreenshotDialogueShown: false,
    paintDeactivationIsPendingScreenshotDialogue: false,
};

const paintReducer = (state = initialState, action) => {
    if (action.type === CHANGE_COLOR) {
        return { ...state, color: action.color }
    }

    if (action.type === CHANGE_SHAPE) {
        return { ...state, shape: action.shape }
    }

    if (action.type === ADD_POINTS_DISPATCHER) {
        return {
            ...state, dispatcherPaint: [...state.dispatcherPaint, action.points]
        }
    }

    if (action.type === ADD_POINTS_CALLER) {
        return {
            ...state, callerPaint: [...state.callerPaint, action.points]
        }
    }

    if (action.type === UNDO_LAST_PAINT) {
        return { ...state, dispatcherPaint: [...state.dispatcherPaint.slice(0, -1)] }
    }

    if (action.type === DELETE_PAINT_CALLER) {
        return { ...state, callerPaint: [] }
    }

    if (action.type === DELETE_PAINT) {
        return { ...state, dispatcherPaint: [], callerPaint: [] }
    }

    if (action.type === ALLOW_PAINTING) {
        return { ...state, isPaintingAllowed: true }
    }

    if (action.type === DISALLOW_PAINTING) {
        return { ...state, isPaintingAllowed: false }
    }

    if (action.type === SET_BACKGROUND_IMAGE) {
        return { ...state, backgroundImage: action.backgroundImage }
    }

    if (action.type === REMOVE_BACKGROUND_IMAGE) {
        return { ...state, backgroundImage: null }
    }

    if (action.type === SHOW_SCREENSHOT_DIALOGUE) {
        return { ...state, isScreenshotDialogueShown: true }
    }

    if (action.type === HIDE_SCREENSHOT_DIALOGUE) {
        return { ...state, isScreenshotDialogueShown: false }
    }

    if (action.type === SET_PAINT_DEACTIVATION_IS_PENDING_SCREENSHOT_DIALOGUE) {
        return { ...state, paintDeactivationIsPendingScreenshotDialogue: true };
    }

    if (action.type === UNSET_PAINT_DEACTIVATION_IS_PENDING_SCREENSHOT_DIALOGUE) {
        return { ...state, paintDeactivationIsPendingScreenshotDialogue: false };
    }

    if (action.type === RESET_PAINT) {
        return { ...state, ...initialState }
    }

    return state;
}

export default paintReducer;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import './HLSPlayer.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import {
    DEBUG,
    DISPLAY_ONLY_IN_SESSION,
    EXTERNAL_STREAM_NETWORK_ERROR_RECOVERY_TIMEOUT,
    MANIFEST_LOADING_TIMEOUT,
    MANIFEST_LOAD_ATTEMPT_INTERVAL,
} from '../../config';
import Loading from '../Icons/LoadingSpinner';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { replaceText } from '../../helper/helper';
import { deleteExternalStream } from '../../api/backendApi';
import { sendToggleExternalStreaming } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';

type HLSPlayerOwnsProps = {
    source: string | null;
    isDispatcher: boolean;
}

export const HLSPlayer: React.FC<HLSPlayerOwnsProps> = ({ source, isDispatcher }) => {
    const texts = useSelector((state: RootState) => state.texts.texts);
    const externalStreamUrl = useSelector((state: RootState) => state.streamSlice.externalStreamUrl);
    const externalStreamIsActive = useSelector((state: RootState) => state.application.externalStreamIsActive);

    const [isManifestLoading, setIsManifestLoading] = useState(false);
    const [isManifestAvailable, setIsManifestAvailable] = useState(false);
    const videoRef = useRef(null);
    const streamingSource = source + '/index.m3u8';

    let hlsRef = useRef(null);
    let loadManifestInterval = useRef(null);
    let loadManifestTimeout = useRef(null);
    let networkErrorRecoveryTimeout = useRef(null);

    const classes = clsx('hls-stream-container', {
        'hls-stream-container--is-manifest-available': isManifestAvailable,
        'hls-stream-container--is-manifest-loading': isManifestLoading,
    });

    const handleStreamAvailable = useCallback(() => {
        setIsManifestAvailable(true);
        setIsManifestLoading(false);
        clearAllTimeouts();

        if (isDispatcher) {
            addNotificationAndShowDispatch('external.stream.available', 'info', DISPLAY_ONLY_IN_SESSION);
        }
        videoRef.current.muted = true;
        videoRef.current.play();
    }, [isDispatcher]);

    const clearAllTimeouts = () => {
        clearInterval(loadManifestInterval.current);
        clearTimeout(loadManifestTimeout.current);
        clearTimeout(networkErrorRecoveryTimeout.current);
    };

    const initializeHlsInstance = useCallback(() => {
        const handleManifestIsLoaded = () => {
            if (isDispatcher) {
                sendToggleExternalStreaming(true);
            }
        };

        const handleStreamNotAvailable = () => {
            setIsManifestLoading(true);
            setIsManifestAvailable(false);
            clearAllTimeouts();
            // delay each load attempt
            loadManifestInterval.current = setInterval(initializeHlsInstance, MANIFEST_LOAD_ATTEMPT_INTERVAL);

            if (isDispatcher) {
                sendToggleExternalStreaming(false);
                loadManifestTimeout.current = setTimeout(() => {
                    sendToggleExternalStreaming(true);
                }, MANIFEST_LOADING_TIMEOUT);
            }
        };

        if (hlsRef.current !== null) {
            hlsRef.current.destroy();
            hlsRef.current = null;
        }

        let config = {
            autoStartLoad: true,
            enableWorker: true,
            maxBufferLength: 1,
            liveBackBufferLength: 0,
            liveSyncDuration: 0,
            liveMaxLatencyDuration: 5,
            liveDurationInfinity: true,
            highBufferWatchdogPeriod: 1,
        };

        if (Hls.isSupported()) {
            const video = videoRef.current;
            hlsRef.current = new Hls(config);
            hlsRef.current.loadSource(streamingSource);
            hlsRef.current.attachMedia(video);
            hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => handleStreamAvailable());
            hlsRef.current.on(Hls.Events.MANIFEST_LOADED, () => handleManifestIsLoaded());
            hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
                if (DEBUG) console.log(event, data);
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            hlsRef.current.startLoad();
                            clearTimeout(networkErrorRecoveryTimeout.current);
                            networkErrorRecoveryTimeout.current = setTimeout(() => {
                                handleStreamNotAvailable();
                            }, EXTERNAL_STREAM_NETWORK_ERROR_RECOVERY_TIMEOUT);
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            hlsRef.current.recoverMediaError();
                            if (DEBUG) console.log(event, data);
                            break;
                        default:
                            console.error('Fatal error occurred:', data);
                            break;
                    }
                }
            });
        }
    }, [handleStreamAvailable, streamingSource, isDispatcher]);

    useEffect(() => {
        if (externalStreamIsActive) {
            loadManifestInterval.current = setInterval(initializeHlsInstance, MANIFEST_LOAD_ATTEMPT_INTERVAL);
        } else {
            hlsRef.current = null;

            clearAllTimeouts();
        }
    }, [externalStreamIsActive, initializeHlsInstance]);

    useEffect(() => {
        return () => {
            if (hlsRef && hlsRef.current && hlsRef.current !== null) {
                hlsRef.current.destroy();
                hlsRef.current = null;
            }

            clearAllTimeouts();

            const deleteExternalStreamAddress = async () => {
                await deleteExternalStream();
            };

            if (isDispatcher && externalStreamUrl !== null) {
                deleteExternalStreamAddress();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isDispatcher && (
                <div className={classes}>
                    {!isManifestAvailable ? (
                        <div className="hls-stream-container__loading-container">
                            {!isManifestLoading && <span>{replaceText(texts, 'external.stream.waiting')}</span>}
                            <Loading />
                        </div>
                    ) : (
                        ''
                    )}
                    <video ref={videoRef} id="hls-stream" controls={false}></video>
                </div>
            )}
            {!isDispatcher && (
                <div className="hls-stream-container">
                    {!isManifestAvailable && (
                        <div className="hls-stream-container--is-loading">
                            <span>{replaceText(texts, 'external.stream.waiting')}</span>
                            <Loading />
                        </div>
                    )}
                    <video ref={videoRef} id="hls-stream"></video>
                </div>
            )}
        </>
    );
};

// PropTypes for this Component
HLSPlayer.propTypes = {
    source: PropTypes.string.isRequired,
};

import { DEBUG } from "../../config";
import { muteMicContactDispatch, setIsLeavingConferenceDispatch } from "../../redux/actions/conferencing";
import { addLogDispatch } from "../../redux/actions/logs";
import { denyJoinConversationPermissionDispatch, grantJoinConversationPermissionDispatch } from "../../redux/actions/permissions";
import { invitedUserWebRTCManager } from "../webrtcManagers/InvitedUserWebRTCManager";
import reduxStore from '../../redux/store';
import { addSessionGPSDispatch, addSessionInfosDispatch, dispatchAddSessionChatHistory } from "../../redux/actions/session";
import { dispatchSetIncomingFocusWindow } from "../../redux/actions/focus";
import { activateAudioStreamCallerDispatch, activateChatConferenceDispatch, activateExternalStreamDispatch, activateGPSDispatch, deactivateAudioStreamCallerDispatch, deactivateChatConferenceDispatch, deactivateExternalStreamDispatch, deactivateGPSDispatch } from "../../redux/actions/application";
import { dispatchAllowPainting, dispatchDisallowPainting } from "../../redux/actions/paint";
import { WebRtcMessageDispatcher } from "../../types";
import store from "../../redux/store";
import { addAllDevices, updateActiveDeviceId } from "../../redux/slices/sessionHandlingSlice";

export const handleContactMessageConference = e => {
    const message = JSON.parse(e.content);
    if (message.data === WebRtcMessageDispatcher.TOGGLE_MICROPHONE) {
        invitedUserWebRTCManager.muteMic();
        muteMicContactDispatch();
    }

    if (message.data === WebRtcMessageDispatcher.DISPATCHER_LEFT) {
        setIsLeavingConferenceDispatch();
    }

    if (message.data === WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED) {
        grantJoinConversationPermissionDispatch();
        if (DEBUG) addLogDispatch(['join request granted:']);
    }

    if (message.data === WebRtcMessageDispatcher.JOIN_REQUEST_IS_DECLINED) {
        if (!reduxStore.getState().permissions.joinConversationPermission) denyJoinConversationPermissionDispatch();
        if (DEBUG) addLogDispatch(['join request declined:']);
    }

    if (message.data === WebRtcMessageDispatcher.SESSION_DATA) {
        if (message.sessionData.long !== reduxStore.getState().session.long) {
            addSessionGPSDispatch({
                long: message.sessionData.long,
                lat: message.sessionData.lat,
                accuracy: message.sessionData.accuracy,
            });
        }
        if (message.sessionData.browser !== reduxStore.getState().session.browser) {
            addSessionInfosDispatch({
                osName: message.sessionData.osName,
                osVersion: message.sessionData.osVersion,
                browser: message.sessionData.browser,
            });
        }
        if (message.sessionData.chatHistory.length !== 0 && message.sessionData.chatHistory.length !== reduxStore.getState().session.chatHistory.length) {
            dispatchAddSessionChatHistory(message.sessionData.chatHistory);
        }
        if (message.sessionData.currentFocusFeature) {
            dispatchSetIncomingFocusWindow(message.sessionData.currentFocusFeature);
        }
    }

    if (message.data === WebRtcMessageDispatcher.SESSION_HANDLING_DATA) {
        store.dispatch(updateActiveDeviceId(message.activeDeviceId));
        store.dispatch(addAllDevices(message.devices));
    }

    if (message.data === WebRtcMessageDispatcher.AUDIO_ACTIVATION_STATUS) {
        if (message.audioIsActive) {
            activateAudioStreamCallerDispatch();
        } else {
            deactivateAudioStreamCallerDispatch();
        }
    }

    if (message.data === WebRtcMessageDispatcher.CURRENT_FOCUS_FEATURE) {
        dispatchSetIncomingFocusWindow(message.currentFocusFeature);
    }

    if (message.data === WebRtcMessageDispatcher.TOGGLE_GPS) {
        if (message.state) {
            if (!reduxStore.getState().application.gpsIsActive) {
                activateGPSDispatch();
            }
        } else {
            deactivateGPSDispatch();
        }
    }

    if (message.data === WebRtcMessageDispatcher.TOGGLE_CHAT) {
        if (message.state) {
            activateChatConferenceDispatch();
        } else {
            deactivateChatConferenceDispatch();
        }
    }

    if (message.data === WebRtcMessageDispatcher.ALLOW_PAINTING) {
        var allowPainting = message.state;
        if (allowPainting) {
            if (!reduxStore.getState().paint.isPaintingAllowed) {
                dispatchAllowPainting();
            }
        } else {
            dispatchDisallowPainting();
        }
    }

    if (message && message.data === WebRtcMessageDispatcher.TOGGLE_EXTERNAL_STREAMING) {
        if (message.state) {
            if (!reduxStore.getState().application.externalStreamIsActive) {
                activateExternalStreamDispatch();
            }
        } else {
            deactivateExternalStreamDispatch();
        }
    }
};
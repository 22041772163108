import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Device, SmartConnectKnownDevice } from '../../types/sessionHandling.types';

interface SessionHandlingState {
    sessionHandoverIsActive: boolean;
    sessionHandoverSuccessShown: boolean;
    sessionHijackingIsActive: boolean;
    isHeadMountedDisplayDevice: boolean;
    activeDeviceId: string | null;
    devices: Array<Device>;
    knownSmartConnectDevices: Array<SmartConnectKnownDevice>;
}

// Initial state
const initialState: SessionHandlingState = {
    sessionHandoverIsActive: false,
    sessionHandoverSuccessShown: false,
    sessionHijackingIsActive: false,
    isHeadMountedDisplayDevice: false,
    activeDeviceId: null,
    knownSmartConnectDevices: [],
    devices: [],
};

const sessionHandlingSlice = createSlice({
    name: 'sessionHandling',
    initialState,
    reducers: {
        addSessionDevice: (state, action) => {
            state.devices.push(action.payload);
        },
        removeSessionDevice: (state, action: PayloadAction<string>) => {
            state.devices = state.devices.filter(device => device.contact.userData.id !== action.payload);
        },
        replaceCallerDevice: (state, action: PayloadAction<{ currentDevice: Device; newDevice: Device }>) => {
            const { currentDevice, newDevice } = action.payload;
            state.devices = state.devices.map(device => {
                if (device.contact.userData.id === currentDevice.contact.userData.id) {
                    return { ...device, contact: newDevice.contact };
                }
                return device;
            });
        },
        activateSessionHandover: state => {
            state.sessionHandoverIsActive = true;
        },
        deactivateSessionHandover: state => {
            state.sessionHandoverIsActive = false;
        },
        setIsHeadMountedDisplayDevice: state => {
            state.isHeadMountedDisplayDevice = true;
        },
        unsetIsHeadMountedDisplayDevice: state => {
            state.isHeadMountedDisplayDevice = false;
        },
        updateKnownSmartConnectDevice: (state, action: PayloadAction<SmartConnectKnownDevice>) => {
            // check if device is already known, if so, update it 
            // else add it to the known devices
            const deviceIndex = state.knownSmartConnectDevices.findIndex(device => device.id === action.payload.id);
            if (deviceIndex !== -1) {
                state.knownSmartConnectDevices[deviceIndex] = action.payload;
            } else {
                state.knownSmartConnectDevices.push(action.payload);
            }
        },
        updateActiveDeviceId: (state, action: PayloadAction<string | null>) => {
            state.activeDeviceId = action.payload;
        },
        showSessionHandoverSuccess: state => {
            state.sessionHandoverSuccessShown = true;
        },
        hideSessionHandoverSuccess: state => {
            state.sessionHandoverSuccessShown = false;
        },
        activateSessionHijacking: state => {
            state.sessionHijackingIsActive = true;
        },
        deactivateSessionHijacking: state => {
            state.sessionHijackingIsActive = false;
        },
        // TODO: add type for dispatcherMessage
        addChatHistory: (state, action: PayloadAction<{ activeDeviceId: string; dispatcherMessage: any }>) => {
            const { activeDeviceId, dispatcherMessage } = action.payload;
            const activeDevice = state.devices.find(device => device.contact.userData.id === activeDeviceId);
            if (activeDevice) {
                activeDevice.chatHistory.push(dispatcherMessage);
            }
        },
        // TODO: add type for geolocation
        addGeoLocation: (state, action: PayloadAction<{ activeDeviceId: string; geolocation: any }>) => {
            const { activeDeviceId, geolocation } = action.payload;
            const activeDevice = state.devices.find(device => device.contact.userData.id === activeDeviceId);
            if (activeDevice) {
                activeDevice.geolocation = geolocation;
            }
        },
        addAllDevices: (state, action: PayloadAction<Array<Device>>) => {
            state.devices = action.payload;
        },
        resetSessionHandlingData: () => initialState,
    },
});

export const {
    addSessionDevice,
    removeSessionDevice,
    replaceCallerDevice,
    activateSessionHandover,
    deactivateSessionHandover,
    setIsHeadMountedDisplayDevice,
    unsetIsHeadMountedDisplayDevice,
    updateActiveDeviceId,
    updateKnownSmartConnectDevice,
    showSessionHandoverSuccess,
    hideSessionHandoverSuccess,
    activateSessionHijacking,
    deactivateSessionHijacking,
    addChatHistory,
    addGeoLocation,
    addAllDevices,
    resetSessionHandlingData,
} = sessionHandlingSlice.actions;

export default sessionHandlingSlice.reducer;

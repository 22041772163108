import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './BidiPanel.scss';
import { connect } from 'react-redux';
import NoBidi from '../Icons/NoBidi';
import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';
import ActiveVideoWithBlur from '../Icons/ActiveVideoWithBlur';
import InactiveVideoWithImage from '../Icons/InactiveVideoWithImage';
import InactiveVideoNoEffect from '../Icons/InactiveVideoNoEffect';
import ActiveVideoNoEffect from '../Icons/ActiveVideoNoEffect';
import InactiveVideoWithBlur from '../Icons/InactiveVideoWithBlur';
import ActiveVideoWithImage from '../Icons/ActiveVideoWithImage';
import {
    activateBidiBackgroundDispatch,
    activateBidiBlurDispatch,
    deactivateBidiBackgroundDispatch,
    deactivateBidiBlurDispatch,
} from '../../redux/actions/application';
import { replaceText } from '../../helper/helper';

const BidiPanel = ({ bidiIsActive, bidiBlurIsActive, bidiBackgroundIsActive, videoBackgroundImage, texts }) => {
    const [videoProcessorIsLoading, setVideoProcessorIsLoading] = useState(false);
    let videoProcessorToggleTimeout = useRef(0);

    let classes = `bidi-panel ${bidiIsActive ? 'bidi-panel--active' : ''}`;
    let innerWwrapperClasses = `bidiContainer__inner-wrapper ${videoProcessorIsLoading ? 'blur-transition--isLoading' : ''}`;

    const startToggleTimeout = useCallback(() => {
        setVideoProcessorIsLoading(true);
        videoProcessorToggleTimeout.current = setTimeout(() => {
            setVideoProcessorIsLoading(false);
        }, 2000);
    }, []);

    const activateBidi = useCallback(async () => {
        await dispatcherWebRTCManager.stopBidi();
        await dispatcherWebRTCManager.startBidi();
        startToggleTimeout();
    }, [startToggleTimeout]);

    const activateBlur = useCallback(async () => {
        await dispatcherWebRTCManager.stopBidi();
        await dispatcherWebRTCManager.startVideoWithEffect('blur');
        startToggleTimeout();
    }, [startToggleTimeout]);

    const activateBackgroundImage = useCallback(async () => {
        await dispatcherWebRTCManager.stopBidi();
        await dispatcherWebRTCManager.startVideoWithEffect('backgroundImage');
        startToggleTimeout();
    }, [startToggleTimeout]);

    const disablePip = () => {
        const videoElement = document.getElementById('bidi-stream');
        if (videoElement) {
            videoElement.disablePictureInPicture = true;
        }  
    }

    // disable picture-in-picture on every new render
    useEffect(() => {
        disablePip();
    })

    useEffect(() => {
        if ((bidiIsActive && !bidiBlurIsActive && !bidiBackgroundIsActive)) {
            activateBidi();
        }
    }, [bidiIsActive, bidiBlurIsActive, bidiBackgroundIsActive, activateBidi]);

    useEffect(() => {
        if ((bidiIsActive && bidiBlurIsActive && !bidiBackgroundIsActive)) {
            activateBlur();
        }
    }, [bidiIsActive, bidiBlurIsActive, bidiBackgroundIsActive, activateBlur]);

    useEffect(() => {
        if ((bidiIsActive && !bidiBlurIsActive && bidiBackgroundIsActive)) {
            activateBackgroundImage();
        }
    }, [bidiIsActive, bidiBlurIsActive, bidiBackgroundIsActive, activateBackgroundImage]);

    useEffect(() => {
        return () => {
            clearTimeout(videoProcessorToggleTimeout);
        };
    }, [videoProcessorToggleTimeout]);

    return (
        <div className={classes}>
            <div className="bidi-panel__placeholder">
                <NoBidi />
            </div>
            <div className={innerWwrapperClasses}>
                <div id="bidiContainer__inner"></div>
                <div className="button__wrapper">
                    {bidiIsActive && (
                        <span
                            title={replaceText(texts, 'video.mode.noEffect')}
                            onClick={() => {
                                bidiBlurIsActive && deactivateBidiBlurDispatch();
                                bidiBackgroundIsActive && deactivateBidiBackgroundDispatch();
                            }}>
                            {!bidiBlurIsActive && !bidiBackgroundIsActive ? <ActiveVideoNoEffect /> : <InactiveVideoNoEffect />}
                        </span>
                    )}
                    {bidiIsActive && (
                        <span
                            title={replaceText(texts, 'video.mode.blur')}
                            onClick={() => {
                                bidiBackgroundIsActive && deactivateBidiBackgroundDispatch();
                                activateBidiBlurDispatch();
                            }}>
                            {bidiBlurIsActive ? <ActiveVideoWithBlur /> : <InactiveVideoWithBlur />}
                        </span>
                    )}
                    {bidiIsActive && videoBackgroundImage.length !== 0 && (
                        <span
                            title={replaceText(texts, 'video.mode.backgroundImage')}
                            onClick={() => {
                                bidiBlurIsActive && deactivateBidiBlurDispatch();
                                activateBidiBackgroundDispatch();
                            }}>
                            {bidiBackgroundIsActive ? <ActiveVideoWithImage /> : <InactiveVideoWithImage />}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

// PropTypes for this Component
BidiPanel.propTypes = {
    bidiIsActive: PropTypes.bool,
    bidiBlurIsActive: PropTypes.bool,
    bidiBackgroundIsActive: PropTypes.bool,
    videoBackgroundImage: PropTypes.string,
    videoStreamPermission: PropTypes.bool,
    texts: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        bidiIsActive: state.application.bidiIsActive,
        bidiBlurIsActive: state.application.bidiBlurIsActive,
        bidiBackgroundIsActive: state.application.bidiBackgroundIsActive,
        videoBackgroundImage: state.session.videoBackgroundImage,
        videoStreamPermission: state.permissions.videoStreamPermission,
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(BidiPanel);

import { createSlice } from '@reduxjs/toolkit';

interface OverlayState {
    snapshotOverlayIsShown: boolean;
    streamRecordingOverlayIsShown: boolean;
}

const initialState: OverlayState = {
    snapshotOverlayIsShown: false,
    streamRecordingOverlayIsShown: false,
};

const overlaySlice = createSlice({
    name: 'overlay',
    initialState,
    reducers: {
        showSnapshotOverlay(state) {
            state.snapshotOverlayIsShown = true;
        },
        hideSnapshotOverlay(state) {
            state.snapshotOverlayIsShown = false;
        },
        showStreamRecordingOverlay(state) {
            state.streamRecordingOverlayIsShown = true;
        },
        hideStreamRecordingOverlay(state) {
            state.streamRecordingOverlayIsShown = false;
        },
        resetAllOverlays() {
            return initialState;
        },
    },
});

export const { 
    showSnapshotOverlay, 
    hideSnapshotOverlay, 
    showStreamRecordingOverlay, 
    hideStreamRecordingOverlay, 
    resetAllOverlays 
} = overlaySlice.actions;

export default overlaySlice.reducer;

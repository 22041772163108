export type UserType = 'caller' | 'dispatcher' | 'conference';

export type MatchParams = {
    id: string;
    sessionId: string;
};

export type Match = {
    params: MatchParams;
    isExact: boolean;
    path: string;
    url: string;
};

export const FILE_TYPE = {
    VIDEO: 'video',
    AUDIO: 'audio',
    IMAGE: 'img',
    DOCUMENT: 'doc',
    PRESENTATION: 'present',
    TABLE: 'table',
};

export const RECORDING_TYPE = {
    VIDEO: 'video',
    AUDIO: 'audio',
};

export const SMART_CONNECT_FEATURES = {
    SESSION_HANDOVER: 'SESSION_HANDOVER',
    EXTERNAL_STREAM: 'EXTERNAL_STREAM',
};

export enum WebRtcManagerType {
    DISPATCHER = 'DISPATCHER',
    CALLER = 'CALLER',
    INVITED_USER = 'INVITED_USER',
}

export enum WebRtcMessageCaller {
    ACCEPT_DISCLAIMER = 'ACCEPT_DISCLAIMER',
    ALLOW_PAINTING = 'ALLOW_PAINTING',
    BATTERY = 'BATTERY',
    CALLER_DISCLAIMER_VISIBLE = 'CALLER_DISCLAIMER_VISIBLE',
    CALLER_LOADED_PAGE = 'CALLER_LOADED_PAGE',
    CALLER_MUTED_MIC = 'CALLER_MUTED_MIC',
    CALLER_UNMUTED_MIC = 'CALLER_UNMUTED_MIC',
    CALLER_PAINT_POINTS = 'CALLER_PAINT_POINTS',
    CALLER_UPLOADED_FILE = 'CALLER_UPLOADED_FILE',
    CHAT_MESSAGE = 'CHAT_MESSAGE',
    CHECK_IF_STREAM_RESOLVED = 'CHECK_IF_STREAM_RESOLVED',
    COORDS = 'COORDS',
    COORDS_ERROR = 'COORDS_ERROR',
    COORDS_NOT_AVAILABLE = 'COORDS_NOT_AVAILABLE',
    COORDS_PERMISSION_DENIED = 'COORDS_PERMISSION_DENIED',
    DECLINE_DISCLAIMER = 'DECLINE_DISCLAIMER',
    DELETE_PAINT_POINTS_CALLER = 'DELETE_PAINT_POINTS_CALLER',
    DEVICE_INFO = 'DEVICE_INFO',
    HD_FILE_CALLER_IS_BUSY = 'HD_FILE_CALLER_IS_BUSY',
    HD_FILE_TRANSFER_STARTED = 'HD_FILE_TRANSFER_STARTED',
    HEARTBEAT_PONG = 'HEARTBEAT_PONG',
    IS_HEAD_MOUNTED_DEVICE = 'IS_HEAD_MOUNTED_DEVICE',
    NETWORK_INFO = 'NETWORK_INFO',
    PAGE_IN_FOCUS = 'PAGE_IN_FOCUS',
    PAGE_NOT_IN_FOCUS = 'PAGE_NOT_IN_FOCUS',
    PHOTO_PERMISSION = 'PHOTO_PERMISSION',
    REQUEST_JOIN_PERMISSION = 'REQUEST_JOIN_PERMISSION',
    REQUEST_STREAM_RETRY = 'REQUEST_STREAM_RETRY',
    STREAM_RECORDING_PERMISSION = 'STREAM_RECORDING_PERMISSION',
    SYSTEM = 'SYSTEM',
    TOGGLE_FEATURE_FOCUS = 'TOGGLE_FEATURE_FOCUS',
    UPDATE_CALLER_LANGUAGE = 'UPDATE_CALLER_LANGUAGE',
    USER_CONNECTION_QUALITY = 'USER_CONNECTION_QUALITY',
    VIDEO_NOT_AVAILABLE = 'VIDEO_NOT_AVAILABLE',
}

export enum WebRtcMessageConference {
    IS_LEAVING = 'IS_LEAVING',
    MICROPHONE_ERROR = 'MICROPHONE_ERROR',
    MICROPHONE_IS_MUTED = 'MICROPHONE_IS_MUTED',
    REQUEST_JOIN_PERMISSION = 'REQUEST_JOIN_PERMISSION',
}

export enum WebRtcMessageDispatcher {
    ALLOW_PAINTING = 'ALLOW_PAINTING',
    AUDIO_ACTIVATION_STATUS = 'AUDIO_ACTIVATION_STATUS',
    BIDI_IS_DEACTIVATED = 'BIDI_IS_DEACTIVATED',
    CHAT_MESSAGE = 'CHAT_MESSAGE',
    CURRENT_FOCUS_FEATURE = 'CURRENT_FOCUS_FEATURE',
    DELETE_ALL_PAINT_POINTS = 'DELETE_ALL_PAINT_POINTS',
    DISPATCHER_LEFT = 'DISPATCHER_LEFT',
    DISPATCHER_PAINT_POINTS = 'DISPATCHER_PAINT_POINTS',
    DISPATCHER_UPLOADED_FILE = 'DISPATCHER_UPLOADED_FILE',
    HANDOVER_SESSION = 'HANDOVER_SESSION',
    HEARTBEAT_PING = 'HEARTBEAT_PING',
    HD_FILE_TRANSFER_ENDED = 'HD_FILE_TRANSFER_ENDED',
    JOIN_REQUEST_IS_GRANTED = 'JOIN_REQUEST_IS_GRANTED',
    JOIN_REQUEST_IS_DECLINED = 'JOIN_REQUEST_IS_DECLINED',
    RECEIVE_CALLER_PAGE_LOADED = 'RECEIVE_CALLER_PAGE_LOADED',
    SESSION_DATA = 'SESSION_DATA',
    SESSION_HANDLING_DATA = 'SESSION_HANDLING_DATA',
    SET_FEATURE_FOCUS = 'SET_FEATURE_FOCUS',
    TIME_TO_LIVE_SETTING = 'TIME_TO_LIVE_SETTING',
    TOGGLE_AUDIO_STREAM = 'TOGGLE_AUDIO_STREAM',
    TOGGLE_CHAT = 'TOGGLE_CHAT',
    TOGGLE_DRAW = 'TOGGLE_DRAW',
    TOGGLE_EXTERNAL_STREAMING = 'TOGGLE_EXTERNAL_STREAMING',
    TOGGLE_GPS = 'TOGGLE_GPS',
    TOGGLE_HD_SEND = 'TOGGLE_HD_SEND',
    TOGGLE_MICROPHONE = 'TOGGLE_MICROPHONE',
    TOGGLE_SNAPSHOT = 'TOGGLE_SNAPSHOT',
    TOGGLE_STREAM_RECORDING = 'TOGGLE_STREAM_RECORDING',
    TOGGLE_VIDEO = 'TOGGLE_VIDEO',
    UNDO_LAST_PAINT_POINTS = 'UNDO_LAST_PAINT_POINTS',
    UPDATE_CHAT_TEXTS = 'UPDATE_CHAT_TEXTS',
    UPLOADED_FILES = 'UPLOADED_FILES',
}
interface ComplexObjectStorage {
    [id: string]: any;
}

const complexObjectStorage: ComplexObjectStorage = {};

export const addComplexObject = (id: string, obj: any): void => {
        complexObjectStorage[id] = obj;
};

export const getComplexObject = (id: string): any | undefined => {
    return complexObjectStorage[id];
};

export const removeComplexObject = (id: string): void => {
    delete complexObjectStorage[id];
};

export default complexObjectStorage;

export enum InviteType {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
}

export enum DispatchMode {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export const INVITATION_UPDATE_MODE = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
};

export type InvitationDetails = {
    recipient: string;
    type?: InviteType;
    isSending?: boolean;
    hasError?: boolean;
    isInEditMode?: boolean;
    wasPreviouslyDispatched?: boolean;
};
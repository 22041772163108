export const ONLY_VIDEO_FACE_ENV = {
    facingMode: 'environment',
    audioInputId: null,
    constraints: {
        audio: false,
        video: true,
    },
};

export const ONLY_VIDEO = {
    audioInputId: null,
    constraints: {
        audio: false,
        video: true,
    },
};

export const ONLY_AUDIO = {
    videoInputId: null,
    constraints: {
        audio: true,
        video: false,
    },
};

export const ONLY_BIDI_VIDEO = {
    audioInputId: null,
    constraints: {
        audio: false,
        video: true,
    },
};

export const SCREENSHARING = {
    video: {
        cursor: 'always',
    },
    audio: false,
};
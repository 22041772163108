import { ACTIVATE_WAKELOCK, DEACTIVATE_WAKELOCK, SET_IS_IN_LANDSCAPE_MODE, SET_IS_IN_PORTRAIT_MODE, SET_MOBILE_BREAKPOINT_REACHED, SET_WAKELOCK_IS_SUPPORTED, UNSET_MOBILE_BREAKPOINT_REACHED, UNSET_WAKELOCK_IS_SUPPORTED } from '../actions/deviceScreen';

export const initialState = {
    isInLandscapeMode: false,
    isMobileBreakpointReached: false,
    wakelockIsSupported: false,
    wakelockIsActive: false,
};

type DeviceScreenAction = {
    type: string;
};

const deviceScreenReducer = (state = initialState, action: DeviceScreenAction) => {
    switch (action.type) {
        case SET_IS_IN_PORTRAIT_MODE:
            return {
                ...state,
                isInLandscapeMode: false,
            };
        case SET_IS_IN_LANDSCAPE_MODE:
            return {
                ...state,
                isInLandscapeMode: true,
            };
        case SET_MOBILE_BREAKPOINT_REACHED:
            return {
                ...state,
                isMobileBreakpointReached: true,
            };
        case UNSET_MOBILE_BREAKPOINT_REACHED:
            return {
                ...state,
                isMobileBreakpointReached: false,
            };
        case SET_WAKELOCK_IS_SUPPORTED:
            return {
                ...state,
                wakelockIsSupported: true,
            };
        case UNSET_WAKELOCK_IS_SUPPORTED:
            return {
                ...state,
                wakelockIsSupported: false,
            };
        case ACTIVATE_WAKELOCK:
            return {
                ...state,
                wakelockIsActive: true,
            };
        case DEACTIVATE_WAKELOCK:
            return {
                ...state,
                wakelockIsActive: false,
            };
        default:
            return state;
    }
};

export default deviceScreenReducer;

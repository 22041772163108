import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import { replaceText } from '../../helper/helper';
import store from '../store';
import { addNotificationAndShowDispatch } from './notifications';

export const SET_SCREENSHARE_PERMISSION_GRANTED = 'SET_SCREENSHARE_PERMISSION_GRANTED';
export const SET_SCREENSHARE_PERMISSION_DENIED = 'SET_SCREENSHARE_PERMISSION_DENIED';

export const SET_AUDIO_STREAM_PERMISSION_GRANTED = 'SET_AUDIO_STREAM_PERMISSION_GRANTED';
export const SET_AUDIO_STREAM_PERMISSION_DENIED = 'SET_AUDIO_STREAM_PERMISSION_DENIED';
export const RESET_AUDIO_STREAM_PERMISSION = 'RESET_AUDIO_STREAM_PERMISSION';

export const SET_VIDEO_STREAM_PERMISSION_GRANTED = 'SET_VIDEO_STREAM_PERMISSION_GRANTED';
export const SET_VIDEO_STREAM_PERMISSION_DENIED = 'SET_VIDEO_STREAM_PERMISSION_DENIED';

export const SET_JOIN_CONVERSATION_PERMISSION_GRANTED = 'SET_JOIN_CONVERSATION_PERMISSION_GRANTED';
export const SET_JOIN_CONVERSATION_PERMISSION_DENIED = 'SET_JOIN_CONVERSATION_PERMISSION_DENIED';

export const SET_SNAPSHOT_PERMISSION_GRANTED = 'SET_SNAPSHOT_PERMISSION_GRANTED';
export const SET_SNAPSHOT_PERMISSION_DENIED = 'SET_SNAPSHOT_PERMISSION_DENIED';

export const SET_STREAM_RECORDING_PERMISSION_GRANTED = 'SET_STREAM_RECORDING_PERMISSION_GRANTED';
export const SET_STREAM_RECORDING_PERMISSION_DENIED = 'SET_STREAM_RECORDING_PERMISSION_DENIED';

export const RESET_PERMISSIONS = 'RESET_PERMISSIONS';

// Dispatcher permissions

// SCREENSHARE

const grantScreensharePermission = () => {
    return {
        type: SET_SCREENSHARE_PERMISSION_GRANTED,
    };
};

export const grantScreensharePermissionDispatch = () => {
    store.dispatch(grantScreensharePermission());
};

const denyScreensharePermission = () => {
    return {
        type: SET_SCREENSHARE_PERMISSION_DENIED,
    };
};

export const denyScreensharePermissionDispatch = () => {
    store.dispatch(denyScreensharePermission());
};

// AUDIO STREAM

const grantAudioStreamPermission = () => {
    return {
        type: SET_AUDIO_STREAM_PERMISSION_GRANTED,
    };
};

export const grantAudioStreamPermissionDispatch = () => {
    store.dispatch(grantAudioStreamPermission());
};

const denyAudioStreamPermission = () => {
    return {
        type: SET_AUDIO_STREAM_PERMISSION_DENIED,
    };
};

export const denyAudioStreamPermissionDispatch = () => {
    store.dispatch(denyAudioStreamPermission());
};

const resetAudioStreamPermission = () => {
    return {
        type: RESET_AUDIO_STREAM_PERMISSION,
    };
};

export const resetAudioStreamPermissionDispatch = () => {
    store.dispatch(resetAudioStreamPermission());
};

// VIDEO STREAM

const grantVideoStreamPermission = () => {
    return {
        type: SET_VIDEO_STREAM_PERMISSION_GRANTED,
    };
};

export const grantVideoStreamPermissionDispatch = () => {
    store.dispatch(grantVideoStreamPermission());
};

const denyVideoStreamPermission = () => {
    return {
        type: SET_VIDEO_STREAM_PERMISSION_DENIED,
    };
};

export const denyVideoStreamPermissionDispatch = () => {
    store.dispatch(denyVideoStreamPermission());
};

// JOIN CONFERENCE

const grantJoinConversationPermission = () => {
    return {
        type: SET_JOIN_CONVERSATION_PERMISSION_GRANTED,
    };
};

export const grantJoinConversationPermissionDispatch = () => {
    store.dispatch(grantJoinConversationPermission());
};

const denyJoinConversationPermission = () => {
    return {
        type: SET_JOIN_CONVERSATION_PERMISSION_DENIED,
    };
};

export const denyJoinConversationPermissionDispatch = () => {
    store.dispatch(denyJoinConversationPermission());
};

// SNAPSHOT

const grantSnapshotPermission = () => {
    return {
        type: SET_SNAPSHOT_PERMISSION_GRANTED,
    };
};

export const grantSnapshotPermissionDispatch = () => {
    store.dispatch(grantSnapshotPermission());
};

const denySnapshotPermission = () => {
    return {
        type: SET_SNAPSHOT_PERMISSION_DENIED,
    };
};

export const denySnapshotPermissionDispatch = () => {
    store.dispatch(denySnapshotPermission());
};

// STREAM RECORDING

const grantStreamRecordingPermission = () => {
    return {
        type: SET_STREAM_RECORDING_PERMISSION_GRANTED,
    };
};

export const grantStreamRecordingPermissionDispatch = () => {
    store.dispatch(grantStreamRecordingPermission());
};

const denyStreamRecordingPermission = () => {
    return {
        type: SET_STREAM_RECORDING_PERMISSION_DENIED,
    };
};

export const denyStreamRecordingPermissionDispatch = (showNotification = false) => {
    if (showNotification) {
        addNotificationAndShowDispatch(replaceText(store.getState().texts.texts, 'recording.permission.denied'), 'error', DISPLAY_ONLY_IN_SESSION);
    }
    store.dispatch(denyStreamRecordingPermission());
};

// RESET

const resetPermissions = () => {
    return {
        type: RESET_PERMISSIONS
    }
}

export const resetPermissionsDispatch = () => {
    store.dispatch(resetPermissions());
}
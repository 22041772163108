import './ExternalStreamPanel.scss';
import { HLSPlayer } from './HLSPlayer';
import NoVideo from '../Icons/NoVideo';
import { useEffect } from 'react';
import { usePrevious } from '../../helper/hooks';
import { deactivateExternalStreamDispatch } from '../../redux/actions/application';
import { postExternalStream } from '../../api/backendApi';
import { getURLParams } from '../../helper/rtcFlowHandling';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux/store';
import { sendToggleExternalStreaming } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { addExternalStreamUrl } from '../../redux/slices/streamSlice';

type ExternalStreamOwnProps = {
    isDispatcher?: boolean;
};

// eslint-disable-next-line
const ExternalStreamPanel: React.FC<ExternalStreamOwnProps> = ({ isDispatcher }) => {
    const externalStreamIsActive = useSelector((state: RootState) => state.application.externalStreamIsActive);
    const externalStreamUrl = useSelector((state: RootState) => state.streamSlice.externalStreamUrl);
    const videoIsActive = useSelector((state: RootState) => state.application.videoIsActive);

    const hlsStreamSource = externalStreamUrl;
    const prevVideoIsActive = usePrevious(videoIsActive);
    const { sessionId } = getURLParams();

    const droneClasses = clsx('drone-panel', {
        'drone-panel--isHidden': !externalStreamIsActive,
    });

    useEffect(() => {
        if (videoIsActive && videoIsActive !== prevVideoIsActive && externalStreamIsActive) {
            deactivateExternalStreamDispatch();
        }
    }, [videoIsActive, prevVideoIsActive, externalStreamIsActive]);

    useEffect(() => {
        const createStreamSourceAddress = async () => {
            await postExternalStream();
        };

        if (externalStreamIsActive) {
            createStreamSourceAddress();
            sendToggleExternalStreaming(true);
        }

        store.dispatch(addExternalStreamUrl(sessionId));
    }, [externalStreamIsActive, sessionId]);

    return (
        <div className={droneClasses}>
            {!externalStreamIsActive ? (
                <div className="drone-panel__placeholder">
                    <NoVideo />
                </div>
            ) : (
                <>{hlsStreamSource !== null && <HLSPlayer source={hlsStreamSource} isDispatcher={isDispatcher} />}</>
            )}
        </div>
    );
};

export default ExternalStreamPanel;

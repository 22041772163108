import React, { useState, useRef, useEffect } from 'react';
import './Invitation.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { getCurrentInviteEmailText, sendInvitationEmail, sendInvitationSms } from '../../api/backendApi';
import { getURLParams } from '../../helper/rtcFlowHandling';
import { normalizePhone, replaceText, runAfterTimeHasElapsed } from '../../helper/helper';
import Loading from '../Icons/LoadingSpinner';
import Send from '../Icons/Send';
import { Copy } from '../Icons/Copy';
import { CLIPBOARD_COPY_SUCCESS_TIMEOUT, DISPLAY_ONLY_IN_SESSION, INVITATION_DESCRIPTION_MAX_CHARS, INVITATION_LIMIT } from '../../config';
import { SentInvitationList } from './SentInvitationList';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { emailBodyTextRegex, emailRecipientRegex, phoneNumberRegex } from '../../regex';
import clsx from 'clsx';
import { DispatchMode, INVITATION_UPDATE_MODE, InvitationDetails, InviteType } from '../../types/invitation.types';

interface UpdateSentInvitationsProps {
    mode: string;
    invitation?: InvitationDetails;
    selectedIndex?: number | null | undefined;
}

export const Invitation: React.FC = () => {
    const texts = useSelector((state: RootState) => state.texts.texts);

    const [sendTo, setSendTo] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [conferenceId, setConferenceId] = useState('');
    const [url, setUrl] = useState<any>(null);

    const [sentInvitations, setSentInvitations] = useState<any>([]);
    const [textBodyHasError, setTextBodyHasError] = useState(false);

    const [showLinkCopiedSuccessfully, setShowLinkCopiedSuccessfully] = useState(false);

    const [sendButtonIsDisabled, setSendButtonIsDisabled] = useState(false);

    let emailAddDescriptionClasses = clsx('invitation__add-description-field', {
        'invitation__add-description-field--hasError': textBodyHasError,
    });

    let sendToRef = useRef<HTMLInputElement>(null);
    let emailTextRef = useRef<HTMLTextAreaElement>(null);

    let showEmailSentTimeout = null;

    const updatePendingInvitations = recipients => {
        // Create a new invitations array with updates
        const updatedInvitations = recipients.map(recipient => {
            let recipientWithoutWhiteSpace = recipient.replaceAll(' ', '');
            const isEmail = recipient && emailRecipientRegex.test(recipient);
            const isPhoneNumber = recipient && phoneNumberRegex.test(normalizePhone(recipientWithoutWhiteSpace).toString());

            if (isEmail) {
                return {
                    recipient: recipient,
                    type: InviteType.EMAIL,
                    isSending: false,
                    hasError: false,
                    isInEditMode: false,
                    wasPreviouslyDispatched: false,
                };
            } else if (isPhoneNumber) {
                return {
                    recipient: normalizePhone(recipient),
                    type: InviteType.PHONE,
                    isSending: false,
                    hasError: false,
                    isInEditMode: false,
                    wasPreviouslyDispatched: false,
                };
            } else {
                return { recipient: recipient, type: null, isSending: false, hasError: true, isInEditMode: false, wasPreviouslyDispatched: false };
            }
        });

        return [...updatedInvitations];
    };

    const handleRecipientAndMessageContentInput = () => {
        setSendTo(sendToRef.current!.value);
        setBodyText(emailTextRef.current!.value);
    };

    const isDuplicateRecipient = recipient => {
        const normalizedRecipient = normalizePhone(recipient);

        // find out if there's at least one other invitation with the same recipient.
        const duplicateCount = sentInvitations.filter(invitation => invitation.recipient === recipient || invitation.recipient === normalizedRecipient).length;

        // if there's at least two, it's a duplicate
        return duplicateCount > 1;
    };

    const calculateConferenceId = () => {
        const { callerId, sessionId } = getURLParams();
        const conferenceId = callerId + '/' + sessionId;
        setConferenceId(conferenceId);
    };

    const copyLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            try {
                navigator.clipboard.writeText(url!);
                setShowLinkCopiedSuccessfully(true);
                runAfterTimeHasElapsed(() => setShowLinkCopiedSuccessfully(false), CLIPBOARD_COPY_SUCCESS_TIMEOUT);
            } catch {
                addNotificationAndShowDispatch('error.cpy_lnk_err', 'error', DISPLAY_ONLY_IN_SESSION);
            }
        }
    };

    const validateBodyText = (): boolean => {
        if (emailBodyTextRegex.test(bodyText)) {
            setTextBodyHasError(true);
            return true;
        } else {
            setTextBodyHasError(false);
            return false;
        }
    };

    const sendEmail = async (updatedInvitation, dispatchMode: DispatchMode, selectedIndex = null) => {
        updateSentInvitations({
            mode: dispatchMode,
            invitation: { ...updatedInvitation, type: InviteType.EMAIL, isSending: true, hasError: false, isInEditMode: false },
            selectedIndex,
        });

        const response = await sendInvitationEmail({
            to: updatedInvitation.recipient.replace(/ /g, ''),
            message: bodyText,
            conference: conferenceId,
        }).catch(() => {
            runAfterTimeHasElapsed(() => {
                updateSentInvitations({
                    mode: DispatchMode.EDIT,
                    invitation: {
                        ...updatedInvitation,
                        type: InviteType.EMAIL,
                        isSending: false,
                        hasError: true,
                        isInEditMode: false,
                        wasPreviouslyDispatched: false,
                    },
                    selectedIndex,
                });
            }, 500);
        });

        if (response.ok) {
            updateSentInvitations({
                mode: DispatchMode.EDIT,
                invitation: {
                    ...updatedInvitation,
                    type: InviteType.EMAIL,
                    isSending: false,
                    hasError: false,
                    isInEditMode: false,
                    wasPreviouslyDispatched: true,
                },
                selectedIndex,
            });
        }
    };

    const sendSms = async (invitation: InvitationDetails, dispatchMode: DispatchMode, selectedIndex: number | null = null) => {
        const normalizedPhone = normalizePhone(invitation.recipient).toString();

        updateSentInvitations({
            mode: dispatchMode,
            invitation: {
                ...invitation,
                recipient: normalizedPhone,
                type: InviteType.PHONE,
                isSending: true,
                hasError: false,
                isInEditMode: false,
            },
            selectedIndex,
        });

        const response = await sendInvitationSms({ conference: conferenceId, message: bodyText, phone: normalizedPhone }).catch(e => {
            updateSentInvitations({
                mode: DispatchMode.EDIT,
                invitation: {
                    ...invitation,
                    recipient: normalizedPhone,
                    type: InviteType.PHONE,
                    isSending: false,
                    hasError: true,
                    isInEditMode: false,
                    wasPreviouslyDispatched: false,
                },
                selectedIndex,
            });
        });

        if (response.messageText) {
            updateSentInvitations({
                mode: DispatchMode.EDIT,
                invitation: {
                    ...invitation,
                    recipient: normalizedPhone,
                    type: InviteType.PHONE,
                    isSending: false,
                    hasError: false,
                    isInEditMode: false,
                    wasPreviouslyDispatched: true,
                },
                selectedIndex,
            });
        }
    };

    const validateAndDispatchPendingInvitations = async (e: React.FormEvent) => {
        e.preventDefault();

        if (validateBodyText()) {
            return;
        }

        let recipients = sendTo
            .replace(/,/g, ';')
            .split(';')
            .map(entry => entry.trim());

        // filter empty entries
        recipients = recipients.filter(entry => entry !== '');

        if (recipients.length === 0) {
            return;
        }

        const updatedInvitations = updatePendingInvitations(recipients);

        if (!textBodyHasError && updatedInvitations.length > 0) {
            let invitationCount = sentInvitations.length;
            setSendButtonIsDisabled(true);

            for (const newInvitation of updatedInvitations) {
                invitationCount = invitationCount + 1;
                if (invitationCount <= INVITATION_LIMIT) {
                    validateAndDispatchInvitation(newInvitation, DispatchMode.ADD);
                }
            }

            runAfterTimeHasElapsed(() => {
                setSendButtonIsDisabled(false);
                setSendTo('');
            }, 200);
        }
    };

    const handleEditEntry = (e: React.ChangeEvent<HTMLInputElement>, invitation: InvitationDetails, selectedIndex: number) => {
        const updatedRecipient = e.target.value;
        updateSentInvitations({
            mode: INVITATION_UPDATE_MODE.EDIT,
            invitation: { ...invitation, recipient: updatedRecipient, isInEditMode: true, wasPreviouslyDispatched: false },
            selectedIndex,
        });
    };

    const handleDeleteEntry = indexToDelete => {
        updateSentInvitations({ mode: INVITATION_UPDATE_MODE.DELETE, selectedIndex: indexToDelete });
    };

    const validateAndDispatchInvitation = (invitation: InvitationDetails, dispatchMode: DispatchMode, selectedIndex: number | null = null) => {
        const { wasPreviouslyDispatched } = invitation;
        const recipientWithoutWhiteSpace = invitation.recipient.replaceAll(' ', '');
        const isEmail = emailRecipientRegex.test(invitation.recipient);
        const isPhoneNumber = phoneNumberRegex.test(recipientWithoutWhiteSpace);

        if (wasPreviouslyDispatched) {
            switch (true) {
                case isEmail:
                    sendEmail(invitation, dispatchMode, selectedIndex);
                    break;
                case isPhoneNumber:
                    sendSms(invitation, dispatchMode, selectedIndex);
                    break;
                default:
                    break;
            }
            return;
        }

        if (!wasPreviouslyDispatched && isDuplicateRecipient(invitation.recipient)) {
            updateSentInvitations({
                mode: dispatchMode,
                invitation: { ...invitation, isInEditMode: false },
                selectedIndex,
            });
            return;
        }

        if (!wasPreviouslyDispatched && !isDuplicateRecipient(invitation.recipient)) {
            switch (true) {
                case isEmail:
                    sendEmail(invitation, dispatchMode, selectedIndex);
                    break;
                case isPhoneNumber:
                    sendSms(invitation, dispatchMode, selectedIndex);
                    break;
                default:
                    updateSentInvitations({
                        mode: dispatchMode,
                        invitation: { ...invitation, isInEditMode: false },
                        selectedIndex,
                    });
                    break;
            }
        }
    };

    const updateSentInvitations = ({ mode, invitation, selectedIndex }: UpdateSentInvitationsProps) => {
        switch (true) {
            case mode === INVITATION_UPDATE_MODE.ADD:
                setSentInvitations(currentInvitations => {
                    const entryExists = currentInvitations.some(currentInvitation => currentInvitation.recipient === invitation.recipient);
                    if (!entryExists) {
                        return [...currentInvitations, { ...invitation }];
                    }
                    return currentInvitations;
                });
                break;
            case mode === INVITATION_UPDATE_MODE.EDIT:
                setSentInvitations(currentInvitations => {
                    return currentInvitations.map((currentInvitation, invitationIndex) => {
                        // check for type undefined to prevent addition of indexes
                        if (selectedIndex !== null && invitationIndex === selectedIndex) {
                            return { ...currentInvitation, ...invitation };
                        } else if (selectedIndex === null && currentInvitation.recipient === invitation.recipient) {
                            return { ...currentInvitation, ...invitation };
                        } else {
                            return currentInvitation;
                        }
                    });
                });
                break;
            case mode === INVITATION_UPDATE_MODE.DELETE:
                setSentInvitations(currentInvitations => currentInvitations.filter((currentInvitation, invitationIndex) => invitationIndex !== selectedIndex));
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(showEmailSentTimeout);
        };
    }, [showEmailSentTimeout]);

    useEffect(() => {
        const getAndSetDescriptionText = async () => {
            const bodyText = await getCurrentInviteEmailText();
            if (bodyText) {
                setBodyText(bodyText);
            }
        };

        const prepAndSetConferenceLink = () => {
            let conferenceUrl = window.location.href.replace('disptchr', 'conference'); // eslint-disable-line
            setUrl(conferenceUrl);
        };

        getAndSetDescriptionText();
        calculateConferenceId();
        prepAndSetConferenceLink();
    }, []);

    return (
        <div className="invitation">
            <form onChange={handleRecipientAndMessageContentInput}>
                <div className="invitation__add-recipient__container">
                    <span className="invitation__header">
                        <span>
                            {replaceText(texts, 'invitation.header')} <small>{replaceText(texts, 'invitation.hint', INVITATION_LIMIT)}</small>
                        </span>
                        <small>
                            {sentInvitations.length} / {INVITATION_LIMIT}
                        </small>
                    </span>
                    <div
                        className="add-recipient-field"
                        title={sentInvitations.length >= INVITATION_LIMIT ? replaceText(texts, 'invitation.limit.reached') : ''}>
                        <div className="recipient-wrapper">
                            <input
                                id="phone"
                                type="text"
                                ref={sendToRef}
                                onChange={e => setSendTo(e.target.value)}
                                placeholder={replaceText(texts, 'invitation.recipient.placeholder')}
                                value={sendTo}
                                disabled={sentInvitations.length >= INVITATION_LIMIT || sendButtonIsDisabled}></input>
                            <p>{replaceText(texts, 'invitation.recipient.hint')}</p>
                        </div>
                        <div>
                            {!sendButtonIsDisabled ? (
                                <button
                                    type="submit"
                                    className="btn btn--primary"
                                    onClick={e => validateAndDispatchPendingInvitations(e)}
                                    disabled={sentInvitations.length >= INVITATION_LIMIT}>
                                    <Send />
                                </button>
                            ) : (
                                <div className="loading-container">
                                    <Loading />
                                </div>
                            )}
                        </div>
                    </div>
                    <SentInvitationList
                        sentInvitations={sentInvitations}
                        handleEditEntry={handleEditEntry}
                        handleDeleteEntry={handleDeleteEntry}
                        validateAndDispatchInvitation={validateAndDispatchInvitation}
                    />
                </div>
                <div className={emailAddDescriptionClasses}>
                    <textarea
                        placeholder={replaceText(texts, 'invitation.placeholder')}
                        ref={emailTextRef}
                        onChange={() => {}}
                        onKeyUp={validateBodyText}
                        value={bodyText}
                        maxLength={255}></textarea>
                    {textBodyHasError && <span>{replaceText(texts, 'invitation.email.error')}</span>}
                    <label>
                        {bodyText.length}/{INVITATION_DESCRIPTION_MAX_CHARS}
                    </label>
                </div>
                <div className="button__container">
                    <button className="btn btn--primary" onClick={copyLink}>
                        <Copy />
                        {replaceText(texts, 'invitation.copy.button')}
                    </button>
                </div>
                <div className="alert-panel">
                    <p className={`link-copied alert alert--success ${showLinkCopiedSuccessfully ? '' : 'link-copied--isHidden'}`}>
                        {replaceText(texts, 'invitation.copy.info')}
                    </p>
                </div>
            </form>
        </div>
    );
};

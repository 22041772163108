import store from "../store";
import { dispatcherWebRTCManager } from "../../webrtc/webrtcManagers/DispatcherWebRTCManager";
import { WebRtcMessageDispatcher } from "../../types";

export const ADD_INVITED_USER = 'ADD_INVITED_USER';
export const REMOVE_INVITED_USER = 'REMOVE_INVITED_USER';
export const ADD_STREAM = 'ADD_STREAM';
export const REMOVE_STREAM = 'REMOVE_STREAM';
export const ADD_CONNECTION_QUALITY = 'ADD_CONNECTION_QUALITY';
export const RESET_INVITED_USERS = 'RESET_INVITED_USERS';
export const TOGGLE_AUDIO_MUTED = 'TOGGLE_AUDIO_MUTED';
export const SET_AUDIO_ERROR = 'SET_AUDIO_ERROR';
export const SET_IS_LEAVING = 'SET_IS_LEAVING';

const addInvitedUser = (data) => {
    return {
        type: ADD_INVITED_USER,
        payload: data,
    };
}

export const dispatchAddInvitedUser = data => {
    const responseMessage = {
        data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
    };

    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(responseMessage);
    
    store.dispatch(addInvitedUser(data));
};

const removeInvitedUser = data => {
    return {
        type: REMOVE_INVITED_USER,
        payload: data,
    };
};

export const dispatchRemoveInvitedUser = data => {
    store.dispatch(removeInvitedUser(data));
};

// STREAMS

const addStream = data => {
    return {
        type: ADD_STREAM,
        payload: data,
    };
};

export const dispatchAddStream = data => {
    store.dispatch(addStream(data));
};

const removeStream = data => {
    return {
        type: REMOVE_STREAM,
        payload: data,
    };
};

export const dispatchRemoveStream = data => {
    store.dispatch(removeStream(data));
};

// CONNECTION QUALITY

const addConnectionQuality = data => {
    return {
        type: ADD_CONNECTION_QUALITY,
        payload: data,
    };
};

export const dispatchAddConnectionQuality = data => {
    store.dispatch(addConnectionQuality(data));
};

// RESET INVITED USERS

const resetInvitedUsers = () => {
    return {
        type: RESET_INVITED_USERS,
    };
};

export const dispatchResetInvitedUsers = () => {
    store.dispatch(resetInvitedUsers());
};

// AUDIO MUTED 

interface ToggleAudioMuted {
    userId: string;
    isAudioMuted: boolean;
}

const toggleAudioMuted = ({ userId, isAudioMuted }: ToggleAudioMuted) => {
    return {
        type: TOGGLE_AUDIO_MUTED,
        payload: { userId, isAudioMuted },
    };
};

export const dispatchToggleAudioMuted = ({ userId, isAudioMuted }: ToggleAudioMuted) => {
    store.dispatch(toggleAudioMuted({ userId, isAudioMuted }));
};

// AUDIO MUTED 

const setAudioError = (userId : string ) => {
    return {
        type: SET_AUDIO_ERROR,
        payload: userId,
    };
};

export const dispatchSetAudioError = (userId: string) => {
    store.dispatch(setAudioError(userId));
};

// LEAVING CONFERENCE

const setIsLeaving = ({ userId }) => {
    return {
        type: SET_IS_LEAVING,
        payload: { userId },
    };
};

export const dispatchSetIsLeaving = ({ userId }) => {
    store.dispatch(setIsLeaving({ userId }));
};
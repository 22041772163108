import { errorLog } from './logging';
import { dispatcherWebRTCManager } from '../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { API_RTC_SDK_SCRIPT_TIMEOUT, API_RTC_TIMEOUT, DEBUG, PRIMARY_WEB_RTC_PLATFORM, SECONDARY_WEB_RTC_PLATFORM } from '../config';
import { getApizeeKey, postWebRtcAlias } from '../api/backendApi';
import { dispatchActivateWebRtc } from '../redux/actions/application';
import { WebRtcManagerType } from '../types';

export const webRtcLoader = () => {
    const primaryRtcPlatformScript = process.env.REACT_APP_PRIMARY_WEBRTC_JS_URL;
    const secondaryRtcPlatformScript = process.env.REACT_APP_SECONDARY_WEBRTC_JS_URL;

    const primaryRtcPlatformUrl = process.env.REACT_APP_PRIMARY_RTC_PLATFORM_URL;
    const secondaryRtcPlatformUrl = process.env.REACT_APP_SECONDARY_RTC_PLATFORM_URL;

    let rtcPlatformErrorTimer = null;

    let primaryApiRtcTimer = null;
    let secondaryApiRtcTimer = null;

    let loadInterval = null;

    // remove any previously existing api rtc script
    let existingScript = document.getElementById('api-rtc-script');
    if (existingScript) {
        document.body.removeChild(existingScript);
    }

    let script = document.createElement('script');
    script.setAttribute('id', 'api-rtc-script');

    const platformNotAvailable = e => {
        errorLog({
            message: 'apiRTC not a available - dispatcher',
            error: e,
            eventId: 'APIRTC_SCRIPT_LOADING_DISPATCHER',
        });
        window.setTimeout(() => {
            window.location.href = '/error?error=apiRTC script currently not available';
        }, 100);
    };

    // show error page if platform not available
    const startPlatformTimer = () => {
        rtcPlatformErrorTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading apiRTC script');

            platformNotAvailable({ stack: 'TIMEOUT LOADING' });
        }, API_RTC_TIMEOUT);
    };

    // callback after apiRTC script is loaded
    const handleScriptLoad = async () => {
        clearTimeout(rtcPlatformErrorTimer);
        if (DEBUG) console.log('timeout cleared');
        dispatcherWebRTCManager.apiRTC = (window as any).apiRTC; // eslint-disable-line
        if (dispatcherWebRTCManager.apiRTC.CloudApi.cloudUrl.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            // hds platform
            dispatcherWebRTCManager.webRtcAlias = primaryRtcPlatformUrl;
            dispatcherWebRTCManager.apiKey = await getApizeeKey(WebRtcManagerType.DISPATCHER, PRIMARY_WEB_RTC_PLATFORM);
        } else if (dispatcherWebRTCManager.apiRTC.CloudApi.cloudUrl.includes(SECONDARY_WEB_RTC_PLATFORM)) {
            // cloud platform
            dispatcherWebRTCManager.webRtcAlias = secondaryRtcPlatformUrl;
            dispatcherWebRTCManager.apiKey = await getApizeeKey(WebRtcManagerType.DISPATCHER, SECONDARY_WEB_RTC_PLATFORM);
        }

        if (script.src.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            await postWebRtcAlias(primaryRtcPlatformScript);
            dispatcherWebRTCManager.isPrimaryPlatformUsed = true;
        } else if (script.src.includes(SECONDARY_WEB_RTC_PLATFORM) || script.src.includes('cdn')) {
            await postWebRtcAlias(secondaryRtcPlatformScript);
            dispatcherWebRTCManager.isPrimaryPlatformUsed = false;
        }
        // TODO: place postCallId here after backend implementation
        dispatchActivateWebRtc();
    };

    const checkLoaded = () => {
        if ((window as any).apiRTC) {
            clearInterval(primaryApiRtcTimer);
            clearInterval(secondaryApiRtcTimer);
            clearInterval(loadInterval);
            handleScriptLoad();
        }
    };

    const loadPrimaryScriptAndStartTimer = () => {
        loadPrimaryWebRtcScript();
        primaryApiRtcTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading primary apiRTC script. loading secondary script...');
            loadSecondaryScriptAndStartTimer();
        }, API_RTC_SDK_SCRIPT_TIMEOUT);
    };

    const loadPrimaryWebRtcScript = () => {
        script.setAttribute('src', addTimestampToSDK(primaryRtcPlatformScript));
        document.body.appendChild(script);
    };

    const loadSecondaryScriptAndStartTimer = () => {
        loadSecondaryWebRtcScript();
        secondaryApiRtcTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading secondary apiRTC script.');
        }, API_RTC_SDK_SCRIPT_TIMEOUT);
    };

    const loadSecondaryWebRtcScript = () => {
        document.body.removeChild(script);
        script = document.createElement('script');
        script.setAttribute('id', 'api-rtc-script');
        script.setAttribute('src', addTimestampToSDK(secondaryRtcPlatformScript));
        document.body.appendChild(script);
    };

    const addTimestampToSDK = (script: string) => {
        // Prevents script loading from browser cache
        var timestamp = Math.floor(Date.now() / 1000);
        var sdkUrl = script + '?timestamp=' + timestamp;

        return sdkUrl;
    };

    loadPrimaryScriptAndStartTimer();
    startPlatformTimer();
    loadInterval = setInterval(checkLoaded, 200);
}
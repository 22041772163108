import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StreamState {
    // TODO: create type for streams
    dispatcherScreenshareStream: any;
    dispatcherBidiStream: any;
    dispatcherAudioStream: any;
    conferenceUserStreams: any[];
    ownStreamConferenceUser: any;
    callerStream: any;
    callerAudioStream: any;
    externalStreamUrl: string | null;
}

const initialState: StreamState = {
    dispatcherScreenshareStream: null,
    dispatcherBidiStream: null,
    dispatcherAudioStream: null,
    conferenceUserStreams: [],
    ownStreamConferenceUser: null,
    callerStream: null,
    callerAudioStream: null,
    externalStreamUrl: null,
};

const streamSlice = createSlice({
    name: 'stream',
    initialState,
    reducers: {
        addCallerStream(state, action: PayloadAction<any>) {
            state.callerStream = { streamId: action.payload };
        },
        removeCallerStream(state) {
            state.callerStream = null;
        },
        addCallerAudioStream(state, action: PayloadAction<any>) {
            state.callerAudioStream = { streamId: action.payload };
        },
        removeCallerAudioStream(state) {
            state.callerAudioStream = null;
        },
        addDispatcherStream(state, action: PayloadAction<any>) {
            state.dispatcherScreenshareStream = { streamId: action.payload };
        },
        removeDispatcherStream(state) {
            state.dispatcherScreenshareStream = null;
        },
        addDispatcherBidiStream(state, action: PayloadAction<any>) {
            state.dispatcherBidiStream = { streamId: action.payload };
        },
        removeDispatcherBidiStream(state) {
            state.dispatcherBidiStream = null;
        },
        addDispatcherAudioStream(state, action: PayloadAction<any>) {
            state.dispatcherAudioStream = { streamId: action.payload };
        },
        removeDispatcherAudioStream(state) {
            state.dispatcherAudioStream = null;
        },
        addConferenceUserStream(state, action: PayloadAction<any>) {
            state.conferenceUserStreams.push({ streamId: action.payload });
        },
        removeConferenceUserStream(state, action: PayloadAction<string>) {
            state.conferenceUserStreams = state.conferenceUserStreams.filter(stream => stream.streamId !== action.payload);
        },
        addOwnStreamConferenceUser(state, action: PayloadAction<any>) {
            state.ownStreamConferenceUser = { streamId: action.payload };
        },
        removeOwnStreamConferenceUser(state) {
            state.ownStreamConferenceUser = null;
        },
        addExternalStreamUrl(state, action: PayloadAction<string>) {
        const streamUrl = process.env.REACT_APP_EXTERNAL_STREAM_URL + action.payload.slice(action.payload.length - 3, action.payload.length);
        state.externalStreamUrl = streamUrl;
        },
        removeExternalStreamUrl(state) {
            state.externalStreamUrl = null;
        },
        resetAllStreams(state) {
            return initialState;
        },
    },
});

export const {
    addCallerStream,
    removeCallerStream,
    addCallerAudioStream,
    removeCallerAudioStream,
    addDispatcherStream,
    removeDispatcherStream,
    addDispatcherBidiStream,
    removeDispatcherBidiStream,
    addDispatcherAudioStream,
    removeDispatcherAudioStream,
    addConferenceUserStream,
    removeConferenceUserStream,
    addOwnStreamConferenceUser,
    removeOwnStreamConferenceUser,
    addExternalStreamUrl,
    removeExternalStreamUrl,
    resetAllStreams,
} = streamSlice.actions;

export default streamSlice.reducer;

import {
    addCallerStream as addCallerStreamAction,
    removeCallerStream as removeCallerStreamAction,
    addCallerAudioStream as addCallerAudioStreamAction,
    removeCallerAudioStream as removeCallerAudioStreamAction,
    addDispatcherStream as addDispatcherStreamAction,
    removeDispatcherStream as removeDispatcherStreamAction,
    addDispatcherBidiStream as addDispatcherBidiStreamAction,
    removeDispatcherBidiStream as removeDispatcherBidiStreamAction,
    addDispatcherAudioStream as addDispatcherAudioStreamAction,
    removeDispatcherAudioStream as removeDispatcherAudioStreamAction,
    addConferenceUserStream as addConferenceUserStreamAction,
    removeConferenceUserStream as removeConferenceUserStreamAction,
    addOwnStreamConferenceUser as addOwnStreamConferenceUserAction,
    removeOwnStreamConferenceUser as removeOwnStreamConferenceUserAction,
} from '../slices/streamSlice';
import { addComplexObject, removeComplexObject } from '../../helper/complexObjectStorage';

// streamSlice

// DISPATCHER

export const addDispatcherStream = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addDispatcherStreamAction(payload.streamId));
};

export const removeDispatcherStream = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeDispatcherStreamAction());
};

export const addDispatcherBidiStream = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addDispatcherBidiStreamAction(payload.streamId));
};

export const removeDispatcherBidiStream = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeDispatcherBidiStreamAction());
};

export const addDispatcherAudioStream = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addDispatcherAudioStreamAction(payload.streamId));
};

export const removeDispatcherAudioStream = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeDispatcherAudioStreamAction());
};

// CALLER

export const addCallerStream = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addCallerStreamAction(payload.streamId));
};

export const removeCallerStream = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeCallerStreamAction());
};

export const addCallerAudioStream = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addCallerAudioStreamAction(payload.streamId));
};

export const removeCallerAudioStream = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeCallerAudioStreamAction());
};

// INVITED USERS

export const addConferenceUserStream = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addConferenceUserStreamAction(payload.streamId));
};

export const removeConferenceUserStream = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeConferenceUserStreamAction(payload.streamId));
};

export const addOwnStreamConferenceUser = payload => dispatch => {
    // Add unserializable object to complex object storage
    addComplexObject(payload.streamId, payload);
    dispatch(addOwnStreamConferenceUserAction(payload.streamId));
};

export const removeOwnStreamConferenceUser = payload => dispatch => {
    removeComplexObject(payload.streamId);
    dispatch(removeOwnStreamConferenceUserAction());
};
import reduxStore from '../../redux/store';
import {
    activateCallerChat,
    activateDrawCallerDispatch,
    activateExternalStreamDispatch,
    activateHDSendCallerDispatch,
    deactivateAudioStreamCallerDispatch,
    deactivateBidiOtherDispatch,
    deactivateCallerChat,
    deactivateDrawCallerDispatch,
    deactivateExternalStreamDispatch,
    deactivateHDSendCallerDispatch,
    dispatchCallerFileTransferEnded,
    dispatchCallerPageLoaded,
    showRefreshDispatch,
} from '../../redux/actions/application';
import {
    dispatchActivateFocusControls,
    dispatchDeactivateFocusControls,
    dispatchResetFocusWindow,
    dispatchSetFocusWindowBidi,
    dispatchSetFocusWindowChat,
    dispatchSetFocusWindowExternalStream,
    dispatchSetFocusWindowLiveVideo,
    dispatchSetFocusWindowScreenshare,
} from '../../redux/actions/focus';
import { callerWebRTCManager } from '../webrtcManagers/CallerWebRTCManager';
import {
    dispatchAddPointsDispatcher,
    dispatchAllowPainting,
    dispatchDeletePaint,
    dispatchDisallowPainting,
    dispatchUndoLastPaint,
} from '../../redux/actions/paint';
import { muteMicCallerDispatch } from '../../redux/actions/conferencing';
import { formatDataSize, runAfterTimeHasElapsed } from '../../helper/helper';
import { addFileCallerDispatch, addFileDispatcherDispatch, removeFileCallerDispatch, removeFileDispatcherDispatch } from '../../redux/actions/files';
import { dispatchAddDispatchCenterTtl } from '../../redux/actions/session';
import { FOCUS_FEATURE_TIMEOUT, SESSION_HANDOVER_RECONNECT_TIMEOUT, SESSION_TRANSFER_SUCCESSFUL_TIMEOUT } from '../../config';
import { denyJoinConversationPermissionDispatch, grantJoinConversationPermissionDispatch } from '../../redux/actions/permissions';
import { detectHmdOrWearableDevice, sendCoords } from '../outgoingMessages/outgoingMessagesCaller';
import { WebRtcManagerType, WebRtcMessageDispatcher } from '../../types';
import store from '../../redux/store';
import { hideSessionHandoverSuccess, showSessionHandoverSuccess } from '../../redux/slices/sessionHandlingSlice';
import { unloadEventListenersCaller } from '../eventHandlers/eventHandlingCaller';
import { hideSnapshotOverlay, showSnapshotOverlay } from '../../redux/slices/overlaySlice';

export const handleContactMessageCaller = ({ e, snapshotToggleCallback, streamRecordingCallback }) => {
    if (e.sender === callerWebRTCManager.dispatcher) {
        const message = JSON.parse(e.content);
        if (message) {
            switch (message.data) {
                case WebRtcMessageDispatcher.TOGGLE_GPS:
                    callerWebRTCManager.useGPS = message.state;
                    sendCoords();
                    break;

                case WebRtcMessageDispatcher.TOGGLE_VIDEO:
                    callerWebRTCManager.useVideo = message.state;
                    callerWebRTCManager.cameraId = message.id;
                    callerWebRTCManager.cameraName = message.name;

                    if (callerWebRTCManager.useVideo) {
                        callerWebRTCManager.activateLiveVideo();
                    } else {
                        callerWebRTCManager.deactivateLiveVideoAndRemoveStream();
                    }
                    break;

                case WebRtcMessageDispatcher.TOGGLE_AUDIO_STREAM:
                    callerWebRTCManager.handleAudioStreamToggle(message.state);
                    break;

                case WebRtcMessageDispatcher.CHAT_MESSAGE:
                    callerWebRTCManager.useChat = true;
                    activateCallerChat();
                    dispatchSetFocusWindowChat(WebRtcManagerType.CALLER);
                    break;

                case WebRtcMessageDispatcher.TOGGLE_CHAT:
                    callerWebRTCManager.useChat = message.state;
                    if (callerWebRTCManager.useChat) {
                        activateCallerChat();
                    } else {
                        deactivateCallerChat();
                    }
                    break;

                case WebRtcMessageDispatcher.TOGGLE_SNAPSHOT:
                    if (message.state && !reduxStore.getState().permissions.snapshotPermission) {
                        store.dispatch(showSnapshotOverlay());
                    } else {
                        store.dispatch(hideSnapshotOverlay());
                    }
                    break;

                case WebRtcMessageDispatcher.HEARTBEAT_PING:
                    if (!reduxStore.getState().application.callerEndPageLoaded) {
                        callerWebRTCManager.handlePing();
                    }
                    break;

                case WebRtcMessageDispatcher.DISPATCHER_LEFT:
                    unloadEventListenersCaller();
                    if (reduxStore.getState().application.audioStreamIsActive) {
                        deactivateAudioStreamCallerDispatch();
                        callerWebRTCManager.muteMic();
                    }
                    if (reduxStore.getState().application.videoIsActive) {
                        callerWebRTCManager.deactivateLiveVideoAndRemoveStream();
                    }
                    callerWebRTCManager.endCall();
                    if (callerWebRTCManager.disconnectCallback) callerWebRTCManager.disconnectCallback();
                    callerWebRTCManager.clearAllTimeouts();
                    clearInterval(callerWebRTCManager.checkHeartbeatInterval);
                    break;

                case WebRtcMessageDispatcher.TOGGLE_HD_SEND:
                    var useHDSend = message.state;
                    useHDSend ? activateHDSendCallerDispatch() : deactivateHDSendCallerDispatch();
                    break;

                case WebRtcMessageDispatcher.HD_FILE_TRANSFER_ENDED:
                    dispatchCallerFileTransferEnded();
                    break;

                case WebRtcMessageDispatcher.DISPATCHER_PAINT_POINTS:
                    dispatchAddPointsDispatcher(message.points);
                    break;

                case WebRtcMessageDispatcher.UNDO_LAST_PAINT_POINTS:
                    dispatchUndoLastPaint();
                    break;

                case WebRtcMessageDispatcher.DELETE_ALL_PAINT_POINTS:
                    dispatchDeletePaint();
                    break;

                case WebRtcMessageDispatcher.TOGGLE_DRAW:
                    var useDraw = message.state;
                    snapshotToggleCallback(useDraw);
                    if (useDraw) {
                        activateDrawCallerDispatch();
                        deactivateHDSendCallerDispatch();
                    } else {
                        deactivateDrawCallerDispatch();
                    }
                    break;

                case WebRtcMessageDispatcher.ALLOW_PAINTING:
                    var allowPainting = message.state;
                    if (allowPainting) {
                        if (!reduxStore.getState().paint.isPaintingAllowed) {
                            dispatchAllowPainting();
                        }
                    } else {
                        dispatchDisallowPainting();
                    }
                    break;

                case WebRtcMessageDispatcher.TOGGLE_MICROPHONE:
                    muteMicCallerDispatch();
                    callerWebRTCManager.muteMic();
                    break;

                case WebRtcMessageDispatcher.RECEIVE_CALLER_PAGE_LOADED:
                    dispatchCallerPageLoaded();
                    break;

                case WebRtcMessageDispatcher.BIDI_IS_DEACTIVATED:
                    if (reduxStore.getState().application.bidiIsActive) {
                        deactivateBidiOtherDispatch();
                    }
                    break;

                case WebRtcMessageDispatcher.TOGGLE_STREAM_RECORDING:
                    streamRecordingCallback(message.state);
                    break;

                case WebRtcMessageDispatcher.DISPATCHER_UPLOADED_FILE: {
                    // format file size for redux store and display in chat history
                    let file = message.fileInfo;
                    const formattedFileSize = formatDataSize(file.size);
                    file = { ...file, size: formattedFileSize };
                    addFileDispatcherDispatch(file);
                    break;
                }

                case WebRtcMessageDispatcher.TIME_TO_LIVE_SETTING:
                    dispatchAddDispatchCenterTtl(message.timeToLive);
                    break;

                case WebRtcMessageDispatcher.UPLOADED_FILES:
                    if (message.uploadedFiles.dispatcherFiles.length !== 0) {
                        removeFileDispatcherDispatch();
                        message.uploadedFiles.dispatcherFiles.forEach(file => {
                            addFileDispatcherDispatch(file);
                        });
                    }
                    if (message.uploadedFiles.callerFiles.length !== 0) {
                        removeFileCallerDispatch();
                        message.uploadedFiles.callerFiles.forEach(file => {
                            addFileCallerDispatch(file);
                        });
                    }
                    break;

                case WebRtcMessageDispatcher.SET_FEATURE_FOCUS:
                    switch (true) {
                        case message.state === 'LIVE_VIDEO':
                            dispatchSetFocusWindowLiveVideo();
                            break;
                        case message.state === 'CHAT':
                            dispatchSetFocusWindowChat(WebRtcManagerType.CALLER);
                            break;
                        case message.state === 'BIDI':
                            dispatchSetFocusWindowBidi();
                            break;
                        case message.state === 'SCREEN_SHARE':
                            dispatchSetFocusWindowScreenshare();
                            break;
                        case message.state === 'EXTERNAL_STREAM':
                            dispatchSetFocusWindowExternalStream();
                            break;
                        case message.state === 'RESET':
                            dispatchResetFocusWindow(WebRtcManagerType.CALLER);
                            break;
                        default:
                            break;
                    }
                    dispatchDeactivateFocusControls();
                    runAfterTimeHasElapsed(dispatchActivateFocusControls, FOCUS_FEATURE_TIMEOUT);
                    break;

                case WebRtcMessageDispatcher.HANDOVER_SESSION:
                    store.dispatch(showSessionHandoverSuccess());
                    // show refresh button after x seconds
                    setTimeout(() => {
                        showRefreshDispatch();
                    }, SESSION_HANDOVER_RECONNECT_TIMEOUT);
                    if (reduxStore.getState().application.videoIsActive) {
                        callerWebRTCManager.deactivateLiveVideoAndRemoveStream();
                    }
                    callerWebRTCManager.endCall();
                    // display session transfer was succcesful, then end session
                    setTimeout(() => {
                        store.dispatch(hideSessionHandoverSuccess());
                    }, SESSION_TRANSFER_SUCCESSFUL_TIMEOUT);
                    break;

                case WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED:
                    grantJoinConversationPermissionDispatch();
                    // notify dispatcher if head mounted display detected
                    detectHmdOrWearableDevice();
                    break;

                case WebRtcMessageDispatcher.JOIN_REQUEST_IS_DECLINED:
                    denyJoinConversationPermissionDispatch();
                    break;

                case WebRtcMessageDispatcher.TOGGLE_EXTERNAL_STREAMING:
                    if (message.state === true) {
                        if (!reduxStore.getState().application.externalStreamIsActive) {
                            activateExternalStreamDispatch();
                        }
                    } else {
                        if (reduxStore.getState().application.externalStreamIsActive) {
                            deactivateExternalStreamDispatch();
                        }
                    }
                    break;

                default:
                    break;
            }
        }
    }
};

import React, { useEffect, useRef, useState } from 'react';
import { acceptDisclaimerForDispatcher, getDisclaimerForCaller, getDisclaimerForConference, getDisclaimerForDispatcher } from '../../api/disclaimerApi';
import ModalOverlay from '../Globals/ModalOverlay';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { createButtonAndToggleWakeLock, createKpiLog, replaceText } from '../../helper/helper';

import './DisclaimerPanel.scss';
import { invitedUserWebRTCManager } from '../../webrtc/webrtcManagers/InvitedUserWebRTCManager';
import {
    changeUsageDisclaimerStateCallerDispatch,
    changeUsageDisclaimerStateConferenceDispatch,
    changeUsageDisclaimerStateDispatcherDispatch,
} from '../../redux/actions/disclaimers';
import { useHandleDisclaimerState } from '../../hooks/useHandleDisclaimerState';
import { acceptDisclaimerForCaller, declineDisclaimerAndEndCall, disclaimerVisible } from '../../webrtc/outgoingMessages/outgoingMessagesCaller';
import { callerWebRTCManager } from '../../webrtc/webrtcManagers/CallerWebRTCManager';

interface DisclaimerPanelProps {
    user: 'caller' | 'dispatcher' | 'conference';
    texts: { [key: string]: string };
    disclaimerFeatureCaller: boolean;
    disclaimerFeatureDispatcher: boolean;
}

export type DisclaimerDispatcherType = {
    confirmationEnabled: boolean;
    confirmationText: string;
    disclaimerText: string;
    disclaimerIsActivated: boolean;
    bystanderDisclaimerIsActivated: boolean;
};

export type DisclaimerCallerType = {
    disclaimerText: string;
};

const DisclaimerPanel: React.FC<DisclaimerPanelProps> = ({ user, texts, disclaimerFeatureCaller, disclaimerFeatureDispatcher }) => {
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const [disclaimerText, setDisclaimerText] = useState('');
    const [confirmationText, setConfirmationText] = useState('');
    const [confirmationEnabled, setConfirmationEnabled] = useState(false);

    const [confirmationAccepted, setConfirmationAccepted] = useState(false);

    const acceptButtonRef = useRef(null);

    useHandleDisclaimerState(user);

    useEffect(() => {
        const getDisclaimerTexts = async () => {
            switch (user) {
                case 'caller': {
                    const results: DisclaimerCallerType = await getDisclaimerForCaller();
                    if (results) {
                        setShowDisclaimer(true);
                        setDisclaimerText(results.disclaimerText);
                        disclaimerVisible();
                        // state will be set once the user accepts or declines the disclaimer
                    } else {
                        // set state to none since their is no active disclaimer
                        changeUsageDisclaimerStateCallerDispatch('none');
                    }
                    break;
                }
                case 'dispatcher': {
                    const results: DisclaimerDispatcherType = await getDisclaimerForDispatcher();
                    if (results && !results.bystanderDisclaimerIsActivated) {
                        changeUsageDisclaimerStateCallerDispatch('none');
                    }
                    if (results && results.disclaimerIsActivated) {
                        setShowDisclaimer(true);
                        setDisclaimerText(results.disclaimerText);
                        setConfirmationText(results.confirmationText);
                        setConfirmationEnabled(results.confirmationEnabled);
                        // state will be set once the user accepts the disclaimer
                    } else {
                        // set state to none since their is no active disclaimer
                        changeUsageDisclaimerStateDispatcherDispatch('none');
                    }
                    break;
                }
                case 'conference': {
                    const results: DisclaimerDispatcherType = await getDisclaimerForConference();
                    if (results) {
                        setShowDisclaimer(true);
                        setDisclaimerText(results.disclaimerText);
                    } else {
                        changeUsageDisclaimerStateConferenceDispatch('none');
                    }
                    break;
                }
            }
        };

        if (disclaimerFeatureCaller || disclaimerFeatureDispatcher) {
            // set state to waiting since the feature is enabled
            changeUsageDisclaimerStateCallerDispatch('waiting');
            changeUsageDisclaimerStateDispatcherDispatch('waiting');
            changeUsageDisclaimerStateConferenceDispatch('waiting');

            getDisclaimerTexts();
        } else {
            // set state to none since the feature is disabled
            changeUsageDisclaimerStateCallerDispatch('none');
            changeUsageDisclaimerStateDispatcherDispatch('none');
            changeUsageDisclaimerStateConferenceDispatch('none');
        }
    }, [user, disclaimerFeatureCaller, disclaimerFeatureDispatcher]);

    const acceptDisclaimer = async () => {
        switch (user) {
            case 'caller':
                acceptDisclaimerForCaller();
                changeUsageDisclaimerStateCallerDispatch('accepted');
                break;
            case 'dispatcher':
                await acceptDisclaimerForDispatcher();
                changeUsageDisclaimerStateDispatcherDispatch('accepted');
                break;
            case 'conference':
                changeUsageDisclaimerStateConferenceDispatch('accepted');
                break;
        }

        setShowDisclaimer(false);
    };

    const declineDisclaimer = () => {
        switch (user) {
            case 'caller':
                if (window.confirm(replaceText(texts, 'disclaimer.decline.warning')) === true) {
                    changeUsageDisclaimerStateCallerDispatch('declined');
                    declineDisclaimerAndEndCall();
                    setShowDisclaimer(false);
                }
                break;
            case 'dispatcher':
                changeUsageDisclaimerStateDispatcherDispatch('declined');
                createKpiLog('stateDispatcherDisclaimer', 'declined');
                setShowDisclaimer(false);
                break;
            case 'conference':
                if (window.confirm(replaceText(texts, 'disclaimer.decline.warning')) === true) {
                    changeUsageDisclaimerStateConferenceDispatch('declined');
                    createKpiLog('stateConferenceDisclaimer', 'declined', { sessionId: invitedUserWebRTCManager.sessionId });
                    setShowDisclaimer(false);
                }
                break;
        }
    };

    const classes = clsx('disclaimer-panel', {
        'disclaimer-panel--caller': user === 'caller',
    });

    useEffect(() => {
        if (acceptButtonRef.current) {
            acceptButtonRef.current.focus();
            acceptButtonRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <ModalOverlay isPartialModal modalIsVisible={showDisclaimer}>
            <div className={classes}>
                <div
                    className="disclaimer-panel__text"
                    dangerouslySetInnerHTML={{
                        __html: disclaimerText,
                    }}
                />
                {confirmationEnabled && (
                    <label className="disclaimer-panel__confirmation">
                        <input
                            type="checkbox"
                            checked={confirmationAccepted}
                            onClick={() => {
                                setConfirmationAccepted(!confirmationAccepted);
                            }}
                        />
                        <span>{confirmationText}</span>
                    </label>
                )}
                {user !== 'dispatcher' && <div className="disclaimer-panel__hint">{replaceText(texts, 'disclaimer.hint')}</div>}
                <div className="disclaimer-panel__button-container">
                    {user !== 'dispatcher' && (
                        <button className="btn btn--secondary" onClick={declineDisclaimer}>
                            {replaceText(texts, 'disclaimer.decline')}
                        </button>
                    )}
                    <button
                        ref={acceptButtonRef}
                        className="btn btn--primary"
                        disabled={confirmationEnabled && !confirmationAccepted}
                        onClick={() => {
                            acceptDisclaimer();
                            user === 'caller' && createButtonAndToggleWakeLock(callerWebRTCManager, true);
                        }}>
                        {replaceText(texts, 'disclaimer.accept')}
                    </button>
                </div>
            </div>
        </ModalOverlay>
    );
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        disclaimerFeatureCaller: state.features.disclaimerFeatureCaller,
        disclaimerFeatureDispatcher: state.features.disclaimerFeatureDispatcher,
    };
};

export default connect(mapStateToProps)(DisclaimerPanel);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';
import './ResendSMS.scss';
import { replaceText } from '../../helper/helper';
import { RESENDSMS_BUTTON_TIMEOUT } from '../../config';
import Loading from '../Icons/LoadingSpinner';
import { hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';

export const ResendSMSComponent = ({ texts, isHidden, isAnchorTag, type }) => {
    const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
    let classes = `resend-sms ${isHidden && 'resend-sms__isHidden'}`;
    let typeClasses = `btn btn--primary ${type==='notification' ? 'btn--danger': ''}`;

    const resendSMS = e => {
        if (isAnchorTag) {
            e.preventDefault();
        }
        setResendButtonIsDisabled(true);
        setTimeout(() => {
            setResendButtonIsDisabled(false);
            hideAndRemoveNotificationsDispatch('error');
        }, RESENDSMS_BUTTON_TIMEOUT);
        dispatcherAuthManager.sendSMS(false, true);
    };

    return (
        <div className={classes}>
            {!resendButtonIsDisabled ? (
                !isAnchorTag ? (
                    <button id="resend-sms-button" className={typeClasses} onClick={resendSMS}>
                        <span>{replaceText(texts, 'resendsms.button')}</span>
                    </button>
                ) : (
                    <a id="resend-sms-anchor" href="/" onClick={resendSMS}>
                        {'>>' + replaceText(texts, 'resendsms.button') + '<<'}
                    </a>
                )
            ) : (
                <div className="resend-sms__text-wrapper">
                    {!isAnchorTag ? <span>{replaceText(texts, 'resendsms.sent')}</span> : ''}
                    <Loading />
                </div>
            )}
        </div>
    );
};

// PropTypes for this Component
ResendSMSComponent.propTypes = {
    texts: PropTypes.object,
    isHidden: PropTypes.bool,
    isAnchorTag: PropTypes.bool,
    type: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const ResendSMS = connect(mapStateToProps)(ResendSMSComponent);

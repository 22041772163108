import React, { useEffect, useRef } from 'react';
import { Bin } from '../Icons/Bin';
import { Tick } from '../Icons/Tick';
import Loading from '../Icons/LoadingSpinner';
import Send from '../Icons/Send';
import { ExclamationMark } from '../Icons/ExclamationMark';
import './SentInvitationList.scss';
import { replaceText } from '../../helper/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { usePrevious } from '../../helper/hooks';
import { DispatchMode, InvitationDetails } from '../../types/invitation.types';

interface SentInvitationListProps {
    sentInvitations: InvitationDetails[];
    handleEditEntry: (e: React.ChangeEvent<HTMLInputElement>, invitation: InvitationDetails, index: number) => void;
    handleDeleteEntry: (index: number) => void;
    validateAndDispatchInvitation: (updatedInvitation: InvitationDetails, dispatchMode: DispatchMode, index: number) => void;
}

export const SentInvitationList: React.FC<SentInvitationListProps> = ({
    sentInvitations,
    handleEditEntry,
    handleDeleteEntry,
    validateAndDispatchInvitation,
}) => {
    const texts = useSelector((state: RootState) => state.texts.texts);
    const listRef = useRef<HTMLUListElement>(null);
    const previousSentInvitations = usePrevious(sentInvitations) as InvitationDetails[];

    useEffect(() => {
        if (listRef.current && previousSentInvitations && previousSentInvitations.length < sentInvitations.length) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [previousSentInvitations, sentInvitations]);

    return (
        <>
            <ul className="invitation-sent-list" ref={listRef}>
                {sentInvitations &&
                    sentInvitations.length !== 0 &&
                    sentInvitations.map((invitation, index) => {
                        const allowSend = (!invitation.isSending && !invitation.hasError) || invitation.isInEditMode || invitation.wasPreviouslyDispatched;
                        return (
                            <li key={index} className={!invitation.hasError ? 'list-item' : 'list-item--has-error'}>
                                {!invitation.isSending && (invitation.isInEditMode || invitation.hasError) && (
                                    <div className="list-item--is-in-edit-mode" onClick={() => handleDeleteEntry(index)}>
                                        <Bin />
                                    </div>
                                )}
                                {invitation.hasError ? (
                                    <input type="text" value={invitation.recipient} onChange={e => handleEditEntry(e, invitation, index)}></input>
                                ) : (
                                    <label>
                                        {invitation.recipient}
                                        {!invitation.isSending ? <Tick /> : ''}
                                    </label>
                                )}
                                {invitation.isSending && (
                                    <div className="loading-container">
                                        <Loading />
                                    </div>
                                )}
                                {!invitation.isSending && (
                                    <button
                                        className="btn btn--primary"
                                        disabled={!allowSend}
                                        onClick={e => {
                                            e.preventDefault();
                                            validateAndDispatchInvitation(invitation, DispatchMode.EDIT, index);
                                        }}>
                                        <Send />
                                    </button>
                                )}
                                {invitation.hasError && !invitation.isInEditMode && (
                                    <div className="list-item--warning" title={replaceText(texts, 'invitation.recipient.error')}>
                                        <ExclamationMark />
                                    </div>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};

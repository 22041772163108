export const SESSION_DEVICE_CHANGE_HINTS = {
    NEW_DEVICE: 'NEW_DEVICE',
    DEVICE_CHANGED: 'DEVICE_CHANGED',
};

export enum SmartConnectDeviceType {
    HMD = 'HMD',
    SMARTPHONE = 'SMARTPHONE',
}

// TODO: cleanup type: any
export type SessionHandling = {
    sessionHandoverIsActive: boolean;
    sessionHandoverSuccessShown: boolean;
    sessionHijackingIsActive: boolean;
    isHeadMountedDisplayDevice: boolean;
    activeDeviceId: Number | null;
    devices: any;
};

export type UserData = {
    username: string;
    userConfId: string;
    webRtcCompliant: string;
    dtlsCompliant: string;
    osName: string;
    osVersion: string;
    browser: string;
    deviceType: string;
    deviceVendor: string;
    deviceModel: string;
    browser_version: string;
    browser_major_version: string;
    apiRTCVersion: string;
    cpuArchitecture: string;
    engineName: string;
    engineVersion: string;
    remoteAccessUserSupport: string;
    isSimulated: string;
    id: string;
    audioDevicePresent: string;
    videoDevicePresent: string;
}

export type Contact = {
    groups: string[];
    previousMessages: any[];
    userData: UserData;
    enterprise: null;
    streams: any;
    profile: null;
    fileTransfers: any[];
    promises: any[];
};

export type Device = {
    contact: Contact;
    chatHistory: any[];
    geolocation: any;
}

export type SmartConnectKnownDevice = {
    id: string;
    type: SmartConnectDeviceType;
}
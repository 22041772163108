import React, { useEffect, useRef, useState } from 'react';

import Download from '../Icons/Download';
import { DISPLAY_ONLY_IN_SESSION, IMAGE_NAME, IMAGE_TYPE_ENDINGS, MAX_CHAR_GALLERY_FILE_NAME } from '../../config';
import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { getFileTypeCategory, parseDate, replaceText } from '../../helper/helper';
import { useSelector } from 'react-redux';
import NoMedia from '../Icons/NoMedia';
import './MediaGallery.scss';
import { renderToString } from 'react-dom/server';
import Audio from '../Icons/Audio';
import Loading from '../Icons/LoadingSpinner';
import { Image } from '../Icons/Image';
import { Document } from '../Icons/Document';
import { Presentation } from '../Icons/Presentation';
import { Table } from '../Icons/Table';
import { Video } from '../Icons/Video';
import { SoundFile } from '../Icons/SoundFile';
import BackgroundIcon from '../Icons/BackgroundIcon';
import PlayIcon from '../Icons/PlayIcon';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { RootState } from '../../redux/store';
import { FILE_TYPE } from '../../types';
import { usePrevious } from '../../helper/hooks';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';

interface RecordingInfo {
    recording: {
        mediaId: string;
        mediaURL: string;
        recordedFileName: string;
    };
    thumbnail: string;
    created: string;
    status: string;
}

export const MediaGallery = () => {
    const images = useSelector((state: RootState) => state.session.images);
    const preventPhotoAutoDownload = useSelector((state: RootState) => state.application.preventPhotoAutoDownload);
    const recordings = useSelector((state: RootState) => state.session.recordings);
    const dispatcherFiles = useSelector((state: RootState) => state.files.dispatcherFiles);
    const callerFiles = useSelector((state: RootState) => state.files.callerFiles);
    const texts = useSelector((state: RootState) => state.texts.texts);

    const [combinedMedia, setCombinedMedia] = useState([]);
    const [recordingThumbnailUrl, setRecordingThumbnailUrl] = useState('');

    const galleryRef = useRef();

    const prevImages = usePrevious(images);

    const handleRecordingDownload = (recordingInfo: RecordingInfo) => {
        fetch(recordingInfo.recording.mediaURL)
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                let filename = recordingInfo.recording.recordedFileName;
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    };

    const downloadFile = async (e, file) => {
        e.preventDefault();

        try {
            const fileUrl = file.url;
            const response = await fetch(fileUrl);
            if (!response.ok) {
                addNotificationAndShowDispatch('File not ready', 'error', DISPLAY_ONLY_IN_SESSION);
            }
            const content = await response.blob();
            const blob = new Blob([content], { type: file.type });
            const blobURL = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = file.name;
            a.click();
            window.URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const transformedImages = images?.map(image => ({
            type: 'image',
            time: image.time,
            image,
        }));

        const transformedRecordings = recordings?.map(recording => ({
            type: 'recording',
            time: recording.created,
            recording,
        }));

        const transformedDispatcherFiles = dispatcherFiles?.map(file => ({
            type: 'file',
            time: file.time,
            file,
        }));

        const transformedCallerFiles = callerFiles?.map(file => ({
            type: 'file',
            time: file.time,
            file,
        }));

        const combinedArray = [...transformedImages, ...transformedRecordings, ...transformedDispatcherFiles, ...transformedCallerFiles];

        const sortedCombinedArray = combinedArray.sort((a, b) => {
            const dateA = a.time;
            const dateB = b.time;
            return dateA - dateB;
        });

        setCombinedMedia(sortedCombinedArray);
    }, [images, recordings, dispatcherFiles, callerFiles]);

    useEffect(() => {
        if (!preventPhotoAutoDownload && images.length !== 0 && images !== prevImages) {
            downloadImage(images[images.length - 1]);
        }
    }, [images, preventPhotoAutoDownload, prevImages]);

    useEffect(() => {
        const svgString = renderToString(<Audio />);
        const encodedSvgString = encodeURIComponent(svgString);
        const svgUrl = `data:image/svg+xml;charset=utf-8,${encodedSvgString}`;
        setRecordingThumbnailUrl(svgUrl);
    }, []);

    const renderFileTypeIcon = file => {
        switch (true) {
            case getFileTypeCategory(file) === FILE_TYPE.IMAGE:
                return <Image />;
            case getFileTypeCategory(file) === FILE_TYPE.DOCUMENT:
                return <Document />;
            case getFileTypeCategory(file) === FILE_TYPE.PRESENTATION:
                return <Presentation />;
            case getFileTypeCategory(file) === FILE_TYPE.TABLE:
                return <Table />;
            case getFileTypeCategory(file) === FILE_TYPE.VIDEO:
                return <Video />;
            case getFileTypeCategory(file) === FILE_TYPE.AUDIO:
                return <SoundFile />;
            default:
                break;
        }
    };

    const downloadImage = image => {
        var element = document.createElement('a');
        element.setAttribute('href', `data:application/octet-stream;base64${image.image}`);
        element.setAttribute(
            'download',
            IMAGE_NAME + '_' + dispatcherWebRTCManager.webRtcSessionId + '_' + dispatcherAuthManager.bystanderToken + '_' + image.time + '.' + IMAGE_TYPE_ENDINGS[image.type]
        );

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <div className="media" ref={galleryRef}>
            <div className={`mediaPlaceholder ${combinedMedia.length > 0 ? 'mediaPlaceholder--hidden' : ''}`}>
                <NoMedia />
            </div>
            <div className="media__gallery">
                {combinedMedia.map((media, key) => {
                    return (
                        <React.Fragment key={key}>
                            {media.type === 'image' && (
                                <div className="image-info info-container" onClick={() => downloadImage(media.image)}>
                                    <div className="media-container">
                                        <div className="file-type-icon">
                                            <BackgroundIcon />
                                        </div>
                                        <img src={media.image.image} alt={`image_${media.image.time}`} />
                                    </div>
                                    <Download />
                                    <span>{parseDate(media.image.time)}</span>
                                    {/* differentiate between hd photo and standard */}
                                    {media.image.image.indexOf('application/octet-stream') !== -1 ? (
                                        <span>{replaceText(texts, 'media.photo.hd')}</span>
                                    ) : (
                                        <span>{replaceText(texts, 'media.photo.standard')}</span>
                                    )}
                                </div>
                            )}
                            {media.type === 'recording' && (
                                <div
                                    className="recording-info info-container"
                                    onClick={() => {
                                        media.recording.status === 'available' && handleRecordingDownload(media.recording);
                                    }}>
                                    <div className="media-container">
                                        {media.recording.status === 'available' && (
                                            <div className="file-type-icon">
                                                <PlayIcon />
                                            </div>
                                        )}
                                        <button key={media.recording.mediaId}>
                                            {media.recording.thumbnail === 'data:,' && (
                                                <div className="thumbnail-audio">
                                                    <img src={recordingThumbnailUrl} alt="" />
                                                </div>
                                            )}
                                            {media.recording.thumbnail && media.recording.thumbnail !== 'data:,' && (
                                                <div className="thumbnail-video">
                                                    <img src={media.recording.thumbnail} alt="" />
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                    {media.recording.status !== 'available' && <Loading />}
                                    {media.recording.status === 'started' && (
                                        <div>
                                            <span>{replaceText(texts, 'stream.recording.started')}</span>
                                        </div>
                                    )}
                                    {media.recording.status === 'stopped' && (
                                        <div>
                                            <span>{replaceText(texts, 'stream.recording.stopped')}</span>
                                        </div>
                                    )}

                                    {media.recording.status === 'available' && <Download />}
                                    <span>{media.recording.status === 'available' && parseDate(media.time)}</span>

                                    {media.recording.thumbnail === 'data:,' && <span>{replaceText(texts, 'media.recording.audio')}</span>}
                                    {media.recording.thumbnail && media.recording.thumbnail !== 'data:,' && (
                                        <span>{replaceText(texts, 'media.recording.video')}</span>
                                    )}
                                </div>
                            )}
                            {media.type === 'file' && (
                                <div className="file-info" onClick={e => downloadFile(e, media.file)}>
                                    <div className=" media-container">
                                        <div className="file-icon">{renderFileTypeIcon(media.file)}</div>
                                    </div>
                                    <Download />
                                    <span>{parseDate(media.time)}</span>
                                    <span title={media.file.name}>
                                        {media.file.name < MAX_CHAR_GALLERY_FILE_NAME
                                            ? media.file.name
                                            : `${media.file.name.substring(0, MAX_CHAR_GALLERY_FILE_NAME)}...${media.file.extension}`}
                                    </span>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

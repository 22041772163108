import React from 'react';
import PropTypes from 'prop-types';
import parse from 'platform';
import { connect } from 'react-redux';

import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';

import Help from '../Icons/Help';

import { HELPDESK_LINK, HELPDESK_OS, HELPDESK_OS_VERSIONS, HIGHLIGHT_SYSTEM_PANEL_TIMEOUT } from '../../config';

import './InformationPanel.scss';

import { setCallerLanguageDispatch } from '../../redux/actions/texts';
import { replaceText, getHelpdeskLanguage, createKpiLog } from '../../helper/helper';
import { dispatchAddCallerConnectionQuality } from '../../redux/actions/session';
import { WebRtcMessageCaller } from '../../types';

/**
 * SystemPanel
 * Contains all System Information from the bystander.
 * Infos get transmitted via the established webRTC Connection.
 * Infos: operating system and version, browser name and version, battery left, language, connection quality
 */

class SystemPanel extends React.Component {
    _isMounted = false;
    _kpiSent = {
        battery: false,
        os: false,
        network: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            operationSystem: 'info.notAvailable',
            operationgSystemVersion: 'info.notAvailable',
            operatingSystemRaw: 'info.notAvailable',
            browserName: 'info.notAvailable',
            browserVersion: 'info.notAvailable',
            download: 'info.notAvailable',
            upload: 'info.notAvailable',
            battery: 'info.notAvailable',
            language: 'notAvailable',
            systemPanelIsHighlighted: false,
        };

       if (dispatcherWebRTCManager.connectedSession) {
           dispatcherWebRTCManager.connectedSession.removeListener('contactMessage', this.handleContactMessage);
           dispatcherWebRTCManager.connectedSession.on('contactMessage', this.handleContactMessage);
       }
    }

    handleContactMessage = e => {
        //Display message in UI
        const message = JSON.parse(e.content);
        const sender = e.sender;

        if (this.props.activeDeviceId === null || sender.userData.id === this.props.activeDeviceId) {
            if (message && message.data === WebRtcMessageCaller.SYSTEM) {
                const info = parse.parse(message.browserVersion1b);
                if (this._isMounted) {
                    this.setState({
                        operatingSystemRaw: message.browserVersion1a,
                        operationSystem: info.os.toString(),
                        browserName: info.name,
                        browserVersion: info.version,
                    });

                    if (!this._kpiSent.os) {
                        this._kpiSent.os = true;

                        const additionalStates = {
                            0: info.os.toString(),
                            1: info.name,
                            2: info.version,
                        };

                        createKpiLog('infoSystem', '', additionalStates);
                    }
                }
            }
            if (message && message.data === WebRtcMessageCaller.NETWORK_INFO) {
                if (this._isMounted) {
                    this.setState({
                        upload: message.upload.rating,
                        download: message.download.rating,
                    });

                    dispatchAddCallerConnectionQuality({ rating: message.upload.rating });

                    if (!this._kpiSent.network) {
                        this._kpiSent.network = true;

                        const additionalStates = {
                            0: message.upload.rating,
                            1: message.download.rating,
                        };

                        createKpiLog('infoNetwork', '', additionalStates);
                    }
                }
            }
            if (message && message.data === WebRtcMessageCaller.BATTERY) {
                if (this._isMounted) {
                    this.setState({
                        battery: Math.round(message.level * 100) + '%',
                    });

                    if (!this._kpiSent.battery) {
                        this._kpiSent.battery = true;

                        const additionalStates = {
                            0: Math.round(message.level * 100) + '%',
                        };

                        createKpiLog('infoBattery', '', additionalStates);
                    }
                }
            }
            if (message && message.data === WebRtcMessageCaller.UPDATE_CALLER_LANGUAGE) {
                if (this._isMounted) {
                    setCallerLanguageDispatch(message.language);
                    this.setState({
                        language: message.language,
                    });
                }
            }

            dispatcherWebRTCManager.addCloseCallCallback(() => {
                if (this._isMounted) {
                    this.setInitialState();
                }
            });
        }
    };

    calculateOSLink() {
        const system = this.state.operationSystem;
        const osName = Object.keys(HELPDESK_OS).filter(osType => {
            return HELPDESK_OS[osType].some(os => system.toLowerCase().indexOf(os) !== -1);
        });

        if (osName.length === 0) {
            return HELPDESK_LINK;
        }

        const osVersion = HELPDESK_OS_VERSIONS[osName].filter(
            version =>
                // android
                system.toLowerCase().indexOf(version.replace('-', '.')) !== -1 ||
                // ios
                system.toLowerCase().indexOf(version.replace('-', '_')) !== -1
        );

        if (osName.length > 0) {
            if (osVersion.length > 0) {
                return `${HELPDESK_LINK}/${osName[0]}/${osName[0]}-${osVersion[0]}`;
            }
            return `${HELPDESK_LINK}/${osName[0]}`;
        }
        return HELPDESK_LINK;
    }

    setInitialState() {
        this.setState({
            operationSystem: 'info.notAvailable',
            operationgSystemVersion: 'info.notAvailable',
            operatingSystemRaw: 'info.notAvailable',
            browserName: 'info.notAvailable',
            browserVersion: 'info.notAvailable',
            download: 'info.notAvailable',
            upload: 'info.notAvailable',
            battery: 'info.notAvailable',
            language: 'notAvailable',
        });
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeDeviceId !== null && prevProps.activeDeviceId !== null && prevProps.activeDeviceId !== this.props.activeDeviceId) {
            this.setState({
                systemPanelIsHighlighted: true,
            })
            window.setTimeout(() => {
                this.setState({
                    systemPanelIsHighlighted: false,
                });
            }, HIGHLIGHT_SYSTEM_PANEL_TIMEOUT);
        }
    }

    render() {
        const classes = `informationPanel ${this.state.systemPanelIsHighlighted ? 'informationPanel--is-highlighted' : ''}`;
        return (
            <ul className={classes}>
                <li className="informationPanel__item">
                    <div className="itemWrapper">
                        <span>{replaceText(this.props.texts, 'info.os')}</span>
                        <a href={this.calculateOSLink() + getHelpdeskLanguage(this.props.language)} target="_blank" rel="noopener noreferrer">
                            <Help />
                        </a>
                    </div>

                    {replaceText(this.props.texts, this.state.operationSystem)}
                </li>
                <li className="informationPanel__item">
                    <div className="itemWrapper">
                        <span>{replaceText(this.props.texts, 'info.browser')}</span>
                    </div>
                    {replaceText(this.props.texts, this.state.browserName)} / {replaceText(this.props.texts, this.state.browserVersion)}
                </li>
                <li className="informationPanel__item">
                    <strong>
                        <span>{replaceText(this.props.texts, 'info.language')}</span>
                    </strong>
                    {replaceText(this.props.texts, `lang.${this.state.language}`)}
                </li>
                <li className="informationPanel__item">
                    <strong>
                        <span>{replaceText(this.props.texts, 'info.battery')}</span>
                    </strong>
                    {replaceText(this.props.texts, this.state.battery)}
                </li>
                <li className="informationPanel__item">
                    <strong>
                        <span>{replaceText(this.props.texts, 'info.upload')}</span>
                    </strong>
                    {replaceText(this.props.texts, this.state.upload)}
                </li>
            </ul>
        );
    }
}

SystemPanel.propTypes = {
    texts: PropTypes.any,
    language: PropTypes.string,
    activeDeviceId: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        language: state.texts.language,
        activeDeviceId: state.sessionHandlingSlice.activeDeviceId,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(SystemPanel);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';

import ToggleSwitch from '../Globals/ToggleSwitch';
import ConnectionOverlay from '../Globals/ConnectionOverlay';

import './WebRTCSwitcher.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import {
    enableSnapshotDispatch,
    enableVideoDispatch,
    disableSnapshotDispatch,
    activateChatDispatcherDispatch,
    activateVideoDispatcherDispatch,
    deactivateChatDispatcherDispatch,
    deactivateGPSDispatch,
    deactivateSnapshotDispatch,
    deactivateVideoDispatcherDispatch,
    activateSnapshotDispatch,
    activateGPSDispatch,
    disablePointerDispatch,
    deactivatePointerDispatcherDispatch,
    activatePointerDispatcherDispatch,
    enablePointerDispatch,
    deactivateHDSendDispatch,
    activateHDSendDispatch,
    enableDrawDispatch,
    disableDrawDispatch,
    deactivateDrawDispatcherDispatch,
    activateDrawDispatcherDispatch,
    disableChatDispatch,
    enableChatDispatch,
    removeVideoStreamDispatch,
    dispatchCallerFileTransferEnded,
    dispatchCallerFileIsNotBusy,
    deactivateAudioStreamDispatcherDispatch,
    activateAudioStreamDispatcherDispatch,
    deactivateBidiDispatch,
    activateBidiDispatch,
    deactivateStreamRecordingDispatch,
    activateStreamRecordingDispatch,
    activateBidiBackgroundDispatch,
    deactivateScreenshareDispatch,
    activateScreenshareDispatch,
    unmuteCallerMicrophoneDispatcherDispatch,
    dispatchUnsetLiveVideoIsLoading,
    deactivateSmartConnectDispatch,
    activateSmartConnectDispatch,
} from '../../redux/actions/application';

import { C_LOST, DISPLAY_ONLY_IN_SESSION, STREAM_RECORDING_LIMIT } from '../../config';
import { createKpiLog, isWebGLAvailable, replaceText } from '../../helper/helper';
import { dispatchDisallowPaintingDispatcher, dispatchSetPaintDeactivationIsPendingScreenshotDialogue, dispatchShowScreenshotDialogue } from '../../redux/actions/paint';
import { removeStreamContainer } from '../../helper/streamHandling';
import { resetAudioStreamPermissionDispatch } from '../../redux/actions/permissions';
import { dispatchResetFocusWindow, dispatchSetFocusWindowBidi, dispatchSetFocusWindowChat, dispatchSetFocusWindowLiveVideo } from '../../redux/actions/focus';
import { WebRtcManagerType, WebRtcMessageCaller } from '../../types';
import { sendAudioActivationStatus, sendBidiIsDeactivated, sendCurrentFocusFeatureStatus, sendScreenshareToggled, sendSetFeatureFocus, sendToggleStreamRecording } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';

/**
 * WebRTCSwitcher
 * ToggleSwitchers for all functions available.
 * Handles any dependencies on one another.
 *
 * @component ConnectionOverlay - shows waiting for connection until webRTC connection is established
 * @component ToggleSwitch - Simple toggle switch (on/off)
 */

class WebRTCSwitcher extends PureComponent {
    _audioPermissionCheckInterval = 0;
    _streamRecordingPermissionCheckInterval = 0;

    constructor(props) {
        super(props);
        this.state = {
            deactivateGPSDispatchHasRun: false,
            deactivateVideoDispatchHasRun: false,
            recordingLimitIsReached: false,
            streamRecordTimer: 0,
            webGLSupported: false,
            bidiHasBeenActivated: false,
        };

        if (dispatcherWebRTCManager.connectedSession) {
            dispatcherWebRTCManager.connectedSession.removeListener('contactMessage', this.handleContactMessage);
            dispatcherWebRTCManager.connectedSession.on('contactMessage', this.handleContactMessage);
        }
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);
        const sender = e.sender;

        if (this.props.activeDeviceId === null || sender.userData.id === this.props.activeDeviceId) {
            if (message && (message.data === WebRtcMessageCaller.COORDS_ERROR || message.data === WebRtcMessageCaller.COORDS_NOT_AVAILABLE || message.data === WebRtcMessageCaller.COORDS_PERMISSION_DENIED)) {
                if (!this.state.deactivateGPSDispatchHasRun) {
                    deactivateGPSDispatch();
                    this.setState({
                        deactivateGPSDispatchHasRun: true,
                    });
                }
            }
            if (message && message.data === WebRtcMessageCaller.COORDS_ERROR) {
                addNotificationAndShowDispatch('error.gps_err', 'error', DISPLAY_ONLY_IN_SESSION);
            }
            if (message && message.data === WebRtcMessageCaller.COORDS_NOT_AVAILABLE) {
                addNotificationAndShowDispatch('error.gps_nt_avlbl', 'error', DISPLAY_ONLY_IN_SESSION);
            }
            if (message && message.data === WebRtcMessageCaller.COORDS_PERMISSION_DENIED) {
                addNotificationAndShowDispatch('error.gps_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
            }
            if (message && message.data === WebRtcMessageCaller.DEVICE_INFO && message.devices.length === 0) {
                addNotificationAndShowDispatch('error.vd_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
                if (this.props.videoIsActive) deactivateVideoDispatcherDispatch();
                if (this.props.pointerIsActive) disablePointerDispatch();
                if (this.props.snapshotIsActive) disableSnapshotDispatch();
                if (this.props.drawIsActive) disableDrawDispatch();
                if (this.props.videoIsLoading) dispatchUnsetLiveVideoIsLoading();
            }

            if (message && message.data === WebRtcMessageCaller.VIDEO_NOT_AVAILABLE) {
                addNotificationAndShowDispatch('error.vd_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
                if (!this.state.deactivateVideoDispatchHasRun) {
                    if (this.props.videoIsActive) deactivateVideoDispatcherDispatch();
                    this.setState({
                        deactivateVideoDispatchHasRun: true,
                    });
                }
                removeStreamContainer();
                removeVideoStreamDispatch();

                if (this.props.videoIsLoading) dispatchUnsetLiveVideoIsLoading();
            }

            if (message && message.data === WebRtcMessageCaller.DEVICE_INFO && message.devices.length > 0) {
                enableVideoDispatch();
            }

            if (message && message.data === WebRtcMessageCaller.PHOTO_PERMISSION && message.permission === false) {
                deactivateSnapshotDispatch(true);
            }

            if (message && message.data === WebRtcMessageCaller.STREAM_RECORDING_PERMISSION && message.permission === false) {
                deactivateStreamRecordingDispatch();
                clearInterval(this._streamRecordingPermissionCheckInterval);
            }
        }
    };

    deactivateAllSwitches = () => {
        if (this.props.videoIsActive) deactivateVideoDispatcherDispatch();
        if (this.props.chatIsActive) deactivateChatDispatcherDispatch();
        if (this.props.gpsIsActive) deactivateGPSDispatch();
        if (this.props.snapshotIsActive) deactivateSnapshotDispatch(true);
        if (this.props.pointerIsActive) deactivatePointerDispatcherDispatch();
    };

    toggleChat = () => {
        const currentActiveState = this.props.chatIsActive;
        if (currentActiveState) {
            deactivateChatDispatcherDispatch();
        } else {
            activateChatDispatcherDispatch();
            if (!this.props.drawIsActive) {
                dispatchSetFocusWindowChat();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
            }
        }
    };
    toggleVideo = () => {
        const currentActiveState = this.props.videoIsActive;
        if (currentActiveState) {
            deactivateVideoDispatcherDispatch();
        } else {
            activateVideoDispatcherDispatch();
            dispatchSetFocusWindowLiveVideo();
            sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
            createKpiLog('stateLiveVideoFeature', 'activated');
            this.setState({
                deactivateVideoDispatchHasRun: false,
            });
        }

        if (currentActiveState) {
            deactivateSnapshotDispatch(true);
            disableSnapshotDispatch();
            deactivatePointerDispatcherDispatch();
            disablePointerDispatch();
            disableDrawDispatch();
            deactivateDrawDispatcherDispatch();
            dispatchDisallowPaintingDispatcher();
            enableChatDispatch();
        } else {
            enableSnapshotDispatch();
            enablePointerDispatch();
            enableDrawDispatch();
        }
    };
    toggleSnapshot = () => {
        const currentActiveState = this.props.snapshotIsActive;
        if (currentActiveState) {
            deactivateSnapshotDispatch(true);
            deactivateHDSendDispatch();
        } else {
            activateSnapshotDispatch();
        }
    };
    toggleGPS = () => {
        if (!this.state.webGLSupported) {
            if (isWebGLAvailable()) {
                this.setState({
                    webGLSupported: true,
                });
            } else {
                addNotificationAndShowDispatch('error.webgl', 'error', DISPLAY_ONLY_IN_SESSION);
                return;
            }
        }

        const currentActiveState = this.props.gpsIsActive;
        if (currentActiveState) {
            deactivateGPSDispatch();
        } else {
            activateGPSDispatch();
            this.setState({
                deactivateGPSDispatchHasRun: false,
            });
        }
    };

    togglePointer = () => {
        const currentActiveState = this.props.pointerIsActive;
        if (currentActiveState) {
            deactivatePointerDispatcherDispatch();
        } else {
            if (this.props.currentFocusFeature !== FOCUS_FEATURE_TYPE.LIVE_VIDEO) {
                dispatchSetFocusWindowLiveVideo();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
            }
            activatePointerDispatcherDispatch();
        }
    };

    toggleDraw = () => {
        const currentActiveState = this.props.drawIsActive;
        if (!currentActiveState) {
            if (this.props.currentFocusFeature !== FOCUS_FEATURE_TYPE.LIVE_VIDEO) {
                dispatchSetFocusWindowLiveVideo();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
            }
            activateDrawDispatcherDispatch();
        }

        if (currentActiveState) {
            if (this.props.photoPermission && this.props.snapshotFeature && this.props.isPaintingAllowed) {
                dispatchSetPaintDeactivationIsPendingScreenshotDialogue();
                dispatchShowScreenshotDialogue();
            } else {
                deactivateDrawDispatcherDispatch();
            }

            enableChatDispatch();
            if (this.props.wasChatActiveBefore) {
                activateChatDispatcherDispatch();
            }
            enablePointerDispatch();
            enableSnapshotDispatch();
        } else {
            disableChatDispatch();
            deactivateChatDispatcherDispatch();
            disablePointerDispatch();
            disableSnapshotDispatch();
            deactivatePointerDispatcherDispatch();
            deactivateSnapshotDispatch();
            dispatchDisallowPaintingDispatcher();
            dispatchCallerFileTransferEnded();
            dispatchCallerFileIsNotBusy();
        }
    };

    toggleHDSend = () => {
        const currentActiveState = this.props.hdSendIsActive;
        currentActiveState ? deactivateHDSendDispatch() : activateHDSendDispatch();
    };

    toggleAudioStream = () => {
        const currentActiveState = this.props.audioStreamIsActive;
        if (currentActiveState) {
            deactivateAudioStreamDispatcherDispatch();
        } else {
            if (this.props.audioStreamPermission === null || this.props.audioStreamPermission === false) {
                // Permission needs to be reset to check if dispatcher has reset browser permissions
                resetAudioStreamPermissionDispatch();
                this._audioPermissionCheckInterval = setInterval(this.checkIfAudioPermissionIsGranted, 500);
            }
            // activate the audio streams
            activateAudioStreamDispatcherDispatch();
            // if a new audio stream is activated, the microphone will be unmuted, so we need to reflect this state
            unmuteCallerMicrophoneDispatcherDispatch();
        }

        // we need to send the current status to the conference user as well
        sendAudioActivationStatus(!currentActiveState);
    };

    checkIfAudioPermissionIsGranted = () => {
        if (this.props.audioStreamPermission === false) {
            if (this.props.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
            clearInterval(this._audioPermissionCheckInterval);
        }
        if (this.props.audioStreamPermission === true) {
            clearInterval(this._audioPermissionCheckInterval);
        }
    };

    toggleRecordingFeature = () => {
        const currentActiveState = this.props.streamRecordingIsActive;
        if (!currentActiveState) {
            activateStreamRecordingDispatch();
            if (!this.props.streamRecordingPermission) sendToggleStreamRecording(!currentActiveState);
            this._streamRecordingPermissionCheckInterval = setInterval(this.startRecordingIfPermissionGranted, 1000);
        } else {
            if (this.props.recordings.length >= STREAM_RECORDING_LIMIT) {
                this.setState({
                    recordingLimitIsReached: true,
                });
            }
            deactivateStreamRecordingDispatch();
            clearInterval(this._streamRecordingPermissionCheckInterval);
            this.stopRecordTimer();
        }
    };

    startRecordingIfPermissionGranted = () => {
        if (this.props.streamRecordingPermission) {
            this.startStreamRecording();
            clearInterval(this._streamRecordingPermissionCheckInterval);
        }
    };

    startStreamRecording = async () => {
        await dispatcherWebRTCManager.startConversationRecording();
        this.startRecordTimer();
    };

    stopStreamRecording = async () => {
        await dispatcherWebRTCManager.stopConversationRecording();
    };

    toggleBidi = async () => {
        const currentActiveState = this.props.bidiIsActive;
        this.setState({
            bidiLoading: true,
        });
        if (currentActiveState) {
            deactivateBidiDispatch();
            sendBidiIsDeactivated();
        } else {
            activateBidiDispatch();
            // initially activate background image if available
            if (!this.state.bidiHasBeenActivated && this.props.videoBackgroundImage.length !== 0) {
                activateBidiBackgroundDispatch();
            } else if (!this.state.bidiHasBeenActivated && this.props.videoBackgroundImage.length === 0) {
                activateBidiDispatch();
            }
            if (!this.props.drawIsActive) {
                dispatchSetFocusWindowBidi();
                sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
            }
            this.setState({
                bidiHasBeenActivated: true,
            });
        }
    };

    toggleScreenshare = async () => {
        const currentActiveState = this.props.screenshareIsActive;
        if (currentActiveState) {
            deactivateScreenshareDispatch('dispatcher');
            sendScreenshareToggled(false);
        } else {
            activateScreenshareDispatch('dispatcher');
            sendScreenshareToggled(true);
        }
    };

    startRecordTimer = () => {
        this._streamRecordTimer = setInterval(this.timerChange, 1000);
    };

    timerChange = () => {
        this.setState({ streamRecordTimer: this.state.streamRecordTimer + 1 });
        if (this.state.streamRecordTimer >= 1800 || (!this.props.streamRecordingIsActive && !this.props.streamRecordingHasStarted)) {
            if (this.props.streamRecordingIsActive) this.toggleRecordingFeature();
            if (this.props.streamRecordingHasStarted) this.stopStreamRecording();
            this.stopRecordTimer();
        }
    };

    stopRecordTimer = () => {
        clearInterval(this._streamRecordTimer);
        this.setState({
            streamRecordTimer: 0,
        });
    };

    toggleSmartConnect = async () => {
        const currentActiveState = this.props.smartConnectIsActive;
        if (currentActiveState) {
            deactivateSmartConnectDispatch();
            if (this.props.currentFocusFeature === FOCUS_FEATURE_TYPE.EXTERNAL_STREAM) {
                dispatchResetFocusWindow(WebRtcManagerType.DISPATCHER);
                sendCurrentFocusFeatureStatus(FOCUS_FEATURE_TYPE.RESET_FOCUS);
            }
        } else {
            activateSmartConnectDispatch();
        }
    };

    displayTextIfDisabled = () => {
        if (this.props.isHeadMountedDisplayDevice) {
            return replaceText(this.props.texts, 'toggle.disabled.by.hmd');
        }

        if (this.props.drawIsActive) {
            return replaceText(this.props.texts, 'toggle.disabled.by.draw');
        }

        return '';
    }

    componentWillUnmount() {
        this.deactivateAllSwitches();
        clearTimeout(this._audioToggleTimeout);
        clearTimeout(this._bidiToggleTimeout);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.videoIsActive !== this.props.videoIsActive && this.props.videoIsActive) {
            enableSnapshotDispatch();
            enablePointerDispatch();
            enableDrawDispatch();
        } else if (prevProps.videoIsActive !== this.props.videoIsActive && this.props.videoIsActive === false) {
            disableSnapshotDispatch();
            disablePointerDispatch();
            disableDrawDispatch();
        }

        if (!this.props.videoIsActive && !this.props.audioStreamIsActive && this.props.streamRecordingIsActive && !this.props.streamRecordingHasStarted) {
            this.toggleRecordingFeature();
        } else if (!this.props.videoIsActive && !this.props.audioStreamIsActive && this.props.streamRecordingIsActive && this.props.streamRecordingHasStarted) {
            this.toggleRecordingFeature();
            this.stopStreamRecording();
        }

        // deactivate pointer if live video not in focus
        if (this.props.videoIsActive && this.props.pointerIsActive && this.props.currentFocusFeature !== FOCUS_FEATURE_TYPE.LIVE_VIDEO) {
            this.togglePointer();
        }

        if (this.props.activeDeviceId !== null && this.props.activeDeviceId !== prevProps.activeDeviceId) {
            if (this.props.gpsIsActive) {
                deactivateGPSDispatch();
            }
        }
    }

    render() {
        const disabledNoConnection = !this.props.isConnected || this.props.connectionStatus === C_LOST;
        const recordingLimitIsReached = !this.props.streamRecordingIsActive && this.props.recordings.length >= STREAM_RECORDING_LIMIT;
        const noStreamToRecord = !this.props.videoIsActive && !this.props.audioStreamIsActive;

        const minutes = Math.floor(this.state.streamRecordTimer / 60);
        const seconds = this.state.streamRecordTimer % 60;

        return (
            <div className="webRTCSwitcher">
                <ConnectionOverlay force={true} />
                <ToggleSwitch
                    id="location-toggle"
                    changeHandler={this.toggleGPS}
                    label={replaceText(this.props.texts, 'toggle.gps')}
                    isDisabled={this.props.gpsIsDisabled || disabledNoConnection || !this.props.callerPageLoaded || this.props.isHeadMountedDisplayDevice}
                    isActive={this.props.gpsIsActive}
                    title={this.props.isHeadMountedDisplayDevice ? replaceText(this.props.texts, 'toggle.disabled.by.hmd') : ''}
                />
                <ToggleSwitch
                    id="video-toggle"
                    changeHandler={this.toggleVideo}
                    label={replaceText(this.props.texts, 'toggle.video')}
                    isDisabled={this.props.videoIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    isActive={this.props.videoIsActive}
                />
                {(this.props.snapshotFeature || this.props.hdSendFeature) && (
                    <ToggleSwitch
                        id="snapshot-toggle"
                        changeHandler={this.toggleSnapshot}
                        label={replaceText(this.props.texts, 'toggle.snapshot')}
                        isDisabled={this.props.snapshotIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.snapshotIsActive}
                        sub={true}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.pointerFeature && (
                    <ToggleSwitch
                        id="pointer-toggle"
                        changeHandler={this.togglePointer}
                        label={replaceText(this.props.texts, 'toggle.pointer')}
                        isDisabled={this.props.pointerIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.pointerIsActive}
                        sub={true}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.drawFeature && (
                    <ToggleSwitch
                        id="draw-toggle"
                        changeHandler={this.toggleDraw}
                        label={replaceText(this.props.texts, 'toggle.draw')}
                        isDisabled={this.props.drawIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.drawIsActive}
                        sub={true}
                    />
                )}
                {this.props.chatFeature && (
                    <ToggleSwitch
                        id="chat-toggle"
                        changeHandler={this.toggleChat}
                        label={replaceText(this.props.texts, 'toggle.chat')}
                        isActive={this.props.chatIsActive}
                        isDisabled={this.props.chatIsDisabled || disabledNoConnection || !this.props.callerPageLoaded || this.props.isHeadMountedDisplayDevice}
                        title={this.displayTextIfDisabled()}
                    />
                )}
                {this.props.audioStreamFeature && (
                    <ToggleSwitch
                        id="audio-toggle"
                        changeHandler={this.toggleAudioStream}
                        label={replaceText(this.props.texts, 'toggle.audio')}
                        isActive={this.props.audioStreamIsActive}
                        isDisabled={this.props.audioStreamIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                        longDelay
                    />
                )}
                {this.props.bidiFeature && (
                    <ToggleSwitch
                        id="bidi-toggle"
                        changeHandler={this.toggleBidi}
                        label={replaceText(this.props.texts, 'toggle.bidi')}
                        isActive={this.props.bidiIsActive}
                        isDisabled={this.props.bidiIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    />
                )}
                {this.props.streamRecordingFeature && (
                    <div className="toggle__container">
                        {this.props.streamRecordingIsActive && this.props.streamRecordingPermission && this.props.streamRecordingHasStarted && (
                            <div className="recording-timer">
                                <span>
                                    {minutes}:{seconds > 9 ? seconds : '0' + seconds}
                                </span>
                            </div>
                        )}
                        <ToggleSwitch
                            id="recording-toggle"
                            changeHandler={() => {
                                !recordingLimitIsReached && this.toggleRecordingFeature();
                            }}
                            label={replaceText(this.props.texts, 'toggle.recording')}
                            isActive={this.props.streamRecordingIsActive}
                            title={recordingLimitIsReached ? replaceText(this.props.texts, 'stream.recording.limit') : undefined}
                            isDisabled={
                                !this.props.callerPageLoaded ||
                                disabledNoConnection ||
                                this.state.streamRecordingLoading ||
                                recordingLimitIsReached ||
                                noStreamToRecord
                            }
                            longDelay
                        />
                    </div>
                )}
                {this.props.screenShareFeature && (
                    <ToggleSwitch
                        id="screenshare-toggle"
                        changeHandler={this.toggleScreenshare}
                        label={replaceText(this.props.texts, 'toggle.screenshare')}
                        isActive={this.props.screenshareIsActive}
                        isDisabled={this.props.screenShareIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    />
                )}
                {this.props.smartConnectFeature && (
                    <ToggleSwitch
                        id="toggleSmartConnect"
                        changeHandler={this.toggleSmartConnect}
                        label={replaceText(this.props.texts, 'toggle.smartConnect')}
                        isActive={this.props.smartConnectIsActive}
                        isDisabled={!this.props.callerPageLoaded || disabledNoConnection}
                    />
                )}
            </div>
        );
    }
}

// PropTypes for this Component
WebRTCSwitcher.propTypes = {
    videoIsDisabled: PropTypes.bool,
    gpsIsDisabled: PropTypes.bool,
    snapshotIsDisabled: PropTypes.bool,
    videoIsActive: PropTypes.bool,
    gpsIsActive: PropTypes.bool,
    snapshotIsActive: PropTypes.bool,
    chatIsActive: PropTypes.bool,
    pointerIsDisabled: PropTypes.bool,
    pointerIsActive: PropTypes.bool,
    pointerFeature: PropTypes.bool,
    snapshotFeature: PropTypes.bool,
    chatFeature: PropTypes.bool,
    chatIsDisabled: PropTypes.bool,
    hdSendFeature: PropTypes.bool,
    hdSendIsActive: PropTypes.bool,
    drawFeature: PropTypes.bool,
    drawIsActive: PropTypes.bool,
    drawIsDisabled: PropTypes.bool,
    connectionStatus: PropTypes.string,
    hasVideoStream: PropTypes.bool,
    isConnected: PropTypes.bool,
    wasChatActiveBefore: PropTypes.bool,
    photoPermission: PropTypes.bool,
    isPaintingAllowed: PropTypes.bool,
    texts: PropTypes.any,
    conferencingIsDisabled: PropTypes.bool,
    audioStreamFeature: PropTypes.bool,
    audioStreamIsDisabled: PropTypes.bool,
    audioStreamIsActive: PropTypes.bool,
    micIsMuted: PropTypes.bool,
    callerPageLoaded: PropTypes.bool,
    bidiFeature: PropTypes.bool,
    bidiIsDisabled: PropTypes.bool,
    bidiIsActive: PropTypes.bool,
    dispatcherBidiStream: PropTypes.object,
    videoStreamPermission: PropTypes.bool,
    audioIsMuted: PropTypes.bool,
    snapshotDisclaimerAccepted: PropTypes.bool,
    audioStreamPermission: PropTypes.bool,
    streamRecordingIsActive: PropTypes.bool,
    streamRecordingPermission: PropTypes.bool,
    recordings: PropTypes.array,
    streamRecordingFeature: PropTypes.bool,
    streamRecordingHasStarted: PropTypes.bool,
    videoBackgroundImage: PropTypes.any,
    screenShareFeature: PropTypes.bool,
    screenShareIsDisabled: PropTypes.bool,
    screenshareIsActive: PropTypes.bool,
    currentFocusFeature: PropTypes.string,
    videoIsLoading: PropTypes.bool,
    smartConnectFeature: PropTypes.bool,
    externalStreamUrl: PropTypes.string,
    bystanderToken: PropTypes.string,
    smartConnectIsActive: PropTypes.bool,
    activeDeviceId: PropTypes.string,
    isHeadMountedDisplayDevice: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        videoIsDisabled: state.application.videoIsDisabled,
        gpsIsDisabled: state.application.gpsIsDisabled,
        snapshotIsDisabled: state.application.snapshotIsDisabled,
        pointerIsDisabled: state.application.pointerIsDisabled,
        videoIsActive: state.application.videoIsActive,
        gpsIsActive: state.application.gpsIsActive,
        snapshotIsActive: state.application.snapshotIsActive,
        chatIsActive: state.application.chatIsActive,
        pointerIsActive: state.application.pointerIsActive,
        pointerFeature: state.features.pointerFeature,
        snapshotFeature: state.features.snapshotFeature,
        hdSendFeature: state.features.hdSendFeature,
        hdSendIsActive: state.application.hdSendIsActive,
        drawFeature: state.features.drawFeature,
        drawIsActive: state.application.drawIsActive,
        drawIsDisabled: state.application.drawIsDisabled,
        chatFeature: state.features.chatFeature,
        chatIsDisabled: state.application.chatIsDisabled,
        connectionStatus: state.connection.status,
        hasVideoStream: state.application.hasVideoStream,
        wasChatActiveBefore: state.application.wasChatActiveBefore,
        isConnected: state.connection.isConnected,
        photoPermission: state.session.photoPermission,
        isPaintingAllowed: state.paint.isPaintingAllowed,
        texts: state.texts.texts,
        conferencingIsDisabled: state.application.conferencingIsDisabled,
        audioStreamFeature: state.features.audioStreamFeature,
        audioStreamIsDisabled: state.application.audioStreamIsDisabled,
        audioStreamIsActive: state.application.audioStreamIsActive,
        micIsMuted: state.conferencing.micIsMuted,
        callerPageLoaded: state.application.callerPageLoaded,
        bidiFeature: state.features.bidiFeature,
        bidiIsDisabled: state.application.bidiIsDisabled,
        bidiIsActive: state.application.bidiIsActive,
        dispatcherBidiStream: state.streamSlice.dispatcherBidiStream,
        videoStreamPermission: state.permissions.videoStreamPermission,
        audioIsMuted: state.application.audioIsMuted,
        snapshotDisclaimerAccepted: state.disclaimers.snapshotDisclaimerAccepted,
        audioStreamPermission: state.permissions.audioStreamPermission,
        streamRecordingFeature: state.features.streamRecordingFeature,
        streamRecordingIsActive: state.application.streamRecordingIsActive,
        streamRecordingPermission: state.permissions.streamRecordingPermission,
        recordings: state.session.recordings,
        streamRecordingHasStarted: state.application.streamRecordingHasStarted,
        videoBackgroundImage: state.session.videoBackgroundImage,
        screenShareFeature: state.features.screenShareFeature,
        screenShareIsDisabled: state.application.screenShareIsDisabled,
        screenshareIsActive: state.application.screenshareIsActive,
        currentFocusFeature: state.focus.currentFocusFeature,
        videoIsLoading: state.application.videoIsLoading,
        smartConnectFeature: state.features.smartConnectFeature,
        externalStreamUrl: state.streamSlice.externalStreamUrl,
        bystanderToken: state.session.bystanderToken,
        smartConnectIsActive: state.application.smartConnectIsActive,
        activeDeviceId: state.sessionHandlingSlice.activeDeviceId,
        isHeadMountedDisplayDevice: state.sessionHandlingSlice.isHeadMountedDisplayDevice,
    };
};

export default connect(mapStateToProps)(WebRTCSwitcher);

import { combineReducers } from 'redux';

import notifications from './notifications';
import logs from './logs';
import texts from './texts';
import application from './application';
import features from './features';
import connection from './connection';
import session from './session';
import phonenumbers from './phonenumbers';
import paint from './paint';
import conferencing from './conferencing';
import permissions from './permissions';
import disclaimers from './disclaimers';
import files from './files';
import focus from './focus';
import invitedUsers from './invitedUsers';
import deviceScreen from './deviceScreen';
import sessionHandlingSlice from '../slices/sessionHandlingSlice';
import streamSlice from '../slices/streamSlice';
import overlaySlice from '../slices/overlaySlice';

/**
 * combined root reducer
 * see https://redux.js.org/
 */

export default combineReducers({
    notifications,
    logs,
    texts,
    application,
    features,
    connection,
    session,
    phonenumbers,
    paint,
    conferencing,
    permissions,
    disclaimers,
    files,
    focus,
    invitedUsers,
    deviceScreen,
    sessionHandlingSlice,
    streamSlice,
    overlaySlice
});

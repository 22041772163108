import {
    PING_INTERVAL,
    DEBUG,
    C_LOST_DURATION,
    C_INIT,
    DISPATCHER_USER_HANGUP_TIMEOUT,
    DISPLAY_ONLY_IN_SESSION,
    RESET_TOGGLE_THRESHOLD,
    STREAM_RECORDING_LIMIT,
    FILE_TYPE_ENDINGS,
    VIDEO_EFFECT_TIMEOUT,
    WEB_RTC_DISCONNNECT_TIMEOUT,
} from '../../config';
import { errorLog, timeLog } from '../../helper/logging';
import { formatDataSize, getWebBrowser, getFileTypeCategory } from '../../helper/helper';
import { sendFileTransferInfo } from '../../api/backendApi';
import { addLogDispatch } from '../../redux/actions/logs';
import { conversationErrorHandling, sessionErrorHandling } from '../../helper/rtcErrorHandling';
import { connectionEndedDispatch, connectionEstablishedDispatch, connectionLostDispatch } from '../../redux/actions/connection';
import reduxStore from '../../redux/store/index';
import { addSessionImageDispatch, dispatchAddSessionFile } from '../../redux/actions/session';
import { convertBlobToBase64, createErrorLog, createKpiLog } from '../../helper/helper';
import {
    deactivateAudioStreamDispatcherDispatch,
    deactivateBidiDispatch,
    deactivateCallerChat,
    deactivateChatDispatcherDispatch,
    deactivateConferencingDispatch,
    deactivateDrawDispatcherDispatch,
    deactivateExternalStreamDispatch,
    deactivateGPSDispatch,
    deactivateHDSendDispatch,
    deactivatePointerDispatcherDispatch,
    deactivateScreenshareDispatch,
    deactivateSmartConnectDispatch,
    deactivateSnapshotDispatch,
    deactivateStreamRecordingDispatch,
    deactivateVideoDispatcherDispatch,
    disableDrawDispatch,
    disablePointerDispatch,
    disableSnapshotDispatch,
    dispatchCallerFileTransferEnded,
    dispatchStartStreamRecording,
    dispatchStopStreamRecording,
    muteAudioDispatch,
    unmuteAudioDispatch,
} from '../../redux/actions/application';
import { dispatchDisallowPaintingDispatcher } from '../../redux/actions/paint';
import { addConversationNameDispatcherDispatch, muteMicDispatcherDispatch, unmuteMicDispatcherDispatch } from '../../redux/actions/conferencing';
import { createUserDisplayName, dispatcherStreamHandlers, enterConversation, getURLParams } from '../../helper/rtcFlowHandling';
import { ONLY_AUDIO, ONLY_BIDI_VIDEO, SCREENSHARING } from '../../redux/reducers/streams';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import {
    denyAudioStreamPermissionDispatch,
    denyScreensharePermissionDispatch,
    denyVideoStreamPermissionDispatch,
    grantAudioStreamPermissionDispatch,
    grantScreensharePermissionDispatch,
    grantVideoStreamPermissionDispatch,
} from '../../redux/actions/permissions';
import dispatcherDummyStream from '../../resources/video/dispatcherDummyStream.mp4';
import { addFileDispatcherDispatch, addFileUploadQueueDispatch, removeFileUploadQueueDispatch, removePreviewFileDispatch } from '../../redux/actions/files';
import { dispatchResetFocusWindow, dispatchSetFocusWindowChat, dispatchSetFocusWindowScreenshare } from '../../redux/actions/focus';
import { WebRtcManagerType, WebRtcMessageDispatcher } from '../../types';
import { handleContactMessageDispatcher } from '../incomingMessages/handleContactMessageDispatcher';
import {
    sendCurrentFocusFeatureStatus,
    sendFileTransferEnded,
    sendPing,
    sendSetFeatureFocus,
    sendToggleVideo,
    sendWebRtcMessage,
} from '../outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';
import store from '../../redux/store/index';
import { getComplexObject } from '../../helper/complexObjectStorage';
import { serializeContact } from '../../redux/utils/serializeContact';
import {
    addDispatcherAudioStream,
    addDispatcherBidiStream,
    addDispatcherStream,
    removeDispatcherAudioStream,
    removeDispatcherBidiStream,
} from '../../redux/actionCreators/actionCreators';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';
import { loadEventListenersDispatcher, unloadEventListenersDispatcher } from '../eventHandlers/eventHandlingDispatcher';

/**
 * DispatcherWebRTCManager
 * contains all the functions needed to setup the dispatcher webrtc connection and communication
 */

class DispatcherWebRTCManager {
    apiKey = null;
    apiRTC = null;
    audioStream = null;
    bidiStream = null;
    callbackOnIncoming = null;
    callerId = null;
    closeCallCallbacks = [];
    connectedConversation = null;
    connected = false;
    connectedSession = null;
    conversationName = null;
    dummyStream = null;
    failedLogins = 0;
    heartbeatInterval = null;
    isAndroid = false;
    isFirefox = false;
    isIOS = false;
    isPrimaryPlatformUsed = null;
    newCallCallbacks = [];
    osMajorVersion = null;
    pongMissed = 0;
    pongAccepted = 0;
    previousCameraId = null;
    sender = null;
    screenshareStream = null;
    sessionHijackingActivationTimeout = null;
    stoppedViaBrowserButton = false;
    streamRetryRequests = 0;
    streamWithEffect = null;
    token = null;
    translationError = false;
    userAgent = null;
    webRtcAlias = null;
    webRtcDisconnectionTimeout = null;
    webRtcSessionId = null;

    /**
     * init the webrtc session event listeners and execute callback on incoming call
     * @param {function} callbackOnIncoming
     */
    initWebRTC = async callbackOnIncoming => {
        await dispatcherWebRTCManager.createAndJoinConversation();
        callbackOnIncoming();
        dispatcherWebRTCManager.callbackOnIncoming = callbackOnIncoming;
    };

    /**
     * register a new useragent with apiRTC
     * @param {object} token
     * @returns {Promise}
     */
    registerUserAgentAndStartSession = async ({ token }) => {
        dispatcherWebRTCManager.userAgent = new dispatcherWebRTCManager.apiRTC.UserAgent({
            uri: 'apzkey:' + dispatcherWebRTCManager.apiKey,
        });
        dispatcherWebRTCManager.token = token;

        return new Promise(function (resolve, reject) {
            dispatcherWebRTCManager.userAgent
                .register({
                    cloudUrl: dispatcherWebRTCManager.webRtcAlias,
                    token: token,
                })
                .then(session => {
                    if (DEBUG) addLogDispatch(['user agent session registered']);
                    sessionErrorHandling(session, this);

                    dispatcherWebRTCManager.connectedSession = session;
                    dispatcherWebRTCManager.connected = true;
                    dispatcherWebRTCManager.webRtcSessionId = session.getId();
                    dispatcherWebRTCManager.setupMessageListener();
                    dispatcherWebRTCManager.setupFileListener();

                    timeLog('authSession');
                    createKpiLog('infoDispatcherLoginSuccess');

                    resolve(session.getId());
                })
                .catch(error => {
                    if (DEBUG) addLogDispatch(['Registration error', error]);
                    reject(error);

                    dispatcherWebRTCManager.failedLogins = dispatcherWebRTCManager.failedLogins + 1;
                    const additionalStates = {
                        0: dispatcherWebRTCManager.failedLogins,
                    };

                    createErrorLog('infoDispatcherLoginFail', '', '', additionalStates);
                });
        });
    };

    establishConnectivityWithNewCaller(contactInfo) {
        dispatcherWebRTCManager.sender = null;
        dispatcherWebRTCManager.sender = contactInfo;
        if (dispatcherWebRTCManager.sender !== null) {
            if (DEBUG) addLogDispatch(['call invitation accepted']);
            createKpiLog('infoConnectionEstablished');
            dispatcherWebRTCManager.establishHeartbeat();
            connectionEstablishedDispatch();
            dispatcherWebRTCManager.callbackOnIncoming && dispatcherWebRTCManager.callbackOnIncoming();
            for (const callback of dispatcherWebRTCManager.newCallCallbacks) {
                if (typeof callback == 'function') {
                    callback();
                }
            }
        }
    }

    /**
     * create and join a session group
     */

    async getUrlParamsAndCreateSessionGroup() {
        const { conversationName } = getURLParams();
        dispatcherWebRTCManager.connectedSession.joinGroup(conversationName);
        dispatcherWebRTCManager.connectedSession.leaveGroup('default');
        dispatcherWebRTCManager.connectedSession.unsubscribeToGroup('default');
    }

    /**
     * Decode token
     */
    getUserFromToken() {
        var base64Url = dispatcherWebRTCManager.token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        let parsedToken = JSON.parse(jsonPayload);

        return parsedToken.sub;
    }

    /**
     * setup all webrtc contact message eventlisteners
     */
    setupMessageListener() {
        dispatcherWebRTCManager.connectedSession.removeListener('contactMessage');
        dispatcherWebRTCManager.connectedSession.on('contactMessage', handleContactMessageDispatcher);
    }

    /**
     * sets up the file invitation listener
     */
    setupFileListener() {
        dispatcherWebRTCManager.connectedSession.on('fileTransferInvitation', invitation => {
            invitation
                .accept()
                .then(async fileObj => {
                    const base64File = await convertBlobToBase64(fileObj.file);
                    const timestamp = new Date().getTime();
                    sendFileTransferEnded();

                    dispatchCallerFileTransferEnded(timestamp, fileObj.type);

                    addSessionImageDispatch({
                        image: base64File,
                        type: fileObj.type,
                        time: timestamp,
                        quality: 'hd',
                    });
                })
                .catch(function (error) {
                    if (DEBUG) addLogDispatch([`Error receiving file invitation - ${error}`]);

                    errorLog({
                        message: 'HD Send Image error receiving file invitation',
                        error: error,
                        eventId: 'HD_IMAGE_FILE_INVITATION_ERROR',
                    });
                });
        });
    }

    createAndJoinConversation() {
        var conversationOptions = {
            moderationEnabled: true,
            moderator: true,
        };
        var createStreamOptions = {};
        createStreamOptions.constraints = {
            audio: false,
            video: false,
        };

        createUserDisplayName(dispatcherWebRTCManager, 'dispatcher');

        const { callerId, conversationName } = getURLParams();
        dispatcherWebRTCManager.callerId = callerId;
        dispatcherWebRTCManager.conversationName = conversationName;

        enterConversation(dispatcherWebRTCManager, conversationOptions);
        addConversationNameDispatcherDispatch(conversationName);
        dispatcherWebRTCManager.connectedConversation.join();
        loadEventListenersDispatcher();
        conversationErrorHandling(dispatcherWebRTCManager.connectedConversation, dispatcherWebRTCManager);
    }

    stopScreenshare = () => {
        if (dispatcherWebRTCManager.screenshareStream !== null) {
            dispatcherWebRTCManager.screenshareStream.removeFromDiv('screenshare-container', 'screenshare-stream');
            if (!dispatcherWebRTCManager.stoppedViaBrowserButton) {
                dispatcherWebRTCManager.connectedConversation.unpublish(dispatcherWebRTCManager.screenshareStream);
                dispatcherWebRTCManager.screenshareStream.release();
            }
            dispatcherWebRTCManager.screenshareStream = null;
            dispatcherWebRTCManager.stoppedViaBrowserButton = false;
            createKpiLog('infoScreenshare', 'unpublished');
        }
    };

    // Share screen in active conversation
    startScreenshare = () => {
        dispatcherWebRTCManager.apiRTC.Stream.createDisplayMediaStream(SCREENSHARING, false)
            .then(stream => {
                const publishOptions = {
                    context: {
                        screenshare: true,
                    },
                };
                dispatcherWebRTCManager.screenshareStream = stream;
                store.dispatch(addDispatcherStream(stream));
                stream.removeFromDiv('screenshare-container', 'screenshare-stream');
                stream.addInDiv('screenshare-container', 'screenshare-stream', {}, true);
                dispatcherWebRTCManager.connectedConversation.publish(stream, publishOptions);
                dispatcherStreamHandlers(stream);
                // For first activation, log permission request
                if (!reduxStore.getState().permissions.screensharePermission) {
                    createKpiLog('permissionScreenshare', 'granted');
                    grantScreensharePermissionDispatch();
                }
                createKpiLog('infoScreenshare', 'published');
                if (!reduxStore.getState().application.drawIsActive) {
                    dispatchSetFocusWindowScreenshare();
                    sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
                }
            })
            .catch(function (err) {
                addNotificationAndShowDispatch('error.scr_shr_err', 'error', DISPLAY_ONLY_IN_SESSION);
                deactivateScreenshareDispatch('dispatcher');
                // Log permission request denial
                if (reduxStore.getState().permissions.screensharePermission || reduxStore.getState().permissions.screensharePermission === null) {
                    createKpiLog('permissionScreenshare', 'denied');
                    denyScreensharePermissionDispatch();
                }
            });
    };

    startBidi = () => {
        const streamOptions = {
            ...ONLY_BIDI_VIDEO,
        };

        return new Promise((resolve, reject) => {
            dispatcherWebRTCManager.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    const publishOptions = {
                        context: {
                            bidi: true,
                        },
                    };
                    setTimeout(() => {
                        // Artificial delay to avoid triggering browser autoplay prevention
                        dispatcherWebRTCManager.bidiStream = stream;
                        store.dispatch(addDispatcherBidiStream(stream));
                        stream.removeFromDiv('bidiContainer__inner', 'bidi-stream');
                        stream.addInDiv('bidiContainer__inner', 'bidi-stream', {}, true);
                        dispatcherWebRTCManager.connectedConversation.publish(dispatcherWebRTCManager.bidiStream, publishOptions);
                        if (!reduxStore.getState().permissions.videoStreamPermission || reduxStore.getState().permissions.videoStreamPermission === null) {
                            // Success
                            createKpiLog('permissionBidi', 'granted');
                            grantVideoStreamPermissionDispatch();
                        }
                        return resolve();
                    }, 1000);
                })
                .catch(() => {
                    addNotificationAndShowDispatch('error.bidiCamera.access', 'error', DISPLAY_ONLY_IN_SESSION);
                    if (reduxStore.getState().permissions.videoStreamPermission === true || reduxStore.getState().permissions.videoStreamPermission === null) {
                        createKpiLog('permissionBidi', 'denied');
                        denyVideoStreamPermissionDispatch();
                    }
                    deactivateBidiDispatch();
                });
        });
    };

    startVideoWithEffect = async effect => {
        const streamOptions = {
            ...ONLY_BIDI_VIDEO,
        };

        const options = {
            backgroundImageUrl: reduxStore.getState().session.videoBackgroundImage,
        };

        return new Promise((resolve, reject) => {
            dispatcherWebRTCManager.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    const publishOptions = {
                        context: {
                            bidi: true,
                        },
                    };
                    dispatcherWebRTCManager.bidiStream = stream;

                    // checks for the availablity of cdn dependent video effects. if loading takes longer than the timeout, toggle off
                    let videoEffectTimeout = null;
                    videoEffectTimeout = setTimeout(() => {
                        addNotificationAndShowDispatch('error.video.effect', 'error', DISPLAY_ONLY_IN_SESSION);
                        dispatcherWebRTCManager.stopBidi();
                        deactivateBidiDispatch();
                    }, VIDEO_EFFECT_TIMEOUT);

                    stream
                        .applyVideoProcessor(effect, options)
                        .then(streamWithEffect => {
                            // Artificial delay to avoid triggering browser autoplay prevention
                            setTimeout(() => {
                                clearTimeout(videoEffectTimeout);
                                dispatcherWebRTCManager.streamWithEffect = streamWithEffect;
                                dispatcherWebRTCManager.bidiStream.removeFromDiv('bidiContainer__inner', 'bidi-stream');
                                streamWithEffect.addInDiv('bidiContainer__inner', 'bidi-stream', {}, true);
                                dispatcherWebRTCManager.connectedConversation.publish(streamWithEffect, publishOptions);
                                if (
                                    !reduxStore.getState().permissions.videoStreamPermission ||
                                    reduxStore.getState().permissions.videoStreamPermission === null
                                ) {
                                    // Success
                                    createKpiLog('permissionBidi', 'granted');
                                    grantVideoStreamPermissionDispatch();
                                }
                                return resolve();
                            }, 1000);
                        })
                        .catch(err => {
                            console.log(err);
                            if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
                            return reject();
                        });
                    return resolve();
                })
                .catch(err => {
                    console.log(err);
                    addNotificationAndShowDispatch('error.bidiCamera.access', 'error', DISPLAY_ONLY_IN_SESSION);
                    if (reduxStore.getState().permissions.videoStreamPermission === true || reduxStore.getState().permissions.videoStreamPermission === null) {
                        createKpiLog('permissionBidi', 'denied');
                        denyVideoStreamPermissionDispatch();
                    }
                    deactivateBidiDispatch();
                });
        });
    };

    stopBidi = () => {
        if (dispatcherWebRTCManager.bidiStream !== null) {
            dispatcherWebRTCManager.bidiStream.release();
            dispatcherWebRTCManager.bidiStream.removeFromDiv('bidiContainer__inner', 'bidi-stream');
            dispatcherWebRTCManager.connectedConversation.unpublish(dispatcherWebRTCManager.bidiStream);
        }
        dispatcherWebRTCManager.bidiStream = null;

        if (dispatcherWebRTCManager.streamWithEffect !== null) {
            dispatcherWebRTCManager.streamWithEffect.release();
            dispatcherWebRTCManager.streamWithEffect.removeFromDiv('bidiContainer__inner', 'bidi-stream');
            dispatcherWebRTCManager.connectedConversation.unpublish(dispatcherWebRTCManager.streamWithEffect);
        }
        dispatcherWebRTCManager.streamWithEffect = null;

        if (reduxStore.getState().streamSlice.dispatcherBidiStream) {
            store.dispatch(removeDispatcherBidiStream(reduxStore.getState().streamSlice.dispatcherBidiStream));
        }
    };

    async handleDispatcherAudioStream(isActive) {
        // audio inactive
        if (!reduxStore.getState().application.audioStreamIsActive) {
            // publish a new audio stream
            if (isActive) {
                await dispatcherWebRTCManager.createAudioStream();
                if (reduxStore.getState().application.audioIsMuted) unmuteAudioDispatch();
                if (reduxStore.getState().conferencing.micIsMuted) unmuteMicDispatcherDispatch();
            }
        }

        // audio active
        if (reduxStore.getState().application.audioStreamIsActive) {
            // unpublish the audio stream
            if (!isActive) {
                dispatcherWebRTCManager.removeAudioStream();
                if (!reduxStore.getState().conferencing.micIsMuted) muteMicDispatcherDispatch();
                if (!reduxStore.getState().application.audioIsMuted) muteAudioDispatch();
            }
        }
    }

    removeAudioStream() {
        if (reduxStore.getState().streamSlice.dispatcherAudioStream) {
            // Fetch stream from complex object storage
            const stream = getComplexObject(reduxStore.getState().streamSlice.dispatcherAudioStream.streamId);
            if (stream) {
                dispatcherWebRTCManager.connectedConversation.unpublish(stream);
                stream.release();
                store.dispatch(removeDispatcherAudioStream(stream));
                createKpiLog('infoAudioStream', 'unpublished');
            }
        }
    }

    createAudioStream() {
        return new Promise((resolve, reject) => {
            if (reduxStore.getState().streamSlice.dispatcherAudioStream === null) {
                const streamOptions = { ...ONLY_AUDIO };
                dispatcherWebRTCManager.userAgent
                    .createStream(streamOptions)
                    .then(stream => {
                        const options = {
                            audioLabels: ['dispatcherAudio'],
                        };
                        dispatcherWebRTCManager.audioStream = stream;
                        dispatcherWebRTCManager.connectedConversation.publish(stream, options);
                        store.dispatch(addDispatcherAudioStream(stream));
                        if (!reduxStore.getState().permissions.audioStreamPermission) {
                            createKpiLog('permissionAudioStream', 'granted');
                            grantAudioStreamPermissionDispatch();
                        }
                        createKpiLog('infoAudioStream', 'published');
                        return resolve();
                    })
                    .catch(() => {
                        addNotificationAndShowDispatch('error.mic.acc', 'error', DISPLAY_ONLY_IN_SESSION);
                        if (
                            reduxStore.getState().permissions.audioStreamPermission === true ||
                            reduxStore.getState().permissions.audioStreamPermission === null
                        ) {
                            createKpiLog('permissionAudioStream', 'denied');
                            denyAudioStreamPermissionDispatch();
                        }
                        return reject();
                    });
            }
        });
    }

    async startConversationRecording() {
        await dispatcherWebRTCManager.startDummyStream();

        return new Promise((resolve, reject) => {
            let options = {};

            // Dispatcher must have an active stream in the conversation to record streams
            let timestamp = new Date().getTime();
            const fileName = dispatcherWebRTCManager.callerId + '_' + dispatcherAuthManager.bystanderToken + '_' + timestamp;

            let loadInterval = 0;
            options = {
                labelEnabled: true,
                labels: ['callerVideo', 'callerAudio', 'dispatcherAudio', 'conferenceUserAudio'],
                mode: 'efficient',
                customIdInFilename: fileName,
                ttl: reduxStore.getState().session.timeToLive,
            };

            loadInterval = setInterval(checkIfDummyStreamActiveAndStartRecording, 1000);

            function checkIfDummyStreamActiveAndStartRecording() {
                let streams = dispatcherWebRTCManager.connectedConversation.getAvailableStreamList();
                if (streams.filter(stream => stream.isRemote !== true)) {
                    clearInterval(loadInterval);

                    dispatcherWebRTCManager.connectedConversation
                        .startRecording(options)
                        .then(recordingInfo => {
                            const additionalStates = {
                                0: recordingInfo.recordedFileName,
                                1: recordingInfo.mediaURL,
                            };
                            dispatchStartStreamRecording();
                            createKpiLog('infoStreamRecordingStatus', 'started');
                            createKpiLog('infoStreamRecordingInfo', '', additionalStates);
                            return resolve();
                        })
                        .catch(error => {
                            console.error('startRecording', error);
                            return reject();
                        });
                }
            }
        });
    }

    stopConversationRecording() {
        return new Promise((resolve, reject) => {
            if (!reduxStore.getState().application.streamRecordingHasStarted) {
                return resolve;
            }
            dispatcherWebRTCManager.connectedConversation
                .stopRecording()
                .then(recordingInfo => {
                    reduxStore.getState().application.streamRecordingHasStarted && dispatchStopStreamRecording();
                    reduxStore.getState().session.recordings.length >= STREAM_RECORDING_LIMIT && deactivateStreamRecordingDispatch();
                    createKpiLog('infoStreamRecordingStatus', 'stopped');
                    dispatcherWebRTCManager.stopDummyStream();
                    return resolve;
                })
                .catch(error => {
                    console.error('stopRecording', error);
                    return reject;
                });
            return resolve;
        });
    }

    async startDummyStream() {
        return new Promise((resolve, reject) => {
            let videoElement = document.getElementById('hiddenCanvas');
            const options = {
                videoOnly: true,
                context: {
                    recording: true,
                },
            };
            videoElement.src = dispatcherDummyStream;
            videoElement.loop = true;
            videoElement.play().then(() => {
                if (videoElement && videoElement.src) {
                    let mediaStream = null;
                    if (getWebBrowser() === 'isFirefox') {
                        mediaStream = videoElement.mozCaptureStream();
                    } else {
                        mediaStream = videoElement.captureStream();
                    }
                    dispatcherWebRTCManager.userAgent
                        .createStreamFromMediaStream(mediaStream)
                        .then(stream => {
                            dispatcherWebRTCManager.dummyStream = stream;
                            dispatcherWebRTCManager.connectedConversation.publish(stream, options);
                            return resolve(stream);
                        })
                        .catch(err => {
                            console.log(err);
                            return reject();
                        });
                }
            });
        });
    }

    stopDummyStream() {
        if (dispatcherWebRTCManager.dummyStream !== null) {
            dispatcherWebRTCManager.dummyStream.release();
            dispatcherWebRTCManager.connectedConversation.unpublish(dispatcherWebRTCManager.dummyStream);
        }
        dispatcherWebRTCManager.dummyStream = null;
    }

    // Leave active conversation

    leaveConference() {
        if (dispatcherWebRTCManager.connectedConversation !== null) {
            dispatcherWebRTCManager.connectedConversation
                .leave()
                .then(() => {
                    if (dispatcherWebRTCManager.screenshareStream !== null) {
                        dispatcherWebRTCManager.connectedConversation.unpublish(dispatcherWebRTCManager.screenshareStream);
                        dispatcherWebRTCManager.screenshareStream.release();
                    }

                    dispatcherWebRTCManager.screenshareStream = null;
                    dispatcherWebRTCManager.connectedConversation.destroy();
                    unloadEventListenersDispatcher();
                    dispatcherWebRTCManager.connectedConversation = null;
                })
                .catch(err => {
                    console.error('Conversation leave error', err);
                });
        }

        if (dispatcherWebRTCManager.bidiStream !== null) {
            dispatcherWebRTCManager.bidiStream.release();
            dispatcherWebRTCManager.bidiStream = null;
        }

        dispatcherWebRTCManager.name = null;
    }

    muteMic() {
        if (dispatcherWebRTCManager.audioStream !== null) {
            dispatcherWebRTCManager.audioStream.disableAudio();
        }
    }

    unmuteMic() {
        if (dispatcherWebRTCManager.audioStream !== null) {
            dispatcherWebRTCManager.audioStream.enableAudio();
        }
    }

    async pushFileToChat(description = '') {
        const blob = await fetch(reduxStore.getState().files.previewFile.fileUrl).then(response => response.blob());
        let file = null;

        if (blob instanceof File) {
            file = blob;
        } else if (blob instanceof Blob) {
            file = new File([blob], reduxStore.getState().files.previewFile.name, { type: reduxStore.getState().files.previewFile.type });
        }

        const fileDescriptor = {
            file: file,
            filetype: reduxStore.getState().files.previewFile.extension,
            ttl: reduxStore.getState().session.timeToLive,
        };

        const fileExtension = Object.entries(FILE_TYPE_ENDINGS).find(([key, value]) => key === file.type);
        const fileUrl = URL.createObjectURL(file);
        const formattedFileSize = formatDataSize(file.size);

        removePreviewFileDispatch();
        addFileUploadQueueDispatch({ fileUrl: fileUrl, name: file.name, size: formattedFileSize, type: file.type, extension: fileExtension[1], description });

        dispatcherWebRTCManager.connectedConversation
            .pushData(fileDescriptor)
            .then(cloudMediaInfo => {
                const timestamp = Date.now();
                const fileInfo = {
                    ...cloudMediaInfo,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    extension: fileExtension[1],
                    description,
                    time: timestamp,
                };
                addFileDispatcherDispatch({ ...fileInfo, size: formattedFileSize });
                dispatchAddSessionFile(file.name, getFileTypeCategory(file), timestamp, cloudMediaInfo.url);
                sendFileTransferInfo(fileInfo, reduxStore.getState().session.timeToLive);
                removeFileUploadQueueDispatch({
                    fileUrl: fileUrl,
                });
                createKpiLog('fileTransmissionDispatcherInfo', '', { 0: file.name, 1: formattedFileSize });

                if (reduxStore.getState().application.currentFocusFeature !== FOCUS_FEATURE_TYPE.CHAT) {
                    sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                    dispatchSetFocusWindowChat();
                }

                const message = {
                    data: WebRtcMessageDispatcher.DISPATCHER_UPLOADED_FILE,
                    fileInfo: fileInfo,
                };

                sendWebRtcMessage(message);
            })
            .catch(error => {
                console.log('File uploading error: ', error);
                addNotificationAndShowDispatch('error.file.upload', 'error', DISPLAY_ONLY_IN_SESSION);
                removeFileUploadQueueDispatch({
                    fileUrl: fileUrl,
                });
            });
    }

    toggleOffAllFeatures() {
        if (reduxStore.getState().application.videoIsActive) deactivateVideoDispatcherDispatch();
        if (reduxStore.getState().application.gpsIsActive) deactivateGPSDispatch();
        if (reduxStore.getState().application.snapshotIsActive) deactivateSnapshotDispatch(true);
        if (reduxStore.getState().application.pointerIsActive) deactivatePointerDispatcherDispatch();
        if (reduxStore.getState().application.chatIsActive) {
            deactivateChatDispatcherDispatch();
            if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT) dispatchResetFocusWindow(WebRtcManagerType.DISPATCHER);
        }
        if (reduxStore.getState().application.hdSendIsActive) deactivateHDSendDispatch();
        if (reduxStore.getState().application.drawIsActive) deactivateDrawDispatcherDispatch();
        if (reduxStore.getState().application.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
        if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
        if (reduxStore.getState().application.streamRecordingIsActive) deactivateStreamRecordingDispatch();
        if (reduxStore.getState().application.screenshareIsActive) deactivateScreenshareDispatch('dispatcher');
        if (reduxStore.getState().application.conferencingIsActive) deactivateConferencingDispatch();
        if (reduxStore.getState().application.externalStreamIsActive) deactivateExternalStreamDispatch();
        if (reduxStore.getState().application.smartConnectIsActive) deactivateSmartConnectDispatch();
    }

    //
    establishHeartbeat() {
        clearInterval(dispatcherWebRTCManager.heartbeatInterval);
        dispatcherWebRTCManager.pongMissed = 0;
        dispatcherWebRTCManager.pongAccepted = 0;

        dispatcherWebRTCManager.heartbeatInterval = setInterval(() => {
            dispatcherWebRTCManager.pongMissed += 1; // starts at 1

            sendPing();
            dispatcherWebRTCManager.handlePong();
        }, PING_INTERVAL);
    }

    handlePong() {
        const missedThreshold = (C_LOST_DURATION + PING_INTERVAL) / PING_INTERVAL; // 4
        const acceptedThreshold = C_LOST_DURATION / PING_INTERVAL; // 3
        const resetToggleThreshold = (RESET_TOGGLE_THRESHOLD + PING_INTERVAL) / PING_INTERVAL; // 10
        const connectionStore = reduxStore.getState().connection;

        if (dispatcherWebRTCManager.pongMissed >= missedThreshold) {
            dispatcherWebRTCManager.pongAccepted = 0;
            if (connectionStore.isConnected && connectionStore.status === C_INIT) {
                connectionLostDispatch();
                createKpiLog('infoHeartbeatLost');
            }
        }

        if (dispatcherWebRTCManager.pongMissed >= resetToggleThreshold) {
            if (reduxStore.getState().application.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
            if (reduxStore.getState().application.chatIsActive) deactivateCallerChat();
            if (reduxStore.getState().application.videoIsActive) deactivateVideoDispatcherDispatch();
            if (reduxStore.getState().application.snapshotIsActive) deactivateSnapshotDispatch(true);
            if (!reduxStore.getState().application.snapshotIsDisabled) disableSnapshotDispatch();
            if (reduxStore.getState().application.pointerIsActive) deactivatePointerDispatcherDispatch();
            if (!reduxStore.getState().application.pointerIsDisabled) disablePointerDispatch();
            if (!reduxStore.getState().application.drawIsDisabled) disableDrawDispatch();
            if (reduxStore.getState().application.drawIsActive) deactivateDrawDispatcherDispatch();
            if (reduxStore.getState().paint.isPaintingAllowed) dispatchDisallowPaintingDispatcher();
            if (reduxStore.getState().application.streamRecordingIsActive) deactivateStreamRecordingDispatch();
            if (reduxStore.getState().application.streamRecordingHasStarted) dispatcherWebRTCManager.stopConversationRecording();
            if (reduxStore.getState().application.bidiIsActive) deactivateBidiDispatch();
            reduxStore.getState().notifications.currentNotifications.forEach(notification => {
                if (notification.message === 'disclaimer.waiting.caller') {
                    hideAndRemoveNotificationsDispatch('pending');
                }
            });
        }

        if (dispatcherWebRTCManager.pongAccepted >= acceptedThreshold) {
            if (!reduxStore.getState().connection.isConnected) {
                connectionEstablishedDispatch();
                createKpiLog('infoHeartbeatRegained');

                if (reduxStore.getState().notifications.currentNotifications.length > 0) {
                    reduxStore.getState().notifications.currentNotifications.forEach(notification => {
                        if (notification.message === 'error.lg_evnt') {
                            hideAndRemoveNotificationsDispatch('error');
                        }
                    });
                }
                if (dispatcherWebRTCManager.isIOS && reduxStore.getState().application.videoIsActive) {
                    sendToggleVideo(false);
                    setTimeout(() => {
                        sendToggleVideo(true);
                    }, 1500);
                }
                // send current feature focus state to caller on connection re-established
                sendCurrentFocusFeatureStatus();
            }
        }
    }

    sendMessageToUser(message2Send, user) {
        const message = JSON.stringify(message2Send);
        let intervalCount = 5;
        let interval;

        if (user) {
            const send = () => {
                if (DEBUG) addLogDispatch(['senderObject', serializeContact(user)]);
                if (typeof user.sendMessage === 'function') {
                    user.sendMessage(message)
                        .then(function () {
                            if (DEBUG) addLogDispatch(['message send', message]);
                        })
                        .catch(err => {
                            if (DEBUG) addLogDispatch(['message send error', message, err]);
                        });
                }
            };

            if (user) {
                send();
                return;
            }

            interval = window.setInterval(() => {
                if (DEBUG) addLogDispatch(['sendMessageInterval']);
                if (dispatcherWebRTCManager.sender) {
                    send();
                    clearInterval(interval);
                } else {
                    if (intervalCount > 0) {
                        intervalCount -= 1;
                    } else {
                        clearInterval(interval);
                        if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                    }
                }
            }, 200);
        }
    }

    sendMessageToAllConferenceUsers(message2Send) {
        if (dispatcherWebRTCManager.connectedConversation && dispatcherWebRTCManager.connectedConversation !== null) {
            const { callerId } = getURLParams();
            const message = JSON.stringify(message2Send);
            let intervalCount = 5;
            let interval;
            let contacts = dispatcherWebRTCManager.connectedConversation.getContacts();
            let keys = Object.keys(contacts);

            const send = () => {
                for (const element of keys) {
                    if (contacts[element].userData.username !== callerId) {
                        if (DEBUG) addLogDispatch(['senderObject', serializeContact(contacts[element])]);
                        contacts[element]
                            .sendMessage(message)
                            .then(function () {
                                if (DEBUG) addLogDispatch(['message send', message]);
                            })
                            .catch(err => {
                                if (DEBUG) addLogDispatch(['message send error', message, err]);
                            });
                    }
                }
            };

            if (contacts) {
                send();
                return;
            }

            interval = window.setInterval(() => {
                if (DEBUG) addLogDispatch(['sendMessageInterval']);
                if (dispatcherWebRTCManager.sender) {
                    send();
                    clearInterval(interval);
                } else {
                    if (intervalCount > 0) {
                        intervalCount -= 1;
                    } else {
                        clearInterval(interval);
                        if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                    }
                }
            }, 200);
        }
    }

    /**
     * add a new callback to call accepted event
     * @param {function} callback
     */
    addNewCallCallback(callback) {
        dispatcherWebRTCManager.newCallCallbacks.push(callback);
    }

    /**
     * add a new callback to call ended event
     * @param {function} callback
     */
    addCloseCallCallback(callback) {
        dispatcherWebRTCManager.closeCallCallbacks.push(callback);
    }

    /**
     * clear all call callbacks
     */
    clearCallCallbacks() {
        dispatcherWebRTCManager.newCallCallbacks = [];
        dispatcherWebRTCManager.closeCallCallbacks = [];
    }

    /**
     * logout from current session
     */
    logout() {
        dispatcherWebRTCManager.closeSession();
    }

    /**
     * close the session
     */
    async closeSession() {
        dispatcherWebRTCManager.closeCallCallbacks.forEach(currentCloseCallCallback => {
            if (typeof currentCloseCallCallback == 'function') {
                currentCloseCallCallback();
            }
        });

        setTimeout(() => {
            if (dispatcherWebRTCManager.connectedConversation) {
                dispatcherWebRTCManager.connectedConversation.leave();
                dispatcherWebRTCManager.handleDispatcherAudioStream(false);
            }
            dispatcherWebRTCManager.connected = false;
            dispatcherAuthManager.phone = null;
            dispatcherAuthManager.isPhoneNumberConsumed = false;
            dispatcherAuthManager.backendSessionId = null;
            dispatcherWebRTCManager.sender = null;
            dispatcherWebRTCManager.bystanderToken = null;
            clearInterval(dispatcherWebRTCManager.heartbeatInterval);
            connectionEndedDispatch();
        }, DISPATCHER_USER_HANGUP_TIMEOUT);

        // wait on execution of any potential web rtc events to complete before unregistering user agent
        dispatcherWebRTCManager.webRtcDisconnectionTimeout = setTimeout(() => {
            dispatcherWebRTCManager.unregisterUserAgentAndDeleteApiRtcObject();
            clearTimeout(dispatcherWebRTCManager.webRtcDisconnectionTimeout);
        }, WEB_RTC_DISCONNNECT_TIMEOUT);
    }

    unregisterUserAgentAndDeleteApiRtcObject() {
        if (dispatcherWebRTCManager.userAgent) {
            dispatcherWebRTCManager.userAgent
                .unregister()
                .then(() => {
                    console.log('Disconnected from rtc platform');
                    dispatcherWebRTCManager.userAgent = null;
                    if (window && window.apiRTC) {
                        delete window.apiRTC;
                    }
                })
                .catch(error => {
                    console.log('error disconnecting during unregistration: ', error);
                });
        }
    }
}

export let dispatcherWebRTCManager = new DispatcherWebRTCManager();

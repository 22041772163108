import { DEBUG } from '../../config';
import { addLogDispatch } from '../../redux/actions/logs';
import { invitedUserWebRTCManager } from '../webrtcManagers/InvitedUserWebRTCManager';
import { WebRtcMessageConference } from '../../types';

export const sendIsLeaving = () => {
    const message = {
        data: WebRtcMessageConference.IS_LEAVING,
    };

    invitedUserWebRTCManager.sendMessage(message);
};

export const sendMicrophoneError = () => {
    const message = {
        data: WebRtcMessageConference.MICROPHONE_ERROR,
        userId: invitedUserWebRTCManager.connectedSession.id,
    };

    invitedUserWebRTCManager.sendMessage(message);
};

export const sendMicrophoneMuted = () => {
    const message = {
        data: WebRtcMessageConference.MICROPHONE_IS_MUTED,
        userId: invitedUserWebRTCManager.connectedSession.id,
    };

    invitedUserWebRTCManager.sendMessage(message);
};

export const sendRequestJoinPermission = () => {
    if (DEBUG) addLogDispatch(['requesting join permission:']);
    const message = {
        data: WebRtcMessageConference.REQUEST_JOIN_PERMISSION,
    };

    invitedUserWebRTCManager.sendMessage(message);
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';

import './InformationPanel.scss';
import { createKpiLog, replaceText, runAfterTimeHasElapsed } from '../../helper/helper';
import GeoSMS from './GeoSMS';
import { addSessionGPSDispatch } from '../../redux/actions/session';
import { CLIPBOARD_COPY_SUCCESS_TIMEOUT, DISPLAY_ONLY_IN_SESSION } from '../../config';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { WebRtcMessageCaller } from '../../types';
import store from '../../redux/store';
import { addGeoLocation } from '../../redux/slices/sessionHandlingSlice';

/**
 * GeoPanel
 * Contains the information of the geo location from the bystander.
 * Information is transmitted via the established webRTC connection.
 *
 * @component GeoSMS - Geo SMS Overlay
 */
class GeoPanel extends PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            coords: false,
            latitude: '_______',
            longtitude: '_______',
            accurary: '_',
            smsOpen: false,
            showCoordinatesCopiedSuccessfully: false,
        };

        if (dispatcherWebRTCManager.connectedSession) {
            dispatcherWebRTCManager.connectedSession.removeListener('contactMessage', this.handleContactMessage);
            dispatcherWebRTCManager.connectedSession.on('contactMessage', this.handleContactMessage);
        }
    }

    handleContactMessage = e => {
        //Display message in UI
        const message = JSON.parse(e.content);
        const sender = e.sender;

        if (this.props.activeDeviceId === null || sender.userData.id === this.props.activeDeviceId) {
            if (message && message.data === WebRtcMessageCaller.COORDS) {
                if (this._isMounted) {
                    store.dispatch(addGeoLocation({
                        activeDeviceId: this.props.activeDeviceId,
                        geolocation: {
                            longtitude: message.longitude,
                            latitude: message.latitude,
                            accuracy: message.accuracy,
                        },
                    }));

                    this.setState({
                        coords: true,
                        longtitude: message.longitude,
                        latitude: message.latitude,
                        accurary: message.accuracy,
                    });

                    addSessionGPSDispatch({
                        long: message.longitude,
                        lat: message.latitude,
                        accuracy: message.accuracy,
                    });

                    const additionalStates = {
                        0: message.longitude,
                        1: message.latitude,
                        2: message.accuracy,
                    };

                    createKpiLog('infoGpsCoordinatesReceived', '', additionalStates);
                }
            }
        }
    };

    copyCoordinates = () => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            const coordinates = this.state.latitude + ',' + this.state.longtitude;
            try {
                navigator.clipboard.writeText(coordinates);
                this.setState({
                    showLinkCopiedSuccessfully: true,
                });
                runAfterTimeHasElapsed(
                    () =>
                        this.setState({
                            showLinkCopiedSuccessfully: false,
                        }),
                    CLIPBOARD_COPY_SUCCESS_TIMEOUT
                );
                return true;
            } catch {
                addNotificationAndShowDispatch('error.cpy_coords', 'error', DISPLAY_ONLY_IN_SESSION);
                return false;
            }
        }
    };

    openSms = () => {
        this.setState({ smsOpen: true });
    };

    closeSms = () => {
        this.setState({ smsOpen: false });
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.activeDeviceId !== null && this.props.activeDeviceId !== prevProps.activeDeviceId) {
            const activeDevice = this.props.devices.find(device => device.contact.userData.id === this.props.activeDeviceId);

            if (activeDevice && activeDevice.geolocation) {
                if (Object.entries(activeDevice.geolocation).length !== 0) {
                    this.setState({
                        coords: true,
                        latitude: activeDevice.geolocation.latitude,
                        longtitude: activeDevice.geolocation.longtitude,
                        accurary: activeDevice.geolocation.accuracy,
                    });
                } else {
                    this.setState({
                        coords: false,
                        latitude: '_______',
                        longtitude: '_______',
                        accurary: '_',
                    });
                }
            }
        }
    }

    render() {
        return (
            <div>
                <ul className="informationPanel">
                    <li className="informationPanel__item" id="gps_lat">
                        <strong>
                            <span>{replaceText(this.props.texts, 'info.lat')}</span>
                        </strong>
                        {this.state.latitude}
                    </li>
                    <li className="informationPanel__item" id="gps_long">
                        <strong>
                            <span>{replaceText(this.props.texts, 'info.long')}</span>
                        </strong>
                        {this.state.longtitude}
                    </li>
                    <li className="informationPanel__item" id="gps_acc">
                        <strong>
                            <span>{replaceText(this.props.texts, 'info.acc')}</span>
                        </strong>
                        {Math.round(this.state.accurary)} m
                    </li>

                    {this.props.geoSMSFeature && (
                        <li className="informationPanel__item">
                            <button
                                onClick={this.copyCoordinates}
                                disabled={!this.state.coords || this.props.isHeadMountedDisplayDevice}
                                className="btn btn--primary btn--full">
                                {replaceText(this.props.texts, 'geoSMS.copy')}
                            </button>
                            <button
                                onClick={this.openSms}
                                disabled={!this.state.coords || this.props.isHeadMountedDisplayDevice}
                                className="btn btn--primary btn--full">
                                {replaceText(this.props.texts, 'geoSMS.send')}
                            </button>
                        </li>
                    )}
                    <div className="alert-panel">
                        <p className={`link-copied alert alert--success ${this.state.showLinkCopiedSuccessfully ? '' : 'link-copied--isHidden'}`}>
                            {replaceText(this.props.texts, 'invitation.copy.info')}
                        </p>
                    </div>
                </ul>
                {this.props.geoSMSFeature && (
                    <GeoSMS open={this.state.smsOpen} closeHandler={this.closeSms} lat={this.state.latitude + ''} long={this.state.longtitude + ''} />
                )}
            </div>
        );
    }
}

// PropTypes for this Component
GeoPanel.propTypes = {
    texts: PropTypes.any,
    geoSMSFeature: PropTypes.bool,
    activeDeviceId: PropTypes.string,
    devices: PropTypes.any,
    isHeadMountedDisplayDevice: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        geoSMSFeature: state.features.geoSMSFeature,
        activeDeviceId: state.sessionHandlingSlice.activeDeviceId,
        devices: state.sessionHandlingSlice.devices,
        isHeadMountedDisplayDevice: state.sessionHandlingSlice.isHeadMountedDisplayDevice,
    };
};

export default connect(mapStateToProps)(GeoPanel);

import { getApizeeKey, getAuthtoken, getImage, getSessionEndMessagesConference } from '../../api/backendApi';
import { DEBUG, PRIMARY_WEB_RTC_PLATFORM, SECONDARY_WEB_RTC_PLATFORM, WEB_RTC_DISCONNNECT_TIMEOUT } from '../../config';
import { errorLog } from '../../helper/logging';
import {
    createUserDisplayName,
    enterConversation,
    getURLParams,
} from '../../helper/rtcFlowHandling';
import { addOwnStreamConferenceUser, removeOwnStreamConferenceUser } from '../../redux/actionCreators/actionCreators';
import { addLogDispatch } from '../../redux/actions/logs';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { ONLY_AUDIO } from '../../redux/reducers/streams';
import store from '../../redux/store';
import reduxStore from '../../redux/store';
import { WebRtcManagerType } from '../../types';
import { loadEventListenersConferenceUser, unloadEventListenersConferenceUser } from '../eventHandlers/eventHandlingConference';
import { handleContactMessageConference } from '../incomingMessages/handleContactMessageConference';
import { sendMicrophoneError, sendRequestJoinPermission } from '../outgoingMessages/outgoingMessagesConference';
class InvitedUserWebRTCManager {
    authToken = null;

    apiRTC = null;
    apiKey = null;
    userAgent = null;

    connectedSession = null;
    connectedConversation = null;
    sessionEndMessages = null;
    imageLogo = null;

    dispatcher = null;
    joinRequestIsGranted = null;

    localStream = null;

    sessionId = null;

    onLeaveHandler = null;

    webRtcDisconnectionTimeout = 0;

    /**
     * handles the request token generation
     */
    async requestToken() {
        const { sessionId } = getURLParams();

        this.sessionId = sessionId;

        if (!this.authToken) {
            this.authToken = await getAuthtoken(sessionId);
            if (this.authToken) {
                // Proceed
            } else {
                errorLog({
                    sessionId: sessionId,
                    message: `Error getting Authtoken`,
                    error: { stack: 'no authtoken available' },
                    eventId: 'ERROR_AUTHTOKEN',
                });
                throw new Error(`Error getting Authtoken`);
            }
        }
    }

    initConnectionThen(initCallback, errorCallback) {
        this.requestToken()
            .then(async () => {
                this.sessionEndMessages = await getSessionEndMessagesConference(this.authToken);
                this.imageLogo = await getImage({ type: 'blob', endpoint: process.env.REACT_APP_CONFERENCE_LOGO_ENDPOINT }, this.authToken);
                this.registerUser(initCallback);
            })
            .catch(error => {
                errorCallback(error);
            });
    }

    /**
     * register the current useragent with apiRTC, then call the callback
     * @param {function} initCallback
     */
    async registerUser(initCallback) {
        if (window.apiRTC.CloudApi.cloudUrl.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            // hds platform
            invitedUserWebRTCManager.apiKey = await getApizeeKey(WebRtcManagerType.INVITED_USER, PRIMARY_WEB_RTC_PLATFORM);
            if (DEBUG) addLogDispatch(['using primary web rtc platform:']);
        } else if (window.apiRTC.CloudApi.cloudUrl.includes(SECONDARY_WEB_RTC_PLATFORM)) {
            // cloud platform
            invitedUserWebRTCManager.apiKey = await getApizeeKey(WebRtcManagerType.INVITED_USER, SECONDARY_WEB_RTC_PLATFORM);
            if (DEBUG) addLogDispatch(['using secondary web rtc platform:']);
        }

        invitedUserWebRTCManager.userAgent = new window.apiRTC.UserAgent({
            uri: 'apzkey:' + invitedUserWebRTCManager.apiKey,
        });

        invitedUserWebRTCManager.userAgent
            .register({
                cloudUrl: window.apiRTC.CloudApi.cloudUrl,
                token: this.authToken,
            })
            .then(session => {
                // Save session
                invitedUserWebRTCManager.connectedSession = session;
                invitedUserWebRTCManager.setupMessageListener();

                if (DEBUG) addLogDispatch(['user agent registered session', session.id]);

                initCallback();
            });
    }

    setupMessageListener() {
        invitedUserWebRTCManager.connectedSession.removeListener('contactMessage');
        invitedUserWebRTCManager.connectedSession.on('contactMessage', handleContactMessageConference);
    }

    /**
     * send a given message to dispatcher via webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        if (this.userAgent !== null) {
            const message = JSON.stringify(message2Send);
            const { sessionId } = getURLParams();
            if (invitedUserWebRTCManager.dispatcher) {
                invitedUserWebRTCManager.dispatcher
                    .sendMessage(message)
                    .then(() => {
                        if (DEBUG) addLogDispatch(['Send message', message]);
                    })
                    .catch(error => {
                        if (DEBUG) addLogDispatch(['error sending messsage', message]);

                        if (!ping) {
                            errorLog({
                                sessionId: sessionId,
                                message: `Error sending message via rtc - conference user - ${message}`,
                                error: error,
                                eventId: 'MESSAGE_SEND',
                            });
                        }
                    });
            }
        }
    }

    removeStream() {
        if (this.localStream) {
            this.connectedConversation.unpublish(this.localStream);
            this.localStream.release();
            store.dispatch(removeOwnStreamConferenceUser(this.localStream));
        }
    }

    addStream() {
        invitedUserWebRTCManager.userAgent
            .createStream(ONLY_AUDIO)
            .then(stream => {
                const options = {
                    audioLabels: ['conferenceUserAudio'],
                };
                // mute stream if mic is muted
                if (reduxStore.getState().conferencing.micIsMuted) {
                    stream.disableAudio();
                }

                invitedUserWebRTCManager.localStream = stream;
                invitedUserWebRTCManager.connectedConversation.publish(invitedUserWebRTCManager.localStream, options);

                store.dispatch(addOwnStreamConferenceUser(stream));
            })
            .catch(e => {
                addNotificationAndShowDispatch('error.mic.psn', 'info');
                // join conversation without a own stream
                invitedUserWebRTCManager.connectedConversation.join().then(() => {
                    sendRequestJoinPermission();
                });
                // send error to dispatcher
                sendMicrophoneError();
            });
    }

    updateUserName(userName) {
        invitedUserWebRTCManager.connectedSession.setUserData({ username: userName });
    }

    joinConversation(contactId) {
        const { callerId } = getURLParams();
        invitedUserWebRTCManager.dispatcher = invitedUserWebRTCManager.connectedSession.getOrCreateContact(callerId);
        if (DEBUG) addLogDispatch(['dispatcher web rtc contact saved']);

        createUserDisplayName(invitedUserWebRTCManager, contactId);
        if (DEBUG) addLogDispatch(['web rtc session username set']);

        enterConversation(invitedUserWebRTCManager);
        loadEventListenersConferenceUser();

        invitedUserWebRTCManager.connectedConversation
            .join()
            .then(e => {
                if (DEBUG) addLogDispatch(['conversation joined successfully:', e]);
                sendRequestJoinPermission();
            })
            .catch(error => {
                console.log(error);
            });
    }

    muteMic() {
        invitedUserWebRTCManager.localStream?.disableAudio();
    }

    unmuteMic() {
        invitedUserWebRTCManager.localStream?.enableAudio();
    }

    resetConferenceData() {
        if (invitedUserWebRTCManager.connectedConversation !== null) {
            invitedUserWebRTCManager.connectedConversation
                .leave()
                .then(() => {
                    invitedUserWebRTCManager.connectedConversation.destroy();
                    invitedUserWebRTCManager.connectedConversation = null;
                })
                .catch(err => {
                    console.error('Conversation leave error', err);
                });
        }
    }

    disconnectFromWebRtcPlatform = () => {
        if (this.userAgent !== null) {
            this.userAgent
                .unregister()
                .then(() => {
                    console.log('Disconnected from rtc platform');
                    this.userAgent = null;
                })
                .catch(error => {
                    console.log('error disconnecting during unregistration: ', error);
                });
        }
    };

    leaveConference() {
        return new Promise((resolve, reject) => {
            unloadEventListenersConferenceUser();

            if (invitedUserWebRTCManager.connectedConversation !== null) {
                invitedUserWebRTCManager.connectedConversation
                    .leave()
                    .then(() => {
                        invitedUserWebRTCManager.connectedConversation.destroy();
                        invitedUserWebRTCManager.connectedConversation = null;
                    })
                    .catch(err => {
                        console.error('Conversation leave error', err);
                    });
            }

            if (invitedUserWebRTCManager.localStream !== null) {
                invitedUserWebRTCManager.localStream.release();
            }

            // wait on execution of any potential web rtc events to complete before unregistering user agent
            invitedUserWebRTCManager.webRtcDisconnectionTimeout = setTimeout(() => {
                invitedUserWebRTCManager.disconnectFromWebRtcPlatform();
            }, WEB_RTC_DISCONNNECT_TIMEOUT);

            return resolve();
        });
    }
}
export let invitedUserWebRTCManager = new InvitedUserWebRTCManager();

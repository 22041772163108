import React, { useEffect, useRef } from 'react';
import './FileInput.scss';
import { C_LOST, FILE_TYPE_ENDINGS, FILE_UPLOAD_LIMIT } from '../../config';
import { connect } from 'react-redux';
import Attachment from '../Icons/Attachment';
import { addPreviewFileDispatch } from '../../redux/actions/files';
import Loading from './Loading';
import { formatDataSize, isFileValid } from '../../helper/helper';

interface FileInputProps {
    fileExchangeFeature: boolean;
    userType: string;
    chatIsDisabled: boolean;
    isConnected: boolean;
    connectionStatus: string;
    callerPageLoaded: boolean;
    uploadQueue: Array<string>;
    dispatcherFiles: Array<string>;
    callerFiles: Array<string>;
    isHeadMountedDisplayDevice: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
    userType,
    chatIsDisabled,
    isConnected,
    connectionStatus,
    callerPageLoaded,
    uploadQueue,
    dispatcherFiles,
    callerFiles,
    isHeadMountedDisplayDevice,
}) => {
    const isFileUploadedLimitExceeded = dispatcherFiles.length + callerFiles.length >= FILE_UPLOAD_LIMIT;
    const isDisabledForDispatcher =
        !isConnected || connectionStatus === C_LOST || chatIsDisabled || !callerPageLoaded || uploadQueue.length !== 0 || isFileUploadedLimitExceeded;
    const isDisabledForCaller = chatIsDisabled || !callerPageLoaded || uploadQueue.length !== 0 || isFileUploadedLimitExceeded;

    let fileRef = useRef<HTMLInputElement | null>(null);

    const isDisabled = userType === 'dispatcher' ? isDisabledForDispatcher : isDisabledForCaller;
    let classes = `file-input ${isDisabled || isHeadMountedDisplayDevice ? 'file-input--isNotAllowed' : ''}`;

    const changeFile = () => {
        if (fileRef.current?.files?.[0]) {
            const file = fileRef.current.files[0];
            if (isFileValid(file, userType)) {
                const fileExtension = Object.entries(FILE_TYPE_ENDINGS).find(([key, value]) => key === file.type);
                const formattedFileSize = formatDataSize(file.size);
                addPreviewFileDispatch({
                    fileUrl: URL.createObjectURL(file),
                    name: file.name,
                    size: formattedFileSize,
                    type: file.type,
                    extension: fileExtension[1],
                });
            }
        }
    };

    useEffect(() => {
        document.addEventListener('dragover', e => e.preventDefault());
        document.addEventListener('drop', e => e.preventDefault());

        return () => {
            document.removeEventListener('dragover', e => e.preventDefault());
            document.removeEventListener('drop', e => e.preventDefault());
        };
    }, []);

    const proxyInputClick = () => {
        fileRef.current.focus();
        fileRef.current.click();
    };

    return (
        <div className={classes} onClick={proxyInputClick} title={isFileUploadedLimitExceeded ? 'Limit exceeded' : ''}>
            {uploadQueue.length !== 0 ? <Loading /> : <Attachment />}
            <input
                disabled={isDisabled || isHeadMountedDisplayDevice}
                ref={fileRef}
                type="file"
                id="file-data"
                name="file-data"
                accept={Object.keys(FILE_TYPE_ENDINGS).join(',')}
                onChange={changeFile}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        fileExchangeFeature: state.features.fileExchangeFeature,
        chatIsDisabled: state.application.chatIsDisabled,
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        callerPageLoaded: state.application.callerPageLoaded,
        uploadQueue: state.files.uploadQueue,
        dispatcherFiles: state.files.dispatcherFiles,
        callerFiles: state.files.callerFiles,
        isHeadMountedDisplayDevice: state.sessionHandlingSlice.isHeadMountedDisplayDevice,
    };
};

export default connect(mapStateToProps)(FileInput);

import React from 'react';
import PropTypes from 'prop-types';
import './ModalOverlay.scss';
import { connect } from 'react-redux';
import CircleWithCross from '../Icons/CircleWithCross';
import clsx from 'clsx';

const ModalOverlay = ({ modalIsVisible, isPartialModal, hasCloseButton, closeHandler, children, texts, isCaller, addOwnContent }) => {
    const classes = clsx('modalOverlay', {
        'modalOverlay--isVisible': modalIsVisible,
        'modalOverlay--isPartialModal': isPartialModal,
    });

    const contentClasses = clsx({
        'modalOverlay__content': !addOwnContent,
        'modalOverlay__content--isCaller': !addOwnContent && isCaller,
    });

    return (
        <div className={classes}>
            <div className={contentClasses}>
                <div className="modalOverlay__close">
                    <div onClick={closeHandler}>{hasCloseButton && <CircleWithCross />}</div>
                </div>
                <span>{children}</span>
            </div>
        </div>
    );
};

// PropTypes for this Component
ModalOverlay.propTypes = {
    modalIsVisible: PropTypes.bool,
    closeHandler: PropTypes.func,
    children: PropTypes.any,
    texts: PropTypes.any,
    isPartialModal: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
    isCaller: PropTypes.bool,
    addOwnContent: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(ModalOverlay);

import {
    ENABLE_FEATURE_GEOSMS,
    ENABLE_FEATURE_POINTER,
    ENABLE_FEATURE_SNAPSHOT,
    ENABLE_FEATURE_SESSION_DOWNLOAD,
    ENABLE_FEATURE_LOGO_UPLOAD,
    ENABLE_FEATURE_PHONEBOOK,
    ENABLE_FEATURE_SMS_GUIDE,
    ENABLE_FEATURE_HD_SEND,
    ENABLE_FEATURE_DRAW,
    ENABLE_FEATURE_INVITE,
    ENABLE_FEATURE_AUDIO,
    ENABLE_FEATURE_BIDI,
    ENABLE_FEATURE_NOTES,
    ENABLE_FEATURE_DISCLAIMER_CALLER,
    ENABLE_FEATURE_DISCLAIMER_DISPATCHER,
    ENABLE_FEATURE_STREAM_RECORDING,
    ENABLE_FEATURE_FILE_SHARE,
    ENABLE_FEATURE_SCREEN_SHARE,
    ENABLE_FEATURE_SMART_CONNECT,
} from '../actions/actionTypes';

export const initialState = {
    geoSMSFeature: false,
    pointerFeature: false,
    snapshotFeature: false,
    chatFeature: true,
    sessionDownloadFeature: false,
    logoUploadFeature: false,
    phonebookFeature: false,
    smsGuideFeature: false,
    hdSendFeature: false,
    drawFeature: false,
    audioStreamFeature: false,
    inviteFeature: false,
    bidiFeature: false,
    notesFeature: false,
    disclaimerFeatureCaller: false,
    disclaimerFeatureDispatcher: false,
    streamRecordingFeature: false,
    fileShareFeature: false,
    screenShareFeature: false,
    smartConnectFeature: false,
};

/**
 * feature reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const featureReducer = (state = initialState, action) => {
    if (action.type === ENABLE_FEATURE_GEOSMS) {
        return { ...state, geoSMSFeature: true };
    }
    if (action.type === ENABLE_FEATURE_POINTER) {
        return { ...state, pointerFeature: true };
    }
    if (action.type === ENABLE_FEATURE_SNAPSHOT) {
        return { ...state, snapshotFeature: true };
    }
    if (action.type === ENABLE_FEATURE_SESSION_DOWNLOAD) {
        return { ...state, sessionDownloadFeature: true };
    }
    if (action.type === ENABLE_FEATURE_LOGO_UPLOAD) {
        return { ...state, logoUploadFeature: true };
    }
    if (action.type === ENABLE_FEATURE_PHONEBOOK) {
        return { ...state, phonebookFeature: true };
    }
    if (action.type === ENABLE_FEATURE_SMS_GUIDE) {
        return { ...state, smsGuideFeature: true };
    }
    if (action.type === ENABLE_FEATURE_HD_SEND) {
        return { ...state, hdSendFeature: true };
    }
    if (action.type === ENABLE_FEATURE_DRAW) {
        return { ...state, drawFeature: true };
    }
    if (action.type === ENABLE_FEATURE_INVITE) {
        return { ...state, inviteFeature: true };
    }
    if (action.type === ENABLE_FEATURE_AUDIO) {
        return { ...state, audioStreamFeature: true };
    }
    if (action.type === ENABLE_FEATURE_BIDI) {
        return { ...state, bidiFeature: true };
    }
    if (action.type === ENABLE_FEATURE_NOTES) {
        return { ...state, notesFeature: true };
    }
    if (action.type === ENABLE_FEATURE_DISCLAIMER_CALLER) {
        return { ...state, disclaimerFeatureCaller: true };
    }
    if (action.type === ENABLE_FEATURE_DISCLAIMER_DISPATCHER) {
        return { ...state, disclaimerFeatureDispatcher: true };
    }
    if (action.type === ENABLE_FEATURE_STREAM_RECORDING) {
        return { ...state, streamRecordingFeature: true };
    }
    if (action.type === ENABLE_FEATURE_FILE_SHARE) {
        return { ...state, fileShareFeature: true };
    }
    if (action.type === ENABLE_FEATURE_SCREEN_SHARE) {
        return { ...state, screenShareFeature: true };
    }
    if (action.type === ENABLE_FEATURE_SMART_CONNECT) {
        return { ...state, smartConnectFeature: true };
    }

    return state;
};

export default featureReducer;

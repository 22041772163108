export type FocusFeatureType = typeof FOCUS_FEATURE_TYPE;
export type FocusFeatureKey = keyof FocusFeatureType;

export const FOCUS_FEATURE_TYPE = {
    LIVE_VIDEO: 'LIVE_VIDEO',
    CHAT: 'CHAT',
    BIDI: 'BIDI',
    SCREEN_SHARE: 'SCREEN_SHARE',
    RESET_FOCUS: 'RESET',
    MAP: 'MAP',
    EXTERNAL_STREAM: 'EXTERNAL_STREAM',
};